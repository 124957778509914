@charset "UTF-8";
@font-face {
  font-family: 'Open Sans Regular';
  src: url("/static/fonts/OpenSans-Regular/Open Sans.eot"), url("/static/fonts/OpenSans-Regular/Open Sans.eot?#iefix") format("embedded-opentype"), url("/static/fonts/OpenSans-Regular/Open Sans.woff2") format("woff2"), url("/static/fonts/OpenSans-Regular/Open Sans.woff") format("woff"), url("/static/fonts/OpenSans-Regular/Open Sans.ttf") format("truetype"), url("/static/fonts/OpenSans-Regular/Open Sans.svg#Open Sans") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2212; }

@font-face {
  font-family: 'Open Sans Semibold';
  src: url("/static/fonts/OpenSans-Semibold/Open Sans Semibold.eot"), url("/static/fonts/OpenSans-Semibold/Open Sans Semibold.eot?#iefix") format("embedded-opentype"), url("/static/fonts/OpenSans-Semibold/Open Sans Semibold.woff2") format("woff2"), url("/static/fonts/OpenSans-Semibold/Open Sans Semibold.woff") format("woff"), url("/static/fonts/OpenSans-Semibold/Open Sans Semibold.ttf") format("truetype"), url("/static/fonts/OpenSans-Semibold/Open Sans Semibold.svg#Open Sans Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2074; }

@font-face {
  font-family: 'Open Sans Bold';
  src: url("/static/fonts/OpenSans-Bold/Open Sans Bold.eot"), url("/static/fonts/OpenSans-Bold/Open Sans Bold.eot?#iefix") format("embedded-opentype"), url("/static/fonts/OpenSans-Bold/Open Sans Bold.woff2") format("woff2"), url("/static/fonts/OpenSans-Bold/Open Sans Bold.woff") format("woff"), url("/static/fonts/OpenSans-Bold/Open Sans Bold.ttf") format("truetype"), url("/static/fonts/OpenSans-Bold/Open Sans Bold.svg#Open Sans Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2074; }

@font-face {
  font-family: 'RobotoSlab Bold';
  src: url("/static/fonts/RobotoSlab-Bold/RobotoSlab-Bold.eot");
  src: local("☺"), url("/static/fonts/RobotoSlab-Bold/RobotoSlab-Bold.woff") format("woff"), url("/static/fonts/RobotoSlab-Bold/RobotoSlab-Bold.ttf") format("truetype"), url("/static/fonts/RobotoSlab-Bold/RobotoSlab-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ALS Rubl';
  src: url("/static/fonts/ALS Rubl/ALSRubl.eot");
  src: url("/static/fonts/ALS Rubl/ALSRubl.eot?#iefix") format("embedded-opentype"), url("/static/fonts/ALS Rubl/ALSRubl.woff2") format("woff2"), url("/static/fonts/ALS Rubl/ALSRubl.woff") format("woff"), url("/static/fonts/ALS Rubl/ALSRubl.ttf") format("truetype"), url("/static/fonts/ALS Rubl/ALSRubl.svg#ALSRubl") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0078; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@keyframes progress {
  from {
    width: 0;
    background-color: #d8f3e3; }
  to {
    width: 100%;
    background-color: rgba(252, 146, 31, 0.15); } }

@-webkit-keyframes progress {
  from {
    width: 0;
    background-color: #d8f3e3; }
  to {
    width: 100%;
    background-color: rgba(252, 146, 31, 0.15); } }

.pulsarPoint {
  background-color: #FC921F;
  position: absolute;
  z-index: 20;
  border-radius: 50%;
  animation-name: bounce;
  animation-duration: 2.75s;
  animation-iteration-count: infinite;
  left: 50%;
  top: 50%; }
  .pulsarPoint.hidden {
    display: none; }

.pulsarPoint2 {
  background-color: #FC921F;
  position: absolute;
  z-index: 20;
  border-radius: 50%;
  animation-name: bounce2;
  animation-delay: 1s;
  animation-duration: 2.75s;
  animation-iteration-count: infinite;
  left: 50%;
  top: 50%; }
  .pulsarPoint2.hidden {
    display: none; }

.Pin {
  width: 37px;
  height: 60px;
  position: absolute;
  z-index: 30;
  left: 50%;
  top: 50%;
  margin-left: -19px;
  margin-top: -57px;
  background-image: url("/static/img/lk/pin_a.svg");
  color: #FFFFFF;
  padding: 5px 0 0 11px;
  font-size: 20px; }
  .Pin.hidden {
    display: none; }

@-webkit-keyframes bounce2 {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
    margin-left: 0;
    margin-top: 0; }
  40% {
    width: 135px;
    height: 135px;
    opacity: 0.5;
    margin-left: -68px;
    margin-top: -68px; }
  100% {
    width: 470px;
    height: 470px;
    margin-left: -235px;
    margin-top: -235px;
    opacity: 0; } }

@-webkit-keyframes bounce {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
    margin-left: 0;
    margin-top: 0; }
  40% {
    width: 135px;
    height: 135px;
    opacity: 0.5;
    margin-left: -68px;
    margin-top: -68px; }
  100% {
    width: 470px;
    height: 470px;
    margin-left: -235px;
    margin-top: -235px;
    opacity: 0; } }

/*@keyframes progressTitle {
  0% {
    transform: scale(.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(.75);
  }
  100% {
    transform: scale(.75);
  }
}*/
/*@-webkit-keyframes progressTitle {
  0% {
    transform: scale(.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(.75);
  }
  100% {
    transform: scale(.75);
  }
}*/
.loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url("/static/img/loader.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.placemarkLayoutContainer {
  position: relative; }

.scrollarea-content {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative; }
  .scrollarea-content:focus {
    outline: 0; }

.orders-list-wrapper .loading {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%; }

.scrollarea {
  position: relative;
  overflow: hidden; }
  .scrollarea .scrollbar-container {
    position: absolute;
    background: none;
    opacity: .1;
    z-index: 100;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s; }
    .scrollarea .scrollbar-container.horizontal {
      width: 100%;
      height: 5px;
      left: 0;
      bottom: 0; }
      .scrollarea .scrollbar-container.horizontal .scrollbar {
        width: 20px;
        height: 4px;
        background: black;
        margin-top: 1px; }
    .scrollarea .scrollbar-container.vertical {
      width: 5px;
      height: 100%;
      right: 0;
      top: 0; }
      .scrollarea .scrollbar-container.vertical .scrollbar {
        width: 4px;
        height: 20px;
        background: black;
        margin-left: 1px; }
    .scrollarea .scrollbar-container:hover {
      width: 10px;
      background: gray;
      opacity: .6 !important; }
      .scrollarea .scrollbar-container:hover .scrollbar {
        width: 8px; }
    .scrollarea .scrollbar-container.active {
      width: 10px;
      background: gray;
      opacity: .6 !important; }
      .scrollarea .scrollbar-container.active .scrollbar {
        width: 8px; }
  .scrollarea:hover .scrollbar-container {
    opacity: .3; }

.loader-list {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  background-image: url("/static/img/preloader_rings.svg");
  margin-top: -27.5px;
  margin-left: -27.5px; }
  .loader-list .circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 54px;
    width: 54px;
    position: relative; }
    .loader-list .circular .path {
      stroke-width: 4;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      -webkit-animation: dash 1.5s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite;
      stroke: #fb5c3f;
      stroke-linecap: square; }

.isMoz .loader-list .path {
  stroke-width: 2.5 !important; }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

* {
  margin: 0;
  padding: 0; }

html,
body {
  height: 100%; }
  html.ReactModal__Body--open,
  body.ReactModal__Body--open {
    overflow: hidden; }

body {
  font-family: "Open Sans Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

strong, b {
  font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

#react {
  position: relative;
  min-height: 100%; }

main, #__next {
  overflow: hidden; }

.clr {
  clear: both; }

.download-app {
  width: 140px;
  height: 43px;
  display: inline-block;
  background-repeat: no-repeat;
  margin-right: 15px;
  background: url("/static/img/sprite-apps.svg");
  background-size: 440px auto; }
  .download-app-google {
    background-position-y: 0;
    background-position-x: -5px; }
  .download-app-apple {
    background-position-y: 0;
    background-position-x: -155px; }

.als-rubl {
  font-weight: 400; }

.btn-primary:focus, .btn-primary.focus {
  box-shadow: none !important; }

.hidden {
  display: none;
  visibility: hidden; }

.button-hidden {
  max-height: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: -1000 !important; }
  .button-hidden ~ * {
    z-index: 1; }

.header .small {
  position: relative;
  z-index: 2;
  height: 40px;
  width: 100%;
  background-color: #000000;
  color: rgba(255, 255, 255, 0.7); }
  .header .small .container-fluid {
    margin: 0 auto;
    height: 40px;
    position: relative; }
    .header .small .container-fluid .block_left {
      text-align: center;
      float: left;
      width: 100%;
      position: relative; }
      .header .small .container-fluid .block_left .link-wrapper {
        position: relative;
        display: inline-block;
        vertical-align: top; }
      .header .small .container-fluid .block_left .link {
        position: relative;
        font-size: 11px;
        text-decoration-line: none;
        display: inline-block;
        color: rgba(255, 255, 255, 0.5);
        line-height: 40px;
        margin: 0 25px;
        transition: 0.25s ease-in-out; }
        .header .small .container-fluid .block_left .link::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 2px;
          background-color: transparent; }
        .header .small .container-fluid .block_left .link:hover {
          color: #ffffff; }
        .header .small .container-fluid .block_left .link.active {
          color: #ffffff;
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-weight: 600;
          cursor: default; }
          .header .small .container-fluid .block_left .link.active::before {
            background-color: #fc921f; }
    .header .small .container-fluid .block_right {
      float: right;
      width: 400px;
      text-align: right;
      right: 15px;
      position: absolute; }
      .header .small .container-fluid .block_right > .link {
        position: relative;
        font-size: 11px;
        text-decoration-line: none;
        display: inline-block;
        color: rgba(255, 255, 255, 0.7);
        line-height: 40px;
        cursor: pointer; }
        .header .small .container-fluid .block_right > .link:not(:last-child) {
          border-right: 1px solid #bec5d0;
          margin-right: 15px;
          padding-right: 15px; }
  .header .small .login-in {
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    white-space: nowrap;
    padding: 8px 0;
    margin-right: -5px; }
    .header .small .login-in::after {
      display: block;
      clear: both;
      content: ""; }
    .header .small .login-in-item {
      position: relative;
      display: inline-block;
      font-size: 11px;
      line-height: 1.36;
      color: rgba(255, 255, 255, 0.7); }
      .header .small .login-in-item:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        padding-right: 15px;
        margin-right: 15px; }
    .header .small .login-in-user {
      border-radius: 12px;
      position: relative;
      min-height: 24px;
      cursor: pointer;
      padding: 5px 18px 5px 29px; }
      .header .small .login-in-user:hover {
        background-color: rgba(255, 255, 255, 0.12); }
        .header .small .login-in-user:hover .user {
          background-color: #dfe6f4; }
      .header .small .login-in-user .user {
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #dfe6f4;
        display: flex;
        /*justify-content: center;
          align-items: center;*/
        text-align: center; }
        .header .small .login-in-user .user img {
          /*width: auto;
            max-height: 100%;
            height: auto;*/
          vertical-align: top;
          width: 100%;
          object-fit: cover; }
      .header .small .login-in-user .icon {
        position: absolute;
        right: 5px;
        top: 50%;
        width: 8px;
        height: 5px;
        background-image: url("/static/img/sprite-header.svg");
        background-position: -22px 0;
        margin-top: -2px; }
    .header .small .login-in-balance .icon {
      position: relative;
      top: 0;
      display: inline-block;
      margin-right: 5px;
      width: 12px;
      height: 8px;
      background-image: url("/static/img/sprite-header.svg");
      background-position: 0 -2px; }
  .header .small .lang {
    position: relative;
    font-size: 11px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    padding-right: 15px;
    padding-top: 4px;
    padding-bottom: 5px; }
    .header .small .lang-wrapper {
      position: relative;
      display: inline-block;
      vertical-align: top;
      line-height: 1;
      padding: 8px 0 8px; }
    .header .small .lang-inner {
      position: relative;
      margin-right: 15px;
      padding-right: 16px; }
      .header .small .lang-inner::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 1px;
        height: 15px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        margin-top: -8px; }
    .header .small .lang .icon {
      position: absolute;
      right: 0;
      top: 50%;
      width: 8px;
      height: 5px;
      background-image: url("/static/img/sprite-header.svg");
      background-position: -22px 0;
      margin-top: -2px; }

.header [class="big"] {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(50, 60, 93, 0.1); }

.header .burger {
  position: absolute;
  left: 15px;
  top: 18px;
  width: 24px;
  height: 24px;
  background-image: url("/static/img/ic_menu_24.svg");
  cursor: pointer; }

.header .big {
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 1;
  height: 61px;
  width: 100%;
  color: #152352;
  transition: 0.15s ease-in-out;
  transition-property: box-shadow, background-color, border-bottom-color; }
  .header .big.private .container-fluid {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px; }
  .header .big.private:not(.fixed) {
    background-color: #fafbfc;
    border-bottom: 1px solid #fafbfc; }
  .header .big.private .links .link .active {
    border-bottom: none; }
  .header .big.main-menu-opacity:not(.fixed) {
    background-color: transparent;
    border-bottom: 1px solid transparent; }
  .header .big.opacity:not(.fixed) {
    background-color: transparent;
    border-bottom: 1px solid transparent; }
  .header .big.opacity .container-fluid .block_left .logo .icon-page .cls-1 {
    fill: #ffffff; }
  .header .big.opacity .container-fluid .block_right.links a {
    color: rgba(255, 255, 255, 0.49); }
    .header .big.opacity .container-fluid .block_right.links a:hover {
      color: #ffffff; }
    .header .big.opacity .container-fluid .block_right.links a.active {
      color: #ffffff; }
      .header .big.opacity .container-fluid .block_right.links a.active::after {
        background-color: #fc921f; }
  .header .big:not(.opacity) .container-fluid .block_left .logo .icon-page .cls-1 {
    fill: #000000; }
  .header .big:not(.opacity) .container-fluid .block_right.links a {
    color: #152352; }
    .header .big:not(.opacity) .container-fluid .block_right.links a:hover {
      color: #4174d6; }
    .header .big:not(.opacity) .container-fluid .block_right.links a.active {
      color: #323c5d; }
      .header .big:not(.opacity) .container-fluid .block_right.links a.active::after {
        background-color: #fc921f; }
  .header .big .container-fluid {
    position: relative;
    margin: 0 auto;
    height: 60px; }
    .header .big .container-fluid .block_left {
      float: left;
      height: 60px; }
      .header .big .container-fluid .block_left .logo {
        display: inline-block;
        cursor: pointer;
        font-size: 0;
        line-height: 1;
        text-decoration: none;
        margin-top: 16px;
        /*background-image: url("/static/img/logo.svg");
          background-size: 100% 100%;*/
        width: 119px;
        height: 28px; }
        .header .big .container-fluid .block_left .logo .icon-page .cls-1, .header .big .container-fluid .block_left .logo .icon-page .cls-2 {
          fill-rule: evenodd;
          transition: 0.25s ease-in-out; }
        .header .big .container-fluid .block_left .logo .icon-page .cls-2 {
          fill: #fc921f; }
    .header .big .container-fluid .block_right {
      min-height: 60px;
      text-align: center;
      margin-left: 175px;
      margin-right: 175px; }
      .header .big .container-fluid .block_right.links a {
        position: relative;
        font-size: 13px;
        text-decoration-line: none;
        margin: 0 24px;
        line-height: 60px;
        letter-spacing: 0.4px;
        transition: 0.25s ease-in-out; }
        .header .big .container-fluid .block_right.links a::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: transparent; }
        .header .big .container-fluid .block_right.links a.active {
          cursor: default; }
      .header .big .container-fluid .block_right .lk-details {
        position: absolute;
        right: 40px;
        top: 0;
        z-index: 1;
        line-height: 36px;
        margin-top: 9px; }
        .header .big .container-fluid .block_right .lk-details > * {
          display: inline-block;
          vertical-align: middle; }
          .header .big .container-fluid .block_right .lk-details > *:not(:last-child) {
            margin-right: 40px; }
      .header .big .container-fluid .block_right .btn-lk-add {
        background-color: #4174d6;
        border-color: #4174d6;
        border-radius: 8px;
        width: 118px;
        outline: none;
        color: #FFFFFF;
        font-size: 15px; }
        .header .big .container-fluid .block_right .btn-lk-add:hover {
          background-color: #4174d6;
          border-color: #4174d6;
          color: #FFFFFF; }
      .header .big .container-fluid .block_right .lk-search {
        width: 24px;
        height: 24px;
        background-image: url("/static/img/lk/ic_search_24.svg");
        background-size: 100% 100%;
        cursor: pointer; }
  .header .big.fixed {
    position: fixed;
    top: 0;
    box-shadow: 0 1px 18px 6px rgba(191, 191, 191, 0.45);
    -webkit-box-shadow: 0 1px 18px 6px rgba(191, 191, 191, 0.45);
    -moz-box-shadow: 0 1px 18px 6px rgba(191, 191, 191, 0.45);
    z-index: 9999; }
    .header .big.fixed .container-fluid .block_left .logo .icon-page .cls-1 {
      fill: #000000; }
    .header .big.fixed .container-fluid .block_right.links a {
      color: #152352; }
      .header .big.fixed .container-fluid .block_right.links a:hover {
        color: #4174d6; }
      .header .big.fixed .container-fluid .block_right.links a.active {
        color: #323c5d; }
        .header .big.fixed .container-fluid .block_right.links a.active::after {
          background-color: #fc921f; }

.ReactModal__Body--open .header .big {
  padding-right: 17px; }

.widget-login .title {
  font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 17px;
  line-height: 1.35;
  color: #323c5d;
  text-align: left;
  margin-bottom: 20px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.widget-login .drop-form .form-control {
  font-size: 13px;
  text-align: center; }

.widget-login .drop-form .btn {
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(65, 116, 214, 0.3);
  font-size: 11px; }
  .widget-login .drop-form .btn:disabled {
    color: rgba(50, 60, 93, 0.7);
    background-color: #e4e6ea;
    border-color: #e4e6ea;
    box-shadow: none; }

.widget-login-links {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
  padding: 0 8px; }
  .widget-login-links .item {
    display: inline-block;
    vertical-align: top;
    text-align: center; }
    .widget-login-links .item:only-child {
      width: 100%; }
  .widget-login-links .link {
    font-size: 11px;
    line-height: 1.36;
    color: rgba(50, 60, 93, 0.5);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .widget-login-links .link.change-number, .widget-login-links .link.enter {
      position: relative; }
      .widget-login-links .link.change-number:hover:before, .widget-login-links .link.enter:hover:before {
        background-repeat: no-repeat;
        background-image: url("/static/img/arrow-left.svg");
        background-position-y: center;
        margin-top: 30px;
        width: 10.6px;
        height: 8.5px;
        content: "";
        display: inline-block;
        position: absolute;
        left: -15px;
        top: -26px; }
    .widget-login-links .link:hover, .widget-login-links .link:focus, .widget-login-links .link:active {
      color: #4174d6;
      text-decoration: none; }

.widget-login-user {
  display: block;
  background-color: #fbfcfd;
  border-top: 1px solid #dfe6f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-decoration: none;
  margin: 23px -15px -24px;
  padding: 14px 15px 24px; }
  .widget-login-user .label {
    display: block;
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.3px;
    color: rgba(50, 60, 93, 0.5);
    margin-bottom: 15px; }
  .widget-login-user .inner {
    display: block;
    position: relative;
    min-height: 24px;
    text-decoration: none;
    padding-left: 34px;
    padding-right: 27px; }
  .widget-login-user .user {
    position: absolute;
    left: 0;
    top: 2px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    background-color: #dfe6f4;
    border-radius: 50%; }
    .widget-login-user .user img {
      width: 100%;
      height: 100%;
      vertical-align: top; }
  .widget-login-user .cnt > * {
    display: block; }
  .widget-login-user .cnt .author {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    line-height: 18px;
    color: #323c5d; }
  .widget-login-user .cnt .position {
    font-size: 11px;
    line-height: 15px;
    color: rgba(50, 60, 93, 0.5); }
  .widget-login-user .arrow {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -5px;
    width: 12px;
    height: 12px;
    font-size: 0;
    line-height: 1; }
    .widget-login-user .arrow .svg {
      width: 100%;
      height: 100%; }
    .widget-login-user .arrow .cls-1 {
      fill: none; }
    .widget-login-user .arrow .cls-2 {
      fill: rgba(50, 60, 93, 0.3); }

.widget-login-timer {
  font-size: 11px;
  line-height: 1.36;
  color: rgba(50, 60, 93, 0.5);
  text-align: center;
  margin-top: 25px;
  cursor: pointer;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .widget-login-timer:hover {
    color: #4174d6; }
  .widget-login-timer.t-disabled {
    color: rgba(50, 60, 93, 0.2);
    cursor: default; }
    .widget-login-timer.t-disabled:hover {
      color: rgba(50, 60, 93, 0.2); }

.drop-item:not(:last-child) {
  margin-bottom: 50px; }

.drop-wrapper {
  padding-top: 16px; }
  .drop-wrapper:not(.t-static) {
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%; }
  .drop-wrapper:not(.t-right) {
    left: 0; }
  .drop-wrapper.t-right {
    right: 0; }
  .drop-wrapper.contacts {
    min-width: 376px;
    width: 376px;
    left: 60px; }
    .drop-wrapper.contacts .drop-form {
      display: none; }
      .drop-wrapper.contacts .drop-form.show {
        display: block; }
  .drop-wrapper.floor {
    min-width: 195px;
    width: 195px; }
  .drop-wrapper.profile {
    min-width: 256px;
    width: 256px; }
  .drop-wrapper.language {
    min-width: 116px;
    width: 116px; }
  .drop-wrapper.login {
    min-width: 256px;
    width: 256px;
    top: 32px; }
  .drop-wrapper.filter-add {
    min-width: 186px;
    width: 186px; }
  .drop-wrapper.filter-status {
    min-width: 256px;
    width: 256px; }
  .drop-wrapper.filter-edits {
    min-width: 256px;
    width: 256px; }
  .drop-wrapper.filter-calendar {
    min-width: 300px;
    width: 300px; }
  .drop-wrapper.price-edit {
    min-width: 256px;
    width: 256px; }
  .drop-wrapper.driver-edit {
    min-width: 256px;
    width: 256px; }
  .drop-wrapper.company-edit {
    min-width: 256px;
    width: 256px; }
  .drop-wrapper.order-edit {
    min-width: 275px;
    width: 275px; }
  .drop-wrapper.status-edit {
    min-width: 275px;
    width: 275px; }

.drop-inner {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.16); }
  .drop-inner:not(.t-p-0) {
    padding: 24px 15px; }
  .drop-inner:not(.t-right)::before {
    left: 15px; }
  .drop-inner.t-right::before {
    right: 15px; }
  .drop-inner::before {
    content: "";
    position: absolute;
    top: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 8px 9px;
    border-color: transparent;
    border-bottom-color: #ffffff; }

.drop-contacts {
  margin-left: -15px !important;
  margin-right: -15px !important; }
  .drop-contacts .user > * {
    display: block; }
  .drop-contacts .title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 17px;
    font-weight: 600;
    line-height: 1.35;
    color: #323c5d;
    margin-bottom: 1px; }
  .drop-contacts .subtitle {
    font-size: 14px;
    line-height: 1.33;
    color: rgba(50, 60, 93, 0.85); }
  .drop-contacts .btn {
    margin-top: 3px; }
    .drop-contacts .btn.is-active {
      background-color: rgba(50, 60, 93, 0.1);
      border-color: #dfe6f4;
      color: rgba(50, 60, 93, 0.38); }

.drop-form:not(:first-child) {
  margin-top: 20px; }

.drop-form .form-group:last-child {
  margin-bottom: 0; }

.drop-form .form-group.t-button {
  text-align: right; }
  .drop-form .form-group.t-button .btn {
    width: calc(50% - 15px); }

.drop-form .form-help {
  display: block;
  font-size: 9px;
  line-height: 1.44;
  text-align: left;
  margin-top: 4px;
  padding-left: 15px; }
  .drop-form .form-help.t-error {
    color: #fb5c3f; }
  .drop-form .form-help.t-success {
    color: rgba(50, 60, 93, 0.7); }

.drop-point-type {
  position: absolute;
  left: -10px;
  top: -7px;
  right: -10px;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.16); }
  .drop-point-type .type-item {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    font-weight: 600;
    line-height: 1.33;
    color: rgba(50, 60, 93, 0.85);
    cursor: pointer;
    padding: 10px 10px; }

.drop-load {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px; }
  .drop-load .inner {
    text-align: center; }
  .drop-load .load {
    width: 64px;
    height: 64px;
    background-color: #fc921f;
    border-radius: 50%;
    margin: 0 auto; }
  .drop-load .desc {
    font-size: 13px;
    line-height: 1.38;
    color: #323c5d;
    margin-top: 10px; }

.btn {
  font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600; }
  .btn-warning {
    color: #ffffff;
    background-color: #fc921f;
    border-color: #fc921f; }
  .btn-primary {
    color: #ffffff;
    background-color: #4174d6;
    border-color: #4174d6; }
    .btn-primary.disabled, .btn-primary:disabled {
      color: #fff;
      background-color: #4174d6;
      border-color: #4174d6;
      opacity: 1;
      cursor: not-allowed; }

.form-radio {
  position: relative; }
  .form-radio:not(:last-child) {
    margin-bottom: 1rem; }
  .form-radio [type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
  .form-radio .label {
    position: relative;
    display: block;
    font-size: 13px;
    line-height: 1.38;
    color: rgba(50, 60, 93, 0.7);
    margin-bottom: 0;
    padding-left: 24px;
    cursor: pointer; }
  .form-radio .circle {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: rgba(255, 255, 255, 0);
    border: solid 1px rgba(50, 60, 93, 0.38);
    border-radius: 50%; }
    .form-radio .circle::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 8px;
      height: 8px;
      background-color: #4174d6;
      border-radius: 50%;
      margin-left: -4px;
      margin-top: -4px;
      opacity: 0; }
  .form-radio [type="radio"]:checked + .label {
    color: #4174d6; }
  .form-radio [type="radio"]:checked + .label .circle {
    border-color: #4174d6; }
  .form-radio [type="radio"]:checked + .label .circle::before {
    opacity: 1; }

.tab-list {
  margin-bottom: 0; }
  .tab-list li {
    float: left; }
    .tab-list li:not(:last-child) {
      margin-right: 10px; }
    .tab-list li.active a, .tab-list li.active span {
      background-color: #4174d6;
      color: #ffffff;
      text-decoration: none;
      cursor: default; }
  .tab-list a, .tab-list span {
    display: block;
    border-radius: 20px;
    background-color: transparent;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    font-weight: 600;
    line-height: 1.33;
    color: rgba(50, 60, 93, 0.7);
    padding: 10px 15px;
    cursor: pointer; }
    .tab-list a:hover, .tab-list span:hover {
      text-decoration: none;
      color: #4174d6; }

.slider-wrap {
  width: 100%;
  height: 100%;
  position: relative; }

.selectors {
  position: absolute;
  top: -40px;
  right: 0;
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1; }
  .selectors .selector {
    background: #dfe6f4;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 0;
    cursor: pointer;
    transition: background .3s ease-in-out, opacity .3s ease-in-out; }
    .selectors .selector:hover, .selectors .selector.active {
      background: #fc921f;
      opacity: 0.9; }

.slider {
  width: 100%; }
  .slider .container {
    padding: 0;
    position: relative;
    height: 100%;
    transition: content 500ms ease-in-out; }

aside.panel {
  height: 100%;
  opacity: 0.9;
  color: #FFFFFF;
  transition: content 500ms ease-in-out; }

.tooltip-wrap {
  opacity: 0;
  position: absolute;
  z-index: -1; }
  .tooltip-wrap.t-right {
    left: 100%;
    transform: translateY(-50%);
    top: 50%;
    padding-left: 8px;
    margin-left: 8px; }
    .tooltip-wrap.t-right .tooltip-in::before {
      left: -8px;
      top: 50%;
      transform: translateY(-50%);
      border-width: 9px 8px 9px 0;
      border-right-color: #ffffff; }
  .tooltip-wrap.t-top {
    top: 0;
    left: 0;
    padding-bottom: 8px;
    margin-bottom: 8px;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    pointer-events: none;
    -webkit-transform: translateY(-100%) scale(0.75);
    transform: translateY(-100%) scale(0.75);
    -webkit-transform-origin: 20px calc(100% + 12px);
    transform-origin: 20px calc(100% + 12px); }
    .tooltip-wrap.t-top .tooltip-in::before {
      left: 20px;
      bottom: -8px;
      border-width: 8px 9px 0 9px;
      border-top-color: #ffffff; }

.tooltip-in {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.16); }
  .tooltip-in::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent; }

.tooltip-img {
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  height: 200px;
  background-color: #fCfCfC;
  padding: 20px; }
  .tooltip-img img {
    width: 260px;
    height: 160px; }

.tooltip-pos {
  position: relative;
  cursor: pointer; }
  .tooltip-pos:hover .tooltip-wrap {
    opacity: 1;
    z-index: 1; }
    .tooltip-pos:hover .tooltip-wrap.t-top {
      -webkit-transform: translateY(calc(-100% - 5px)) scale(1);
      transform: translateY(calc(-100% - 5px)) scale(1); }

.adress-selector {
  width: 100%; }
  .adress-selector .menu {
    width: 416px; }

.goods-selector {
  width: 394px; }
  .goods-selector .menu {
    width: 394px; }

.adress-selector, .goods-selector {
  height: 40px;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #dfe6f4;
  padding: 6px 15px; }
  .adress-selector .wrap-inp input, .goods-selector .wrap-inp input {
    background-color: transparent;
    border: 0;
    width: 100%;
    height: 21px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #323c5d;
    padding-right: 30px; }
    .adress-selector .wrap-inp input::-webkit-input-placeholder, .goods-selector .wrap-inp input::-webkit-input-placeholder {
      color: #323c5d;
      opacity: 0.5; }
    .adress-selector .wrap-inp input::-moz-placeholder, .goods-selector .wrap-inp input::-moz-placeholder {
      color: #323c5d;
      opacity: 0.5; }
    .adress-selector .wrap-inp input:focus, .goods-selector .wrap-inp input:focus {
      outline: none; }
  .adress-selector .wrap-inp::after, .goods-selector .wrap-inp::after {
    content: '';
    position: absolute;
    right: 30px;
    top: 0;
    width: 40px;
    height: 100%;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.01), #FFFFFF 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.01), #FFFFFF 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0.01), #FFFFFF 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.01), #FFFFFF 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.01), #FFFFFF 100%); }
  .adress-selector .delete, .goods-selector .delete {
    height: 18px;
    background-image: url("/static/img/input-clear.svg");
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 5px;
    width: 18px;
    cursor: pointer; }
  .adress-selector .menu, .goods-selector .menu {
    position: absolute;
    z-index: 2;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    left: -15px;
    top: 42px; }
    .adress-selector .menu .item, .goods-selector .menu .item {
      color: #323c5d;
      padding: 15px; }
      .adress-selector .menu .item.item-highlighted, .goods-selector .menu .item.item-highlighted {
        background-color: #f6f8fb; }
      .adress-selector .menu .item .city, .goods-selector .menu .item .city {
        display: block;
        font-size: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(50, 60, 93, 0.5); }

.widget {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .widget-address {
    padding: 17px 15px; }
    .widget-address-inputs {
      position: relative;
      margin-right: -15px; }
      .widget-address-inputs::before {
        content: "";
        position: absolute;
        left: 7px;
        top: 15px;
        bottom: 15px;
        width: 2px;
        background-color: #cccfd7; }
    .widget-address-input {
      position: relative;
      padding: 0 15px 0 26px; }
      .widget-address-input:not(:last-child) {
        padding-bottom: 16px;
        margin-bottom: 16px; }
        .widget-address-input:not(:last-child)::after {
          content: "";
          position: absolute;
          left: 26px;
          bottom: 0;
          right: 0;
          height: 1px;
          background-color: #dfe6f4; }
      .widget-address-input .input {
        position: relative; }
      .widget-address-input .icon {
        position: absolute;
        left: 0;
        top: 3px;
        width: 16px;
        height: 16px;
        background-size: 100% 100%; }
      .widget-address-input .geo-icon {
        position: absolute;
        right: 8px;
        bottom: 1px;
        z-index: 1;
        width: 16px;
        height: 16px;
        background-image: url("/static/img/sprite-calc.svg");
        background-position: 0 -42px;
        cursor: pointer; }
      .widget-address-input.t-1:not(.active) .icon {
        background-image: url("/static/img/widget-tariff/ic_route_A_16_blue.svg"); }
      .widget-address-input.t-1 .adress-selector .delete {
        right: 23px; }
      .widget-address-input.t-1 .adress-selector .wrap-inp::after {
        width: 100px; }
      .widget-address-input.t-2:not(.active) .icon {
        background-image: url("/static/img/widget-tariff/ic_route_B_16_red.svg"); }
      .widget-address-input.active.t-1 .icon {
        background-image: url("/static/img/widget-tariff/ic_route_A_16_blue.svg"); }
      .widget-address-input.active.t-2 .icon {
        background-image: url("/static/img/widget-tariff/ic_route_B_16_red.svg"); }
      .widget-address-input .input > * {
        display: block; }
      .widget-address-input .input .label {
        font-size: 13px;
        color: rgba(50, 60, 93, 0.5);
        margin-bottom: 5px; }
      .widget-address-input .adress-selector {
        background-color: transparent;
        width: 100%;
        height: auto;
        border: 0;
        padding: 0; }
        .widget-address-input .adress-selector .menu {
          width: auto;
          left: 0;
          right: -15px; }
        .widget-address-input .adress-selector .wrap-inp {
          font-size: 0;
          line-height: 1; }
          .widget-address-input .adress-selector .wrap-inp input {
            vertical-align: top; }
  .widget-tariff {
    position: relative;
    min-height: 124px;
    overflow: hidden; }
    .widget-tariff-item:not(:last-child) {
      border-bottom: 1px solid #dfe6f4; }
    .widget-tariff-item.active .widget-tariff-head {
      display: none; }
    .widget-tariff-item.active .widget-tariff-cnt {
      display: block; }
    .widget-tariff-head {
      position: relative;
      cursor: pointer;
      background-color: #ffffff;
      min-height: 40px;
      line-height: 24px;
      padding: 8px 15px 8px 49px; }
      .widget-tariff-head:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px; }
      .widget-tariff-head:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px; }
      .widget-tariff-head .img {
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -6px;
        height: 14px;
        font-size: 0;
        line-height: 1;
        display: flex;
        align-items: center; }
      .widget-tariff-head .img-icon {
        background-image: url("/static/img/sprite-calc.svg"); }
        .widget-tariff-head .img-icon.mini2 {
          width: 20px;
          height: 11px;
          background-position: -206px -77px; }
        .widget-tariff-head .img-icon.standart2 {
          width: 22px;
          height: 12px;
          background-position: -239px -76px; }
        .widget-tariff-head .img-icon.extra2 {
          width: 24px;
          height: 14px;
          background-position: -272px -74px; }
        .widget-tariff-head .img-icon.maxi2 {
          width: 24px;
          height: 13px;
          background-position: -306px -75px; }
      .widget-tariff-head .info {
        position: relative; }
        .widget-tariff-head .info > * {
          display: inline-block;
          vertical-align: top; }
      .widget-tariff-head .type {
        font-size: 15px;
        color: #323c5d;
        opacity: 0.7; }
      .widget-tariff-head .time {
        position: relative;
        top: 2px;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 10px;
        color: rgba(50, 60, 93, 0.5);
        line-height: 1.4;
        padding: 3px 8px;
        margin: 0 0 0 13px;
        border-radius: 4px;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        background-color: rgba(208, 216, 230, 0.2); }
        .widget-tariff-head .time .icon {
          position: relative;
          top: 2px;
          display: inline-block;
          width: 12px;
          height: 12px;
          background-image: url("/static/img/sprite-calc.svg");
          background-position: -44px -102px;
          margin: 0 4px 0 0; }
      .widget-tariff-head .price {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 15px;
        color: #323c5d; }
        .widget-tariff-head .price .als-rubl {
          font-size: 14px; }
        .widget-tariff-head .price .count {
          font-size: 11px;
          color: rgba(50, 60, 93, 0.5); }
    .widget-tariff-cnt {
      display: none;
      background-color: #fafbfc;
      padding: 15px 15px 20px; }
    .widget-tariff-info {
      margin-bottom: 20px; }
      .widget-tariff-info::after {
        display: block;
        clear: both;
        content: ""; }
      .widget-tariff-info > * {
        display: inline-block;
        vertical-align: top; }
      .widget-tariff-info .price {
        float: right;
        font-size: 32px;
        color: #323c5d;
        line-height: 1.34; }
        .widget-tariff-info .price .count {
          font-size: 15px;
          color: rgba(50, 60, 93, 0.5); }
      .widget-tariff-info .info > * {
        display: block; }
      .widget-tariff-info .tariff {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.5); }
      .widget-tariff-info .mass {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 17px;
        color: #323c5d;
        line-height: 1.35;
        margin: 2px 0 0; }
        .widget-tariff-info .mass .count {
          color: #4174d6; }
        .widget-tariff-info .mass .icon {
          display: inline-block;
          width: 12px;
          height: 12px;
          background-image: url("/static/img/widget-tariff/ic_info.svg");
          background-size: 100% 100%;
          cursor: pointer; }
    .widget-tariff-car::after {
      display: block;
      clear: both;
      content: ""; }
    .widget-tariff-car .column {
      float: left; }
      .widget-tariff-car .column:nth-child(1) {
        width: 165px; }
      .widget-tariff-car .column:nth-child(2) {
        width: calc(100% - 165px);
        padding-left: 15px; }
    .widget-tariff-car .car {
      height: 70px;
      margin-bottom: 2px; }
      .widget-tariff-car .car img {
        vertical-align: top;
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%; }
    .widget-tariff-car .btn {
      background-color: #fc921f;
      border-radius: 10px;
      border-color: #fc921f;
      font-size: 13px;
      line-height: 1.38;
      color: #ffffff;
      padding: 11px 15px 11px 15px; }
      .widget-tariff-car .btn:hover {
        background-color: #fb5c3f;
        border-color: #fb5c3f;
        color: #ffffff;
        -webkit-box-shadow: 0 5px 20px 0 rgba(252, 146, 31, 0.5);
        -moz-box-shadow: 0 5px 20px 0 rgba(252, 146, 31, 0.5);
        box-shadow: 0 5px 20px 0 rgba(252, 146, 31, 0.5); }
        .widget-tariff-car .btn:hover .icon {
          transition: 0.25s ease-in-out;
          margin-left: 15px; }
      .widget-tariff-car .btn .icon {
        position: relative;
        top: -1px;
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        background-image: url("/static/img/widget-tariff/ic_arrow_long.svg");
        background-size: 100% 100%;
        cursor: pointer;
        margin-left: 10px; }
      .widget-tariff-car .btn .count {
        position: relative;
        top: -2px;
        display: inline-block;
        vertical-align: middle;
        height: 16px;
        background-color: #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 16px;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 11px;
        color: #aaafbd;
        margin-right: 10px;
        padding: 0 4px 0 6px; }
    .widget-tariff-car .info-1 {
      overflow: hidden;
      font-size: 11px;
      color: rgba(50, 60, 93, 0.85);
      line-height: 1.36;
      margin-bottom: 10px; }
    .widget-tariff-car .info-2 {
      max-height: 28px;
      overflow: hidden;
      font-size: 11px;
      line-height: 1.36;
      color: rgba(50, 60, 93, 0.5);
      margin-bottom: 16px; }
    .widget-tariff-car .options-list {
      padding-left: 5px; }
      .widget-tariff-car .options-list li {
        position: relative;
        float: left;
        font-size: 10px;
        color: rgba(50, 60, 93, 0.5);
        padding-left: 16px; }
        .widget-tariff-car .options-list li:not(:last-child) {
          margin-right: 6px; }
        .widget-tariff-car .options-list li .icon {
          position: absolute;
          left: 0;
          top: 1px;
          display: inline-block;
          background-image: url("/static/img/sprite-index.svg");
          cursor: pointer; }
        .widget-tariff-car .options-list li.t-1 .icon {
          width: 12px;
          height: 12px;
          background-position: -608px -510px; }
        .widget-tariff-car .options-list li.t-2 .icon {
          width: 13px;
          height: 12px;
          background-position: -629px -510px; }
    .widget-tariff-car .time {
      display: block;
      position: relative;
      min-height: 24px;
      font-size: 10px;
      font-weight: 400;
      color: rgba(50, 60, 93, 0.4);
      line-height: 1.4;
      margin-top: 7px;
      padding-left: 30px; }
      .widget-tariff-car .time span {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 600;
        color: rgba(50, 60, 93, 0.5); }
      .widget-tariff-car .time .icon {
        position: absolute;
        left: 0;
        top: 2px;
        display: inline-block;
        width: 22px;
        height: 22px;
        background-image: url("/static/img/sprite-calc.svg");
        background-position: -1px -69px;
        cursor: pointer; }
  .widget-f-status-menu {
    padding: 10px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .widget-f-status-menu .menu {
      margin-bottom: 0; }
      .widget-f-status-menu .menu li:not(:last-child) {
        margin-bottom: 10px; }
      .widget-f-status-menu .menu li.active .item .check {
        background-color: #63b463;
        border-color: #63b463; }
        .widget-f-status-menu .menu li.active .item .check::before {
          opacity: 1; }
      .widget-f-status-menu .menu .item {
        position: relative;
        background-color: #ffffff;
        display: block;
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d;
        cursor: pointer;
        padding: 6px 46px 6px 41px; }
        .widget-f-status-menu .menu .item:hover, .widget-f-status-menu .menu .item:focus, .widget-f-status-menu .menu .item:active {
          text-decoration: none; }
        .widget-f-status-menu .menu .item .icon {
          position: absolute;
          left: 20px;
          top: 9px;
          width: 10px;
          height: 10px;
          border-radius: 50%; }
        .widget-f-status-menu .menu .item .check {
          position: absolute;
          right: 20px;
          top: 4px;
          width: 20px;
          height: 20px;
          border: 1px solid #dfe6f4;
          border-radius: 4px;
          background-color: #ffffff; }
          .widget-f-status-menu .menu .item .check::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 12px;
            height: 12px;
            background-image: url("/static/img/lk/info/ic_done_12.svg");
            background-size: 100% 100%;
            opacity: 0;
            margin-left: -6px;
            margin-top: -6px; }
        .widget-f-status-menu .menu .item.t-0 .icon {
          background-color: #4174d6; }
        .widget-f-status-menu .menu .item.t-1 .icon {
          background-color: #ffc658; }
        .widget-f-status-menu .menu .item.t-2 .icon {
          background-color: #63b463; }
        .widget-f-status-menu .menu .item.t-3 .icon {
          background-color: #fb5c3f; }
        .widget-f-status-menu .menu .item.t-4 .icon {
          background-color: #888888; }
  .widget-f-status-others {
    border-top: 1px solid #dfe6f4;
    padding: 10px 0; }
    .widget-f-status-others.active .top .arrow {
      transform: rotate(-90deg); }
    .widget-f-status-others.active .others-wrapper {
      display: block; }
    .widget-f-status-others:not(.active) .others-wrapper {
      display: none; }
    .widget-f-status-others .top {
      position: relative;
      background-color: #ffffff;
      display: block;
      font-size: 13px;
      line-height: 1.38;
      color: #323c5d;
      cursor: pointer;
      padding: 6px 36px 6px 41px; }
      .widget-f-status-others .top:hover, .widget-f-status-others .top:focus, .widget-f-status-others .top:active {
        text-decoration: none; }
      .widget-f-status-others .top:hover {
        color: #4174d6; }
        .widget-f-status-others .top:hover .icon .cls-2 {
          fill: #4174d6; }
      .widget-f-status-others .top .icon {
        position: absolute;
        left: 20px;
        top: 8px;
        width: 12px;
        height: 12px;
        font-size: 0;
        line-height: 1; }
        .widget-f-status-others .top .icon .svg {
          width: 100%;
          height: 100%; }
        .widget-f-status-others .top .icon .cls-1 {
          fill: none; }
        .widget-f-status-others .top .icon .cls-2 {
          fill: rgba(50, 60, 93, 0.5); }
      .widget-f-status-others .top .arrow {
        position: absolute;
        right: 20px;
        top: 9px;
        width: 12px;
        height: 12px;
        font-size: 0;
        line-height: 1; }
        .widget-f-status-others .top .arrow .svg {
          width: 100%;
          height: 100%; }
        .widget-f-status-others .top .arrow .cls-1 {
          fill: none; }
        .widget-f-status-others .top .arrow .cls-2 {
          fill: #323c5d; }
    .widget-f-status-others .search {
      padding: 0 20px;
      margin-top: 10px; }
      .widget-f-status-others .search .form-control {
        background-color: #f5f8fc;
        border-color: #f5f8fc; }
  .widget-f-edits {
    padding: 10px 0; }
    .widget-f-edits .top {
      position: relative;
      background-color: #ffffff;
      display: block;
      font-size: 13px;
      line-height: 1.38;
      color: #323c5d;
      cursor: pointer;
      padding: 6px 36px 6px 41px; }
      .widget-f-edits .top .icon {
        position: absolute;
        left: 20px;
        top: 8px;
        width: 12px;
        height: 12px;
        font-size: 0;
        line-height: 1;
        opacity: 0.5;
        background-size: 100% 100%; }
        .widget-f-edits .top .icon .svg {
          width: 100%;
          height: 100%; }
        .widget-f-edits .top .icon .cls-1 {
          fill: none; }
        .widget-f-edits .top .icon .cls-2 {
          fill: rgba(50, 60, 93, 0.5); }
        .widget-f-edits .top .icon.t-calendar {
          background-image: url("/static/img/lk/filter/ic_calendar_12.svg"); }
        .widget-f-edits .top .icon.t-phone {
          background-image: url("/static/img/lk/filter/ic_call_12.svg"); }
        .widget-f-edits .top .icon.t-number {
          background-image: url("/static/img/lk/filter/ic_seen_12.svg"); }
        .widget-f-edits .top .icon.t-status {
          background-image: url("/static/img/lk/filter/ic_cardstack_12.svg"); }
    .widget-f-edits .drop-form {
      padding: 0 20px 10px; }
      .widget-f-edits .drop-form .form-control {
        background-color: #f5f8fc;
        border-color: #f5f8fc; }
      .widget-f-edits .drop-form .btn {
        border-radius: 6px;
        box-shadow: 0 2px 10px 0 rgba(65, 116, 214, 0.3);
        font-size: 11px; }
  .widget-f-calendar .calendar-wrapper .react-datepicker {
    display: block;
    border: 0;
    box-shadow: none;
    top: auto;
    left: auto; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__month-container {
      border-bottom: 0;
      float: none;
      margin-bottom: 0; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__header {
      background-color: transparent;
      border-bottom: 0; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__current-month {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: 600;
      font-size: 20px;
      padding: 0 44px; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__day, .widget-f-calendar .calendar-wrapper .react-datepicker__day-name {
      width: 30px;
      margin: 5px; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__day-names {
      padding: 0 10px; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__day-name {
      font-size: 13px;
      line-height: 3.15;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      color: rgba(50, 60, 93, 0.4); }
    .widget-f-calendar .calendar-wrapper .react-datepicker__day--disabled {
      color: rgba(50, 60, 93, 0.4); }
    .widget-f-calendar .calendar-wrapper .react-datepicker__day {
      border-radius: 50%;
      font-size: 17px;
      line-height: 1.8;
      letter-spacing: 0.4px; }
      .widget-f-calendar .calendar-wrapper .react-datepicker__day--in-range {
        background-color: rgba(252, 146, 31, 0.2);
        color: #323c5d; }
      .widget-f-calendar .calendar-wrapper .react-datepicker__day--range-end {
        background-color: #fc921f;
        color: #ffffff; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__day--today {
      background-color: #fc921f;
      font-weight: 400;
      color: #ffffff; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__month {
      margin: 0;
      padding: 0 10px; }
    .widget-f-calendar .calendar-wrapper .react-datepicker__navigation {
      background: url("/static/img/lk/ic_chevron_right_24.svg");
      background-size: 100% 100%;
      line-height: 1;
      top: 12px;
      border: 0;
      height: 24px;
      width: 24px; }
      .widget-f-calendar .calendar-wrapper .react-datepicker__navigation:hover, .widget-f-calendar .calendar-wrapper .react-datepicker__navigation:focus {
        outline: none; }
      .widget-f-calendar .calendar-wrapper .react-datepicker__navigation--previous {
        left: 13px;
        transform: rotate(-180deg); }
      .widget-f-calendar .calendar-wrapper .react-datepicker__navigation--next {
        right: 13px; }
  .widget-f-calendar-bottom {
    border-top: 1px solid #dfe6f4;
    padding-top: 10px; }
    .widget-f-calendar-bottom .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .widget-f-calendar-bottom .wrapper > * {
        display: inline-block;
        vertical-align: middle; }
    .widget-f-calendar-bottom .column-l > * {
      display: block; }
    .widget-f-calendar-bottom .column-l .time {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #323c5d; }
    .widget-f-calendar-bottom .column-l .date {
      font-size: 10px;
      line-height: 1.2;
      color: rgba(50, 60, 93, 0.5); }
  .widget-f-calendar-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 10px; }
    .widget-f-calendar-time > * {
      display: inline-block;
      vertical-align: middle; }
    .widget-f-calendar-time .form-control {
      background-color: #f5f8fc;
      border-color: #f5f8fc;
      width: 47%; }
    .widget-f-calendar-time .line {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.3); }
  .widget-price-edit {
    text-align: left; }
    .widget-price-edit-top {
      padding: 20px 20px 10px; }
      .widget-price-edit-top:not(:last-child) {
        border-bottom: 1px solid #dfe6f4; }
      .widget-price-edit-top > * {
        display: block; }
      .widget-price-edit-top .price {
        font-size: 30px;
        line-height: 1.37;
        color: #323c5d; }
    .widget-price-edit-bottom {
      padding: 10px 20px; }
    .widget-price-edit-type {
      padding: 18px 20px 10px; }
      .widget-price-edit-type:not(:last-child) {
        border-bottom: 1px solid #dfe6f4; }
      .widget-price-edit-type > * {
        display: block; }
    .widget-price-edit .label {
      font-size: 10px;
      line-height: 1.2;
      letter-spacing: 0.3px;
      color: rgba(50, 60, 93, 0.5);
      text-transform: uppercase; }
    .widget-price-edit-list {
      position: relative;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 0; }
      .widget-price-edit-list > li {
        background-color: transparent;
        position: relative;
        cursor: pointer;
        padding: 6px 42px 6px 20px;
        transition: 0.25s ease-in-out; }
        .widget-price-edit-list > li:hover {
          background-color: rgba(65, 116, 214, 0.05); }
          .widget-price-edit-list > li:hover .check {
            opacity: 1; }
        .widget-price-edit-list > li.active .check {
          opacity: 1;
          background-color: #63b463;
          border-color: #63b463; }
          .widget-price-edit-list > li.active .check::before {
            opacity: 1; }
        .widget-price-edit-list > li:not(:last-child) {
          margin-bottom: 10px; }
        .widget-price-edit-list > li > * {
          display: inline-block;
          vertical-align: middle; }
        .widget-price-edit-list > li .type-title {
          font-size: 13px;
          line-height: 1.38;
          color: #323c5d; }
        .widget-price-edit-list > li .type-img {
          margin-right: 10px; }
        .widget-price-edit-list > li .icon {
          position: absolute;
          right: 20px;
          bottom: 9px;
          width: 12px;
          height: 12px;
          background-image: url("/static/img/lk/ic_chevron_right_12_2.svg");
          background-size: 100% 100%; }
        .widget-price-edit-list > li .check {
          opacity: 0;
          position: absolute;
          right: 20px;
          top: 6px;
          width: 20px;
          height: 20px;
          border: 1px solid #dfe6f4;
          border-radius: 4px;
          background-color: #ffffff; }
          .widget-price-edit-list > li .check::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 12px;
            height: 12px;
            background-image: url("/static/img/lk/info/ic_done_12.svg");
            background-size: 100% 100%;
            opacity: 1;
            margin-left: -6px;
            margin-top: -6px; }
      .widget-price-edit-list.t-top {
        z-index: 1;
        margin-top: 10px; }
      .widget-price-edit-list.t-type {
        margin-top: 18px; }
    .widget-price-edit-card {
      position: relative;
      margin-left: 10px; }
      .widget-price-edit-card .card-item {
        position: relative;
        font-size: 13px;
        line-height: 1.38;
        color: #4174d6;
        cursor: pointer;
        padding-left: 24px; }
        .widget-price-edit-card .card-item .card-bank {
          position: absolute;
          left: 0;
          top: 3px;
          width: 20px;
          height: 12px;
          border-radius: 2px;
          background-color: rgba(65, 116, 214, 0.2);
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 18px 10px; }
          .widget-price-edit-card .card-item .card-bank.t-maestro {
            background-image: url("/static/img/calc/step5/Maestro.svg"); }
          .widget-price-edit-card .card-item .card-bank.t-mastercard {
            background-image: url("/static/img/calc/step5/MasterCard.svg"); }
          .widget-price-edit-card .card-item .card-bank.t-mircard {
            background-image: url("/static/img/calc/step5/MirCard.svg"); }
          .widget-price-edit-card .card-item .card-bank.t-visacard {
            background-image: url("/static/img/calc/step5/VisaCard.svg"); }
        .widget-price-edit-card .card-item .card-title {
          position: relative; }
      .widget-price-edit-card .card-drop {
        position: absolute;
        left: -15px;
        top: -15px;
        right: -15px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.16);
        padding: 38px 15px 15px; }
      .widget-price-edit-card .card-list {
        margin-bottom: 0;
        margin-left: -15px;
        margin-right: -15px; }
        .widget-price-edit-card .card-list > li {
          cursor: pointer;
          padding: 6px 15px 6px;
          transition: 0.25s ease-in-out; }
          .widget-price-edit-card .card-list > li:hover {
            background-color: rgba(65, 116, 214, 0.05); }
      .widget-price-edit-card .card-add {
        position: relative;
        display: block;
        cursor: pointer;
        font-size: 11px;
        line-height: 1.36;
        color: #4174d6;
        margin: 10px -15px 0;
        padding: 6px 15px 6px 41px; }
        .widget-price-edit-card .card-add .add {
          position: absolute;
          left: 15px;
          top: 7px;
          width: 20px;
          height: 12px;
          border-radius: 2px;
          background-color: rgba(65, 116, 214, 0.2); }
  .widget-driver-edit {
    text-align: left; }
    .widget-driver-edit-top {
      border-bottom: 1px solid #dfe6f4;
      padding: 20px 20px 20px; }
    .widget-driver-edit-user {
      min-height: 40px;
      position: relative;
      padding-left: 50px; }
      .widget-driver-edit-user .img-user {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        background-color: #dfe6f4;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        display: flex;
        /*justify-content: center;
        align-items: center;*/ }
        .widget-driver-edit-user .img-user img {
          vertical-align: top;
          width: 100%;
          object-fit: cover; }
      .widget-driver-edit-user .cnt > * {
        display: block; }
      .widget-driver-edit-user .name {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        line-height: 20px;
        color: #323c5d; }
      .widget-driver-edit-user .phone {
        font-size: 13px;
        color: rgba(50, 60, 93, 0.5);
        line-height: 18px; }
      .widget-driver-edit-user .user-company {
        border-radius: 4px;
        background-color: rgba(252, 146, 31, 0.15);
        display: inline-block;
        vertical-align: top;
        font-size: 10px;
        line-height: 1.4;
        color: #fc921f;
        padding: 3px 8px;
        margin-top: 9px; }
    .widget-driver-edit-car {
      min-height: 24px;
      position: relative;
      padding-left: 50px;
      margin-top: 20px; }
      .widget-driver-edit-car .img-car {
        position: absolute;
        left: 8px;
        top: 0;
        width: 24px;
        height: 24px;
        overflow: hidden;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; }
        .widget-driver-edit-car .img-car img {
          vertical-align: top;
          max-width: 100%;
          width: auto;
          max-height: 100%;
          height: auto; }
      .widget-driver-edit-car .cnt > * {
        display: block; }
      .widget-driver-edit-car .name {
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d; }
      .widget-driver-edit-car .info {
        font-size: 10px;
        color: rgba(50, 60, 93, 0.7);
        line-height: 1.4; }
      .widget-driver-edit-car .car-number {
        border-radius: 4px;
        background-color: #ffffff;
        border: 1px solid #dfe6f4;
        display: inline-block;
        vertical-align: top;
        font-size: 10px;
        line-height: 1.4;
        color: #323c5d;
        padding: 3px 8px;
        margin-top: 8px; }
    .widget-driver-edit-list {
      padding: 10px 20px; }
      .widget-driver-edit-list:not(:last-child) {
        border-bottom: 1px solid #dfe6f4; }
    .widget-driver-edit-bottom {
      padding: 10px 20px; }
      .widget-driver-edit-bottom .driver-del {
        display: block;
        cursor: pointer;
        font-size: 13px;
        line-height: 1.38;
        color: #fb5c3f;
        margin: 0 -20px;
        padding: 6px 20px; }
  .widget-company-edit {
    text-align: left; }
    .widget-company-edit-top {
      padding: 20px 20px 20px; }
    .widget-company-edit-user {
      min-height: 40px;
      position: relative;
      padding-left: 50px; }
      .widget-company-edit-user .name {
        white-space: normal; }
      .widget-company-edit-user .img-user {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        background-color: #dfe6f4;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        display: flex;
        /*justify-content: center;
        align-items: center;*/ }
        .widget-company-edit-user .img-user img {
          vertical-align: top;
          width: 100%;
          object-fit: cover; }
      .widget-company-edit-user .cnt > * {
        display: block; }
      .widget-company-edit-user .name {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        line-height: 20px;
        color: #323c5d; }
      .widget-company-edit-user .phone {
        font-size: 13px;
        color: rgba(50, 60, 93, 0.5);
        line-height: 18px; }
      .widget-company-edit-user .user-company {
        border-radius: 4px;
        background-color: rgba(252, 146, 31, 0.15);
        display: inline-block;
        vertical-align: top;
        font-size: 10px;
        line-height: 1.4;
        color: #fc921f;
        padding: 3px 8px;
        margin-top: 9px; }
  .widget-order-edit-top {
    position: relative;
    border-bottom: 1px solid #dfe6f4;
    padding: 20px 20px 10px; }
    .widget-order-edit-top .order-number {
      display: block;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      line-height: 1.35;
      color: #323c5d; }
    .widget-order-edit-top .order-time {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 20px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.5); }
    .widget-order-edit-top .buttons {
      margin-top: 10px; }
  .widget-order-edit-list {
    padding: 10px 20px; }
    .widget-order-edit-list:not(:last-child) {
      border-bottom: 1px solid #dfe6f4; }
  .widget-order-edit-bottom {
    padding: 10px 20px; }
    .widget-order-edit-bottom .order-del {
      display: block;
      cursor: pointer;
      font-size: 13px;
      line-height: 1.38;
      color: #fb5c3f;
      margin: 0 -20px;
      padding: 6px 20px; }
  .widget-order-edit .label {
    display: block;
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.3px;
    color: rgba(50, 60, 93, 0.5);
    text-transform: uppercase;
    margin-bottom: 18px; }
  .widget-order-edit .order-status {
    position: relative;
    cursor: pointer;
    margin: 5px -20px 0;
    padding: 6px 42px 6px 20px; }
    .widget-order-edit .order-status > * {
      display: inline-block;
      vertical-align: middle; }
    .widget-order-edit .order-status-title {
      font-size: 13px;
      line-height: 1.38;
      color: #323c5d; }
    .widget-order-edit .order-status .type-status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: -1px; }
      .widget-order-edit .order-status .type-status.t-color-1 {
        background-color: #ffc658; }
    .widget-order-edit .order-status .type-order {
      width: 12px;
      height: 12px;
      background-size: 100% 100%;
      margin-right: 10px;
      margin-top: -1px; }
      .widget-order-edit .order-status .type-order.t-start {
        background-image: url("/static/img/lk/info/ic_route_A_16.svg"); }
    .widget-order-edit .order-status-label {
      border-radius: 12px;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 10px;
      line-height: 1.4;
      letter-spacing: 0.4px;
      cursor: pointer;
      padding: 2px 7px 3px 7px; }
      .widget-order-edit .order-status-label.t-success {
        background-color: #63b463;
        color: #ffffff; }
      .widget-order-edit .order-status-label.t-error {
        background-color: #ff3333;
        color: #ffffff; }
      .widget-order-edit .order-status-label .icon {
        position: relative;
        top: 2px;
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url("/static/img/lk/info/ic_done_12.svg");
        background-size: 100% 100%;
        margin-right: 5px; }
  .widget-order-edit-driver {
    padding: 18px 20px 15px; }
    .widget-order-edit-driver:not(:last-child) {
      border-bottom: 1px solid #dfe6f4; }
  .widget-order-edit-stars {
    padding: 18px 20px 15px; }
    .widget-order-edit-stars:not(:last-child) {
      border-bottom: 1px solid #dfe6f4; }
    .widget-order-edit-stars .stars-list::after {
      display: block;
      clear: both;
      content: ""; }
    .widget-order-edit-stars .stars-list:last-child {
      margin-bottom: 0; }
    .widget-order-edit-stars .stars-list.no-rating:hover .star-icon {
      background-image: url("/static/img/lk/widgets/edit/ic_star_border_black_24px_active.svg");
      cursor: pointer; }
    .widget-order-edit-stars .stars-list.no-rating li:hover ~ li .star-icon {
      background-image: url("/static/img/lk/widgets/edit/ic_star_border_black_24px.svg"); }
    .widget-order-edit-stars .stars-list li {
      float: left; }
      .widget-order-edit-stars .stars-list li:not(:last-child) {
        padding-right: 24px; }
      .widget-order-edit-stars .stars-list li .star-icon {
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 24px;
        background-image: url("/static/img/lk/widgets/edit/ic_star_border_black_24px.svg");
        background-size: 100% 100%; }
        .widget-order-edit-stars .stars-list li .star-icon.is-active {
          background-image: url("/static/img/lk/widgets/edit/ic_star_border_black_24px_active.svg"); }
    .widget-order-edit-stars .stars-success {
      display: block;
      font-size: 13px;
      line-height: 1.38;
      color: #63b463; }
  .widget-order-edit-line {
    height: 3px;
    background-color: #f0f3f7;
    border-radius: 1.5px;
    overflow: hidden;
    margin-top: 10px; }
    .widget-order-edit-line .progress-line {
      display: block;
      width: 0;
      height: 100%;
      background-color: #fc921f;
      transition: 0.25s linear; }
  .widget-status-edit-path {
    padding: 15px 15px 15px; }
    .widget-status-edit-path:not(:last-child) {
      border-bottom: 1px solid #dfe6f4; }
  .widget-status-edit-item {
    position: relative;
    padding-left: 26px; }
    .widget-status-edit-item::before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      background-size: 100% 100%; }
    .widget-status-edit-item > * {
      display: block; }
    .widget-status-edit-item .status-title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #323c5d; }
    .widget-status-edit-item.t-start::before {
      background-image: url("/static/img/lk/info/ic_route_A_16.svg"); }
    .widget-status-edit-item.t-point::before {
      background-image: url("/static/img/lk/info/ic_route_point_16.svg"); }
    .widget-status-edit-item.t-route::before {
      background-image: url("/static/img/lk/info/ic_route_mid_24.svg"); }
    .widget-status-edit-item.t-finish::before {
      background-image: url("/static/img/lk/info/ic_route_B_16.svg"); }
  .widget-status-edit-list {
    margin-bottom: 0;
    margin-top: 10px; }
    .widget-status-edit-list li::after {
      display: block;
      clear: both;
      content: ""; }
    .widget-status-edit-list li > * {
      display: inline-block;
      vertical-align: top; }
    .widget-status-edit-list li:not(:last-child) {
      margin-bottom: 10px; }
    .widget-status-edit-list li .desc-list {
      float: left;
      font-size: 11px;
      line-height: 1.36;
      color: rgba(50, 60, 93, 0.7); }
    .widget-status-edit-list li .time-list {
      float: right;
      font-size: 11px;
      line-height: 1.36;
      color: #323c5d; }
  .widget-status-edit-done {
    position: relative;
    padding-left: 26px; }
    .widget-status-edit-done::before {
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      width: 20px;
      height: 20px;
      background-image: url("/static/img/lk/info/ic_circ_done_20.svg");
      background-size: 100% 100%; }
    .widget-status-edit-done > * {
      display: inline-block;
      vertical-align: middle; }
    .widget-status-edit-done .status-title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #63b463; }
    .widget-status-edit-done .status-time {
      position: absolute;
      right: 0;
      top: 0;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #323c5d; }
  .widget-status-edit-user {
    padding: 15px 15px 15px; }
  .widget-status-edit-driver {
    position: relative;
    min-height: 36px;
    border-radius: 10px;
    background-color: #f8f8f8;
    padding: 6px 6px 6px 44px; }
    .widget-status-edit-driver .img {
      position: absolute;
      left: 10px;
      top: 11px;
      width: 24px;
      height: 24px;
      background-color: #dfe6f4;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      display: flex;
      /*justify-content: center;
        align-items: center;*/ }
      .widget-status-edit-driver .img img {
        vertical-align: top;
        /*width: auto;
          max-height: 100%;
          height: auto;*/
        width: 100%;
        object-fit: cover; }
    .widget-status-edit-driver .cnt > * {
      display: block; }
    .widget-status-edit-driver .name {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 13px;
      line-height: 18px;
      color: #323c5d; }
    .widget-status-edit-driver .position {
      font-size: 11px;
      line-height: 15px;
      color: rgba(50, 60, 93, 0.5); }
  .widget-status-edit-scroll {
    max-height: 211px;
    overflow-y: auto;
    -ms-overflow-style: none; }
    .widget-status-edit-scroll::-webkit-scrollbar {
      width: 0; }
  .widget-edit-list {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0; }
    .widget-edit-list > li {
      background-color: transparent;
      position: relative;
      cursor: pointer;
      padding: 6px 42px 6px 20px;
      transition: 0.25s ease-in-out; }
      .widget-edit-list > li:hover {
        background-color: rgba(65, 116, 214, 0.05); }
      .widget-edit-list > li:not(:last-child) {
        margin-bottom: 10px; }
      .widget-edit-list > li > * {
        display: inline-block;
        vertical-align: middle; }
      .widget-edit-list > li .edit-title {
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d; }
      .widget-edit-list > li .edit-img {
        margin-top: -1px;
        margin-right: 10px; }
      .widget-edit-list > li .icon {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        background-image: url("/static/img/lk/ic_chevron_right_12_2.svg");
        background-size: 100% 100%; }
  .widget-cancel-order {
    padding: 20px 20px 20px; }
    .widget-cancel-order-menu {
      margin: 0 -20px; }
      .widget-cancel-order-menu .menu {
        margin-bottom: 0; }
        .widget-cancel-order-menu .menu li:not(:last-child) {
          margin-bottom: 10px; }
        .widget-cancel-order-menu .menu li.active .item .check {
          background-color: #63b463;
          border-color: #63b463; }
          .widget-cancel-order-menu .menu li.active .item .check::before {
            opacity: 1; }
        .widget-cancel-order-menu .menu .item {
          position: relative;
          background-color: #ffffff;
          display: block;
          font-size: 13px;
          line-height: 1.38;
          color: #323c5d;
          cursor: pointer;
          padding: 6px 46px 6px 20px; }
          .widget-cancel-order-menu .menu .item .check {
            position: absolute;
            right: 20px;
            top: 4px;
            width: 20px;
            height: 20px;
            border: 1px solid #dfe6f4;
            border-radius: 4px;
            background-color: #ffffff; }
            .widget-cancel-order-menu .menu .item .check::before {
              content: '';
              position: absolute;
              left: 50%;
              top: 50%;
              width: 12px;
              height: 12px;
              background-image: url("/static/img/lk/info/ic_done_12.svg");
              background-size: 100% 100%;
              opacity: 0;
              margin-left: -6px;
              margin-top: -6px; }
    .widget-cancel-order .title-cancel {
      display: block;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 25px;
      line-height: 1.36;
      color: #323c5d;
      margin-bottom: 10px; }
    .widget-cancel-order .desc-cancel {
      font-size: 15px;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.7); }
    .widget-cancel-order .btn-cancel {
      margin-top: 20px; }
      .widget-cancel-order .btn-cancel .btn {
        background-color: #fb5c3f;
        border-color: #fb5c3f;
        border-radius: 6px;
        box-shadow: 0 3px 10px 0 rgba(251, 92, 63, 0.2);
        font-size: 11px; }
  .widget-lang-menu {
    padding: 10px 0; }
    .widget-lang-menu .menu {
      text-align: left;
      margin-bottom: 0; }
      .widget-lang-menu .menu .item {
        position: relative;
        background-color: #ffffff;
        display: block;
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d;
        cursor: pointer;
        padding: 6px 15px 6px 15px; }
        .widget-lang-menu .menu .item:hover, .widget-lang-menu .menu .item:focus, .widget-lang-menu .menu .item:active {
          text-decoration: none; }
        .widget-lang-menu .menu .item:hover {
          color: #4174d6; }

.widget-profile {
  border-radius: 10px;
  overflow: hidden;
  text-align: left; }
  .widget-profile-user {
    border-bottom: 1px solid #dfe6f4;
    padding: 20px 20px 10px; }
    .widget-profile-user::after {
      display: block;
      clear: both;
      content: ""; }
    .widget-profile-user .user {
      position: relative;
      width: 40px;
      height: 40px;
      overflow: hidden;
      float: left;
      background-color: #dfe6f4;
      border-radius: 50%;
      display: flex;
      /*justify-content: center;
        align-items: center;*/
      text-align: center; }
      .widget-profile-user .user img {
        vertical-align: top;
        width: 100%;
        object-fit: cover; }
      .widget-profile-user .user .success {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 14px;
        height: 14px;
        background-color: #63b463;
        border: solid 1px #ffffff;
        border-radius: 50%; }
    .widget-profile-user .cnt {
      min-height: 40px;
      margin-left: 50px; }
      .widget-profile-user .cnt > *:not(.company) {
        display: block; }
    .widget-profile-user .name {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 20px;
      color: #323c5d; }
    .widget-profile-user .balance {
      font-size: 13px;
      line-height: 18px;
      color: rgba(50, 60, 93, 0.5); }
    .widget-profile-user .company {
      display: inline-block;
      vertical-align: top;
      border-radius: 4px;
      background-color: rgba(252, 146, 31, 0.15);
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 10px;
      line-height: 1.4;
      color: #fc921f;
      padding: 3px 8px;
      margin-top: 9px; }
  .widget-profile-menu {
    padding: 10px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .widget-profile-menu .menu {
      margin-bottom: 0; }
      .widget-profile-menu .menu li {
        /*&:not(:last-child) {
            margin-bottom: 10px;
          }*/ }
        .widget-profile-menu .menu li.disabled .item {
          color: rgba(50, 60, 93, 0.3);
          cursor: not-allowed; }
          .widget-profile-menu .menu li.disabled .item .icon .cls-2 {
            fill: rgba(50, 60, 93, 0.3); }
          .widget-profile-menu .menu li.disabled .item .arrow {
            opacity: 0; }
        .widget-profile-menu .menu li.is-hide {
          display: none; }
      .widget-profile-menu .menu .item {
        position: relative;
        background-color: #ffffff;
        display: block;
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d;
        cursor: pointer;
        padding: 6px 36px 6px 41px; }
        .widget-profile-menu .menu .item:hover, .widget-profile-menu .menu .item:focus, .widget-profile-menu .menu .item:active {
          text-decoration: none; }
        .widget-profile-menu .menu .item:hover {
          color: #4174d6; }
          .widget-profile-menu .menu .item:hover .icon .cls-2 {
            fill: #4174d6; }
          .widget-profile-menu .menu .item:hover .arrow {
            opacity: 1; }
            .widget-profile-menu .menu .item:hover .arrow .cls-2 {
              fill: #4174d6; }
        .widget-profile-menu .menu .item .icon {
          position: absolute;
          left: 20px;
          top: 8px;
          width: 12px;
          height: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: 100% 100%;
          font-size: 0;
          line-height: 1; }
          .widget-profile-menu .menu .item .icon .svg {
            width: 100%;
            height: 100%; }
          .widget-profile-menu .menu .item .icon .cls-1 {
            fill: none; }
          .widget-profile-menu .menu .item .icon .cls-2 {
            fill: rgba(50, 60, 93, 0.5); }
          .widget-profile-menu .menu .item .icon .img-icon {
            display: inline-block;
            vertical-align: middle;
            background-image: url("/static/img/sprite-header.svg"); }
            .widget-profile-menu .menu .item .icon .img-icon.lk {
              width: 12px;
              height: 12px;
              background-position: 0 -22px; }
            .widget-profile-menu .menu .item .icon .img-icon.out {
              width: 12px;
              height: 12px;
              background-position: -22px -22px; }
          .widget-profile-menu .menu .item .icon.t-calendar {
            background-image: url("/static/img/lk/filter/ic_calendar_12.svg"); }
          .widget-profile-menu .menu .item .icon.t-phone {
            background-image: url("/static/img/lk/filter/ic_call_12.svg"); }
          .widget-profile-menu .menu .item .icon.t-number {
            background-image: url("/static/img/lk/filter/ic_cardstack_12.svg"); }
          .widget-profile-menu .menu .item .icon.t-status {
            background-image: url("/static/img/lk/filter/ic_seen_12.svg"); }
          .widget-profile-menu .menu .item .icon.t-lk {
            background-image: url("/static/img/lk/filter/ic_seen_12.svg"); }
        .widget-profile-menu .menu .item .arrow {
          opacity: 0;
          position: absolute;
          right: 20px;
          top: 9px;
          width: 6px;
          height: 12px;
          background-image: url("/static/img/sprite-header.svg");
          background-position: -47px -22px;
          font-size: 0;
          line-height: 1; }
          .widget-profile-menu .menu .item .arrow .svg {
            width: 100%;
            height: 100%; }
          .widget-profile-menu .menu .item .arrow .cls-1 {
            fill: none; }
          .widget-profile-menu .menu .item .arrow .cls-2 {
            fill: rgba(50, 60, 93, 0.3); }
  .widget-profile-company {
    background-color: #ffffff;
    position: relative;
    display: block;
    border-top: 1px solid #dfe6f4;
    padding: 14px 36px 13px 54px; }
    .widget-profile-company:hover, .widget-profile-company:focus, .widget-profile-company:active {
      text-decoration: none; }
    .widget-profile-company:hover {
      background-color: #fbfcfd; }
      .widget-profile-company:hover .arrow .cls-2 {
        fill: #4174d6; }
    .widget-profile-company.disabled {
      background-color: #ffffff;
      cursor: not-allowed; }
      .widget-profile-company.disabled .name {
        color: rgba(50, 60, 93, 0.3); }
      .widget-profile-company.disabled .type {
        color: rgba(50, 60, 93, 0.3); }
      .widget-profile-company.disabled .arrow {
        opacity: 0; }
    .widget-profile-company .icon {
      position: absolute;
      left: 20px;
      top: 50%;
      width: 24px;
      height: 24px;
      background-color: #dfe6f4;
      border-radius: 50%;
      margin-top: -11px; }
      .widget-profile-company .icon img {
        width: 100%;
        height: 100%;
        vertical-align: top; }
    .widget-profile-company .arrow {
      position: absolute;
      right: 20px;
      top: 50%;
      width: 12px;
      height: 12px;
      font-size: 0;
      line-height: 1;
      margin-top: -5px; }
      .widget-profile-company .arrow .svg {
        width: 100%;
        height: 100%; }
      .widget-profile-company .arrow .cls-1 {
        fill: none; }
      .widget-profile-company .arrow .cls-2 {
        fill: rgba(50, 60, 93, 0.3); }
    .widget-profile-company .cnt > * {
      display: block; }
    .widget-profile-company .name {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 13px;
      line-height: 18px;
      color: #323c5d; }
    .widget-profile-company .type {
      font-size: 11px;
      line-height: 15px;
      color: rgba(50, 60, 93, 0.5); }
  .widget-profile-add {
    background-color: #ffffff;
    position: relative;
    display: block;
    border-top: 1px solid #dfe6f4;
    font-size: 15px;
    line-height: 20px;
    color: rgba(50, 60, 93, 0.5);
    padding: 15px 20px 15px 54px; }
    .widget-profile-add:hover, .widget-profile-add:focus, .widget-profile-add:active {
      text-decoration: none; }
    .widget-profile-add:hover {
      background-color: #fbfcfd;
      color: #4174d6; }
      .widget-profile-add:hover .icon .cls-1 {
        fill: rgba(65, 116, 214, 0.1); }
      .widget-profile-add:hover .icon .cls-2 {
        fill: #4174d6; }
    .widget-profile-add.disabled {
      background-color: #ffffff;
      color: rgba(50, 60, 93, 0.3);
      cursor: not-allowed; }
      .widget-profile-add.disabled .icon .cls-1 {
        fill: rgba(50, 60, 93, 0.1); }
      .widget-profile-add.disabled .icon .cls-2 {
        fill: rgba(50, 60, 93, 0.3); }
    .widget-profile-add .icon {
      position: absolute;
      left: 20px;
      top: 50%;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-top: -11px; }
      .widget-profile-add .icon .svg {
        width: 100%;
        height: 100%; }
      .widget-profile-add .icon .cls-1 {
        fill: rgba(50, 60, 93, 0.1); }
      .widget-profile-add .icon .cls-2 {
        fill: rgba(50, 60, 93, 0.5); }

.businessBox .ReactModal__Overlay {
  z-index: 11; }

.businessBox .business-form-wrapper {
  position: absolute;
  left: -16px;
  top: -28px;
  right: -16px;
  bottom: -1px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #dfe6f4; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f6f8fb; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #dfe6f4; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #dfe6f4; }

.react-datepicker-wrapper {
  display: inline-block; }

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #323c5d;
  border: 1px solid #dfe6f4;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  z-index: 999;
  top: 19px;
  left: 185px;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.16);
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px; }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px; }
  .react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px; }
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px; }
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f6f8fb;
  border-bottom: 1px solid #dfe6f4;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative; }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #323c5d;
  font-weight: bold;
  font-size: 0.944rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc; }
    .react-datepicker__navigation--previous:hover {
      border-right-color: #b3b3b3; }
    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc; }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 80px; }
    .react-datepicker__navigation--next:hover {
      border-left-color: #b3b3b3; }
    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: #ccc; }
      .react-datepicker__navigation--years-previous:hover {
        border-top-color: #b3b3b3; }
    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: #ccc; }
      .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left;
  border-bottom: 1px solid #dfe6f4;
  margin-bottom: 45px; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #dfe6f4;
  border-bottom: 1px solid #dfe6f4;
  width: 70px;
  margin-bottom: 45px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 70px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (1.7rem / 2));
        overflow-y: scroll;
        padding-right: 0px;
        padding-left: 0px;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: #f6f8fb; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: #4174d6;
            color: white;
            font-weight: bold; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: #4174d6; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            color: #ccc; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 0.3rem;
      background-color: #f6f8fb; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #323c5d;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__day {
  cursor: pointer; }
  .react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: #f6f8fb; }
  .react-datepicker__day--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff; }
    .react-datepicker__day--highlighted:hover {
      background-color: #32be3f; }
    .react-datepicker__day--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #4174d6;
    color: #fff; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
      background-color: #2d65d1; }
  .react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #6b93df;
    color: #fff; }
    .react-datepicker__day--keyboard-selected:hover {
      background-color: #2d65d1; }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(65, 116, 214, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #f6f8fb;
    color: #323c5d; }
  .react-datepicker__day--disabled {
    cursor: default;
    color: #ccc; }
    .react-datepicker__day--disabled:hover {
      background-color: transparent; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f6f8fb;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #dfe6f4; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px; }
  .react-datepicker__close-icon::after {
    background-color: #4174d6;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center; }

.react-datepicker__today-button {
  background: #f6f8fb;
  border-top: 1px solid #dfe6f4;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem; }
  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent; }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc; }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: #b3b3b3; }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc; }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: #b3b3b3; }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #e6e6e6;
      cursor: default; }

.quicButtons {
  margin: 12px;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0; }
  .quicButtons button {
    position: relative;
    height: 22px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #4174d6;
    padding: 3px 8px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4174d6;
    width: max-content;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer; }

.default-head {
  position: relative;
  background-color: #dfe6f4;
  min-height: 270px;
  padding: 100px 0 40px;
  margin-top: -60px; }
  .default-head::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.58); }
  .default-head .container {
    position: relative; }

.default-header {
  font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 37px;
  line-height: 1.38;
  color: #ffffff; }

.default-cnt {
  padding: 70px 0 70px; }
  .default-cnt .max-width {
    max-width: 768px;
    margin: 0 auto; }
  .default-cnt h2 {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 30px;
    line-height: 1.37;
    color: #323c5d;
    margin-bottom: 50px; }
  .default-cnt h3 {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    color: #323c5d;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 40px; }
  .default-cnt h4 {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 17px;
    color: #323c5d;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 40px; }
  .default-cnt p {
    margin-bottom: 20px; }
  .default-cnt p:not(:first-of-type), .default-cnt b {
    font-size: 14px;
    line-height: 1.35;
    color: #323c5d; }
  .default-cnt a {
    display: inline-block;
    font-size: 14px;
    line-height: 1.35;
    margin-bottom: 20px; }
  .default-cnt p:first-of-type {
    border-left: 5px solid #323c5d;
    font-size: 14px;
    line-height: 1.4;
    color: #323c5d;
    padding-left: 35px; }
  .default-cnt ul {
    list-style: none;
    margin-bottom: 20px;
    padding-left: 15px; }
    .default-cnt ul li {
      font-size: 14px;
      line-height: 1.35;
      color: #323c5d; }
      .default-cnt ul li:not(:last-child) {
        margin-bottom: 20px; }

.default-more-btn {
  margin-top: 35px; }
  .default-more-btn .btn {
    background-color: transparent;
    border-color: #ffffff;
    border-radius: 10px;
    color: #ffffff; }
    .default-more-btn .btn:not(:last-child) {
      margin-right: 10px; }
    .default-more-btn .btn:hover {
      background-color: #4174d6;
      border-color: #4174d6;
      color: #ffffff; }
    .default-more-btn .btn .icon {
      display: inline-block;
      vertical-align: top;
      width: 24px;
      height: 24px;
      background-image: url("/static/img/download.svg");
      background-size: 100% 100%;
      margin-right: 11px; }

.carpark-filter {
  background-color: #F4F6F8;
  height: 227px; }
  .carpark-filter .title {
    color: #323C5D;
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 37px;
    font-weight: 700;
    padding-top: 30px; }

.carpark-order {
  margin-top: 24px;
  color: #323C5D;
  font-size: 13px; }
  .carpark-order .all-order-types {
    margin-bottom: 20px; }
    .carpark-order .all-order-types .order-type {
      display: inline-block;
      margin-right: 20px; }
      .carpark-order .all-order-types .order-type .title {
        color: #323C5D;
        font-size: 13px; }
      .carpark-order .all-order-types .order-type .name {
        display: inline-block;
        color: #9B9FB0; }
        .carpark-order .all-order-types .order-type .name.selected {
          color: #323C5D; }
      .carpark-order .all-order-types .order-type .value {
        display: inline-block;
        margin-left: 10px;
        background-color: #D9E3F7;
        color: #4174D6;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        height: 20px; }

.carpark .car-item .container {
  border-bottom: 1px solid #D6D8DE; }

.carpark .car-item .car-item-row {
  position: relative; }
  .carpark .car-item .car-item-row .car-icon {
    width: 58px;
    height: 37px;
    display: inline-block;
    margin-right: 10px;
    position: absolute;
    top: 34px;
    background: url("/static/img/sprite-cartype.svg");
    background-size: 542px auto; }
    .carpark .car-item .car-item-row .car-icon.unib {
      background-position-y: -58px;
      background-position-x: -179px; }
    .carpark .car-item .car-item-row .car-icon.commerc {
      background-position-y: -107px;
      background-position-x: -241px; }
    .carpark .car-item .car-item-row .car-icon.furgon {
      background-position-y: -58px;
      background-position-x: -59px; }
    .carpark .car-item .car-item-row .car-icon.porter {
      background-position-y: -58px;
      background-position-x: -122px; }
    .carpark .car-item .car-item-row .car-icon.vnunib {
      background-position-y: -8px;
      background-position-x: -238px; }
    .carpark .car-item .car-item-row .car-icon.vnbus {
      background-position-y: -107px;
      background-position-x: -59px; }
    .carpark .car-item .car-item-row .car-icon.gnext {
      background-position-y: -8px;
      background-position-x: -238px; }
    .carpark .car-item .car-item-row .car-icon.tent {
      background-position-y: -107px;
      background-position-x: -121px; }
    .carpark .car-item .car-item-row .car-icon.box {
      background-position-y: -107px;
      background-position-x: -121px; }
  .carpark .car-item .car-item-row .car-name {
    color: #323C5D;
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 33px;
    font-weight: 700;
    padding-top: 30px;
    display: inline-block;
    margin-left: 58px; }
  .carpark .car-item .car-item-row .car-type {
    color: #989DAE;
    font-family: "Open Sans Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 33px;
    padding-top: 30px;
    display: inline-block;
    margin-left: 15px; }
  .carpark .car-item .car-item-row .car-tariff {
    display: inline-block;
    margin-left: 35px;
    padding-left: 38px;
    position: relative;
    top: -4px; }
    .carpark .car-item .car-item-row .car-tariff-icon {
      width: 30px;
      height: 30px;
      display: inline-block;
      cursor: pointer;
      background-image: url("/static/img/carItem-rouble-icon.svg");
      background-repeat: no-repeat;
      background-position-y: center;
      position: absolute;
      top: -3px;
      left: 0; }
    .carpark .car-item .car-item-row .car-tariff-title {
      color: #6F768D;
      font-size: 15px;
      font-weight: 470;
      display: inline-block; }
    .carpark .car-item .car-item-row .car-tariff-name {
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      color: #4174D6;
      font-size: 15px;
      display: inline-block;
      margin-left: 8px; }
  .carpark .car-item .car-item-row .car-take-order {
    background-color: rgba(208, 216, 230, 0.3);
    border-radius: 10px;
    height: 40px;
    width: 157px;
    float: right;
    margin-top: 38px;
    outline: none;
    border: none;
    cursor: pointer;
    color: rgba(50, 60, 93, 0.7);
    font-size: 13px;
    text-align: center;
    text-decoration: none;
    line-height: 40px; }
    .carpark .car-item .car-item-row .car-take-order:hover {
      color: #FFFFFF;
      background-color: #FC921F;
      -webkit-box-shadow: 0 5px 20px 0 rgba(252, 146, 31, 0.5);
      -moz-box-shadow: 0 5px 20px 0 rgba(252, 146, 31, 0.5);
      box-shadow: 0 5px 20px 0 rgba(252, 146, 31, 0.5); }

.carpark .car-item .car-item-row-params {
  position: relative; }
  .carpark .car-item .car-item-row-params-image {
    width: 376px;
    height: 160px;
    margin-top: 47px;
    display: inline-block;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: cover; }
  .carpark .car-item .car-item-row-params-box-size {
    width: 149px;
    height: 142px;
    margin-top: 47px;
    margin-left: 16px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    background-image: url("/static/img/sprite-autopark.svg");
    background-position: -364px -2px;
    position: relative; }
    .carpark .car-item .car-item-row-params-box-size .value {
      color: #323C5D;
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      font-weight: 700;
      display: inline-block;
      position: absolute;
      right: 60px;
      top: -10px; }
      .carpark .car-item .car-item-row-params-box-size .value .unit {
        font-size: 13px;
        font-weight: 470; }
  .carpark .car-item .car-item-row-params-values {
    margin-left: 61px;
    display: inline-block;
    width: 190px;
    position: absolute;
    top: 30px; }
    .carpark .car-item .car-item-row-params-values .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 17px;
      font-weight: 600;
      color: #323C5D;
      margin-bottom: 25px; }
    .carpark .car-item .car-item-row-params-values .car-info {
      position: relative;
      margin-bottom: 15px;
      height: 13px; }
      .carpark .car-item .car-item-row-params-values .car-info-icon {
        display: inline-block;
        position: absolute;
        left: 0;
        background-image: url("/static/img/sprite-autopark.svg"); }
        .carpark .car-item .car-item-row-params-values .car-info-icon.height {
          top: 3px;
          width: 12px;
          height: 12px;
          background-position: -348px -204px; }
        .carpark .car-item .car-item-row-params-values .car-info-icon.long {
          top: 5px;
          width: 12px;
          height: 8px;
          background-position: -392px -206px; }
        .carpark .car-item .car-item-row-params-values .car-info-icon.width {
          top: 3px;
          width: 13px;
          height: 12px;
          background-position: -369px -204px; }
        .carpark .car-item .car-item-row-params-values .car-info-icon.volume {
          top: 3px;
          width: 12px;
          height: 12px;
          background-position: -414px -204px; }
        .carpark .car-item .car-item-row-params-values .car-info-icon.weight {
          top: 3px;
          width: 12px;
          height: 12px;
          background-position: -436px -204px; }
      .carpark .car-item .car-item-row-params-values .car-info-title {
        color: #515975;
        margin-left: 22px;
        float: left;
        font-size: 13px; }
      .carpark .car-item .car-item-row-params-values .car-info-value {
        color: #323C5D;
        float: right;
        font-size: 13px; }
      .carpark .car-item .car-item-row-params-values .car-info-valueM3 {
        color: #323C5D;
        float: right;
        font-size: 13px;
        right: -4px;
        position: relative; }
  .carpark .car-item .car-item-row-params-desc {
    float: right;
    display: inline-block;
    width: 240px;
    margin-top: 30px; }
    .carpark .car-item .car-item-row-params-desc .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 17px;
      font-weight: 600;
      color: #323C5D;
      margin-bottom: 25px; }
    .carpark .car-item .car-item-row-params-desc .desc {
      font-size: 13px;
      color: #515975;
      margin-bottom: 25px; }
    .carpark .car-item .car-item-row-params-desc .bonus {
      display: inline-block;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 44px; }
      .carpark .car-item .car-item-row-params-desc .bonus .logo {
        display: inline-block;
        margin-right: 7px;
        position: relative;
        margin-left: 25px; }
        .carpark .car-item .car-item-row-params-desc .bonus .logo:before {
          background: url("/static/img/sprite-cartype.svg");
          background-size: 515px auto;
          height: 24px;
          width: 24px;
          content: '';
          display: inline-block;
          position: absolute;
          left: -29px; }
        .carpark .car-item .car-item-row-params-desc .bonus .logo.green {
          color: #63b463; }
          .carpark .car-item .car-item-row-params-desc .bonus .logo.green:before {
            background-position-y: -167px;
            background-position-x: -341px; }
        .carpark .car-item .car-item-row-params-desc .bonus .logo.orange {
          color: #FC921F; }
          .carpark .car-item .car-item-row-params-desc .bonus .logo.orange:before {
            background-position-y: -167px;
            background-position-x: -375px; }

.carpark .car-item:hover {
  background-color: #FAFBFC; }

.carpark-information {
  background-color: #FBFCFD;
  padding-bottom: 100px; }
  .carpark-information .container > .title {
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 37px;
    font-weight: 700;
    color: #323C5D;
    padding-top: 100px;
    padding-bottom: 20px; }
  .carpark-information .info-item {
    padding-top: 60px; }
    .carpark-information .info-item .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      font-weight: 600;
      color: #323C5D;
      min-height: 60px;
      margin-bottom: 10px; }
    .carpark-information .info-item .info {
      font-size: 15px;
      color: #515975; }
    .carpark-information .info-item .link {
      font-size: 13px;
      color: #4174D6;
      padding-top: 24px;
      cursor: pointer; }

.order {
  position: relative;
  padding-bottom: 150px; }
  .order .breadcrumbs {
    height: 60px;
    width: 100%;
    background-color: #FFFFFF;
    color: #152352;
    border-bottom: 1px solid rgba(50, 60, 93, 0.1);
    z-index: 2;
    position: absolute;
    top: -60px; }
    .order .breadcrumbs .container {
      padding: 0;
      position: relative; }
      .order .breadcrumbs .container .btn-back {
        height: 60px;
        display: inline-block;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        color: #6F768D;
        font-size: 13px;
        padding-left: 52px;
        padding-top: 20px;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 600;
        text-decoration: none; }
        .order .breadcrumbs .container .btn-back:before {
          background-image: url("/static/img/btn-back.svg");
          background-repeat: no-repeat;
          background-position-y: center;
          height: 32px;
          width: 32px;
          content: "";
          display: inline-block;
          border-radius: 16px;
          margin-right: 10px;
          position: absolute;
          left: 10px;
          top: 13px; }
        .order .breadcrumbs .container .btn-back:hover:before {
          box-shadow: 0 2px 10px 0 rgba(65, 116, 214, 0.3); }
      .order .breadcrumbs .container .breadcrumbs-items {
        margin: 0 auto;
        width: 730px; }
        .order .breadcrumbs .container .breadcrumbs-items .item {
          display: inline-block;
          padding: 22px 65px;
          cursor: pointer;
          color: rgba(21, 35, 82, 0.49);
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal; }
          .order .breadcrumbs .container .breadcrumbs-items .item.selected {
            color: #63b463;
            border-bottom: 2px solid #63b463;
            padding-bottom: 20px; }
            .order .breadcrumbs .container .breadcrumbs-items .item.selected:hover {
              color: #63b463; }
          .order .breadcrumbs .container .breadcrumbs-items .item:hover {
            color: #152352; }
      .order .breadcrumbs .container .btn-close {
        width: 32px;
        height: 32px;
        display: inline-block;
        cursor: pointer;
        background-image: url("/static/img/btn-close.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        position: absolute;
        top: 13px;
        right: 20px; }
  .order .wrapper-calc h1 {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #323c5d;
    padding-top: 50px; }
  .order .wrapper-calc .content-left {
    float: left; }
    .order .wrapper-calc .content-left .start-point, .order .wrapper-calc .content-left .end-point {
      margin-top: 50px;
      position: relative; }
      .order .wrapper-calc .content-left .start-point-icon, .order .wrapper-calc .content-left .end-point-icon {
        height: 24px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position-y: center;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #323c5d;
        padding-left: 44px;
        position: absolute;
        top: 48px;
        cursor: default;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
      .order .wrapper-calc .content-left .start-point .start-point-icon, .order .wrapper-calc .content-left .end-point .start-point-icon {
        background-image: url("/static/img/icon-point-start.svg"); }
      .order .wrapper-calc .content-left .start-point .end-point-icon, .order .wrapper-calc .content-left .end-point .end-point-icon {
        background-image: url("/static/img/icon-point-end.svg"); }
      .order .wrapper-calc .content-left .start-point-wrap, .order .wrapper-calc .content-left .end-point-wrap {
        display: inline-block;
        margin-left: 196px; }
        .order .wrapper-calc .content-left .start-point-wrap label, .order .wrapper-calc .content-left .end-point-wrap label {
          opacity: 0.7;
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 15px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #323c5d;
          margin-bottom: 15px;
          cursor: default;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none; }
        .order .wrapper-calc .content-left .start-point-wrap .tags, .order .wrapper-calc .content-left .end-point-wrap .tags {
          margin-top: 18px;
          padding-left: 15px; }
          .order .wrapper-calc .content-left .start-point-wrap .tags .tag, .order .wrapper-calc .content-left .end-point-wrap .tags .tag {
            height: 20px;
            border-radius: 4px;
            background-color: #ffffff;
            border: solid 1px #4174d6;
            padding: 3px 8px;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 10px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4174d6;
            width: max-content;
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none; }
  .order .wrapper-calc .content-right {
    float: right; }

.page-wrap {
  background-color: #F4F6F8; }
  .page-wrap .page.PageApp {
    background: -webkit-linear-gradient(60deg, #F4F6F8 50%, #EFF1F4 50%);
    background: -moz-linear-gradient(60deg, #F4F6F8 50%, #EFF1F4 50%);
    background: -o-linear-gradient(60deg, #F4F6F8 50%, #EFF1F4 50%);
    background: linear-gradient(30deg, #F4F6F8 50%, #EFF1F4 50%); }
  .page-wrap .page .s-title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 37px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #323c5d; }
  .page-wrap .page .section-1 {
    position: relative;
    min-height: 760px;
    padding-top: 120px; }
    .page-wrap .page .section-1.iphone::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 771px;
      height: 710px;
      background-image: url("/static/img/sprite-apps.png");
      background-position: -2px -83px; }
    .page-wrap .page .section-1 .container {
      padding: 0; }
      .page-wrap .page .section-1 .container .desc {
        opacity: 0.7;
        font-size: 23px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #323c5d;
        margin-top: 42px; }
      .page-wrap .page .section-1 .container .links {
        color: #4174d6;
        margin-top: 40px; }
        .page-wrap .page .section-1 .container .links .link-info {
          font-size: 20px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          display: inline-block;
          white-space: pre-wrap;
          cursor: pointer; }
      .page-wrap .page .section-1 .container .download-app-wrap {
        margin-top: 60px; }
  .page-wrap .page .section-2 {
    margin-top: 70px; }
    .page-wrap .page .section-2 .container {
      padding: 0; }
    .page-wrap .page .section-2 .what-doing {
      margin: 80px 0 0 0;
      padding: 0;
      position: relative; }
      .page-wrap .page .section-2 .what-doing .circle {
        margin: 0;
        padding: 0;
        border-top: 1px solid #fc921f; }
        .page-wrap .page .section-2 .what-doing .circle:last-child {
          border: none; }
        .page-wrap .page .section-2 .what-doing .circle::before {
          content: '';
          display: block;
          width: 40px;
          height: 40px;
          background-color: #fc921f;
          position: absolute;
          top: -20px;
          border-radius: 20px; }
        .page-wrap .page .section-2 .what-doing .circle .num {
          position: absolute;
          color: #FFFFFF;
          z-index: 2;
          font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 15px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          top: -10px;
          left: 15px; }
        .page-wrap .page .section-2 .what-doing .circle .title {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 20px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #323c5d;
          margin-top: 50px; }
        .page-wrap .page .section-2 .what-doing .circle .info {
          width: 278px;
          margin-top: 15px;
          opacity: 0.85;
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #323c5d; }
          .page-wrap .page .section-2 .what-doing .circle .info a {
            color: #4174D6; }
  .page-wrap .page .section-3 {
    margin-top: 10px; }
    .page-wrap .page .section-3 .slider {
      height: 900px; }
      .page-wrap .page .section-3 .slider .row:not(.input-block) {
        margin: 0; }
      .page-wrap .page .section-3 .slider .row .col-sm {
        padding: 0; }
      .page-wrap .page .section-3 .slider .content {
        position: relative;
        padding-top: 156px; }
        .page-wrap .page .section-3 .slider .content .icon {
          display: inline-block;
          position: absolute;
          top: 156px;
          left: 0; }
          .page-wrap .page .section-3 .slider .content .icon img {
            vertical-align: top; }
        .page-wrap .page .section-3 .slider .content .img-icon {
          width: 54px;
          height: 54px;
          background-image: url("/static/img/sprite_apps.svg"); }
          .page-wrap .page .section-3 .slider .content .img-icon.badge-1 {
            background-position: -198px -52px; }
          .page-wrap .page .section-3 .slider .content .img-icon.badge-2 {
            background-position: -262px -52px; }
          .page-wrap .page .section-3 .slider .content .img-icon.badge-3 {
            background-position: -326px -52px; }
          .page-wrap .page .section-3 .slider .content .img-icon.badge-4 {
            background-position: -390px -52px; }
        .page-wrap .page .section-3 .slider .content .title {
          margin-top: 74px;
          font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 30px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #323c5d; }
        .page-wrap .page .section-3 .slider .content .desc {
          margin-top: 15px;
          opacity: 0.6;
          font-size: 17px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #323c5d; }
        .page-wrap .page .section-3 .slider .content .bages {
          margin-top: 41px; }
          .page-wrap .page .section-3 .slider .content .bages .bage {
            position: relative;
            padding-left: 34px;
            font-family: "Open Sans Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 15px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4174d6;
            min-height: 24px;
            margin-bottom: 15px; }
            .page-wrap .page .section-3 .slider .content .bages .bage::before {
              content: '';
              width: 24px;
              height: 24px;
              position: absolute;
              left: 0;
              top: 0;
              background-image: url("/static/img/icon-bonus.svg");
              background-repeat: no-repeat;
              background-position-y: center; }
      .page-wrap .page .section-3 .slider .mobile .iphone {
        width: 341px;
        height: 684px;
        background-image: url("/static/img/sprite-apps.png");
        margin: 0 auto; }
        .page-wrap .page .section-3 .slider .mobile .iphone.slide-0 {
          background-position: 0 -839px; }
        .page-wrap .page .section-3 .slider .mobile .iphone.slide-1 {
          background-position: -379px -839px; }
        .page-wrap .page .section-3 .slider .mobile .iphone.slide-2 {
          background-position: -759px -839px; }
        .page-wrap .page .section-3 .slider .mobile .iphone.slide-3 {
          background-position: -1139px -839px; }
      .page-wrap .page .section-3 .slider .box-modal {
        padding: 32px 36px 39px 42px;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.08);
        margin-top: 125px; }
        .page-wrap .page .section-3 .slider .box-modal .content {
          padding-top: 0; }
          .page-wrap .page .section-3 .slider .box-modal .content .icon {
            top: -70px; }
          .page-wrap .page .section-3 .slider .box-modal .content .input-block {
            margin-top: 40px; }
          .page-wrap .page .section-3 .slider .box-modal .content .input-wrap {
            border: solid 1px #dfe6f4;
            height: 36px;
            border-radius: 5px;
            background-color: #ffffff;
            display: inline-block;
            width: 340px; }
          .page-wrap .page .section-3 .slider .box-modal .content .download-app-wrap {
            margin-top: 20px; }
  .page-wrap .page .section-4 {
    height: 420px;
    background-image: url("/static/img/section-4.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -60px;
    position: relative; }
    .page-wrap .page .section-4 .title {
      padding-top: 70px;
      color: #FFFFFF;
      font-weight: bold; }
    .page-wrap .page .section-4 .desc {
      font-size: 23px;
      color: #FFFFFF;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-top: 20px; }
    .page-wrap .page .section-4 .input-wrap {
      border: solid 1px #dfe6f4;
      height: 36px;
      border-radius: 5px;
      background-color: #ffffff;
      display: inline-block;
      width: 389px;
      margin-top: 36px; }
      .page-wrap .page .section-4 .input-wrap input {
        background-color: transparent;
        border: 0;
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #323c5d;
        padding-right: 30px;
        margin: 7px 15px; }
        .page-wrap .page .section-4 .input-wrap input:focus {
          outline: none; }
    .page-wrap .page .section-4 button {
      height: 36px;
      border-radius: 6px;
      background-color: #4174d6;
      color: #FFFFFF;
      padding: 6px 25px;
      margin-left: 20px;
      float: right;
      border: 0;
      margin-top: 36px;
      width: 130px; }
    .page-wrap .page .section-4 .tip {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fc921f; }
  .page-wrap .page .section-5 {
    background-color: #000000;
    border-bottom: 1px solid #262626; }
    .page-wrap .page .section-5 .content {
      margin-top: 100px; }
      .page-wrap .page .section-5 .content .s-title {
        padding-top: 5px;
        color: #FFFFFF;
        font-weight: 500; }
      .page-wrap .page .section-5 .content .desc {
        opacity: 0.7;
        font-size: 23px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-top: 30px; }
      .page-wrap .page .section-5 .content .download-app-wrap {
        margin-top: 40px; }
      .page-wrap .page .section-5 .content .items {
        margin-top: 46px; }
        .page-wrap .page .section-5 .content .items .item {
          margin-bottom: 34px;
          padding-left: 15px; }
          .page-wrap .page .section-5 .content .items .item .bage {
            position: relative;
            height: 54px; }
            .page-wrap .page .section-5 .content .items .item .bage::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 54px;
              height: 54px;
              background-image: url("/static/img/sprite_apps.svg"); }
            .page-wrap .page .section-5 .content .items .item .bage.t-1::before {
              background-position: -6px -52px; }
            .page-wrap .page .section-5 .content .items .item .bage.t-2::before {
              background-position: -70px -52px; }
            .page-wrap .page .section-5 .content .items .item .bage.t-3::before {
              background-position: -134px -52px; }
          .page-wrap .page .section-5 .content .items .item .title {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 15px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            padding: 0; }
          .page-wrap .page .section-5 .content .items .item .desc {
            opacity: 0.7;
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            margin-top: 5px; }
    .page-wrap .page .section-5 .mobile {
      padding: 70px 0; }
      .page-wrap .page .section-5 .mobile .iphone {
        width: 341px;
        height: 684px;
        background-image: url("/static/img/sprite-apps.png");
        background-position: -1519px -839px;
        margin: 0 auto; }
    .page-wrap .page .section-5 .big-bages .bage {
      position: relative;
      width: 80px;
      height: 96px; }
      .page-wrap .page .section-5 .big-bages .bage::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url("/static/img/sprite_apps.svg"); }
      .page-wrap .page .section-5 .big-bages .bage.flash::before {
        width: 80px;
        height: 70px;
        background-position: -5px -142px; }
      .page-wrap .page .section-5 .big-bages .bage.shield::before {
        width: 72px;
        height: 96px;
        background-position: -112px -116px; }
      .page-wrap .page .section-5 .big-bages .bage.simple::before {
        width: 78px;
        height: 88px;
        background-position: -212px -124px; }
    .page-wrap .page .section-5 .big-bages .title {
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-top: 15px; }
    .page-wrap .page .section-5 .big-bages .desc {
      margin-top: 5px;
      opacity: 0.7;
      font-size: 15px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
    .page-wrap .page .section-5 .map {
      width: 667px;
      height: 384px;
      margin: 65px auto 40px;
      background-image: url("/static/img/sprite-apps.png");
      background-position: 0 -1563px; }
  .page-wrap .page .section-6 {
    padding-top: 150px;
    height: 300px; }

.page {
  padding-top: 60px; }

.s-index-start {
  position: relative;
  display: flex;
  align-items: center;
  min-height: calc(100vh - 40px);
  color: #ffffff;
  padding: 76px 0 116px; }
  .s-index-start .bg-2 {
    position: absolute;
    left: -5%;
    top: -100%;
    right: -35%;
    bottom: 35%;
    background: -webkit-linear-gradient(-90deg, rgba(255, 223, 162, 0.36), rgba(245, 249, 255, 0.1));
    background: -moz-linear-gradient(-90deg, rgba(255, 223, 162, 0.36), rgba(245, 249, 255, 0.1));
    background: -o-linear-gradient(-90deg, rgba(255, 223, 162, 0.36), rgba(245, 249, 255, 0.1));
    background: linear-gradient(-90deg, rgba(255, 223, 162, 0.36), rgba(245, 249, 255, 0.1));
    transform: rotate(27deg); }
  .s-index-start::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fffff; }
  .s-index-start video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto; }
  .s-index-start .container-fluid {
    position: relative;
    flex: 1; }
  .s-index-start .widget:not(:first-child) {
    margin-top: 10px; }
  .s-index-start .column-item {
    padding-left: 15px;
    padding-right: 15px; }
  .s-index-start .index-start__title {
    max-width: 670px;
    line-height: 1.36;
    color: rgba(50, 60, 93, 0.85);
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0 0 10px; }
  .s-index-start .index-start__subtitle {
    max-width: 474px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 23px;
    line-height: 1.3; }
  .s-index-start .index-start__desc {
    margin: 10px 0 0;
    opacity: 0.6;
    font-size: 20px;
    color: rgba(50, 60, 93, 0.85);
    line-height: 1.35;
    white-space: pre; }
  .s-index-start .index-start__desc2 {
    max-width: 571px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 25px;
    line-height: 1.36;
    color: rgba(50, 60, 93, 0.85);
    margin: 40px 0 0; }
    .s-index-start .index-start__desc2 span {
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      border-bottom: 1px solid currentColor; }
  .s-index-start .index-start-type {
    max-width: 510px;
    margin-top: 50px; }
    .s-index-start .index-start-type .column-type {
      padding-left: 15px;
      padding-right: 15px;
      width: 50%; }
      .s-index-start .index-start-type .column-type .item {
        font-size: 15px;
        line-height: 1.33;
        color: rgba(50, 60, 93, 0.85);
        height: 40px;
        padding-left: 38px; }
        .s-index-start .index-start-type .column-type .item .phone {
          border-bottom: 1px dashed black;
          white-space: nowrap;
          font-weight: bold; }
        .s-index-start .index-start-type .column-type .item .icon {
          display: inline-block;
          width: 28px;
          height: 28px;
          margin-left: -38px;
          position: absolute;
          margin-top: -4px;
          background-size: 100% 100%; }
          .s-index-start .index-start-type .column-type .item .icon.ikea {
            background-image: url("/static/img/icon-ikea.png"); }
          .s-index-start .index-start-type .column-type .item .icon.taxi {
            background-image: url("/static/img/icon-taxi.png"); }
          .s-index-start .index-start-type .column-type .item .icon.sklad {
            background-image: url("/static/img/icon-sklad.png"); }
          .s-index-start .index-start-type .column-type .item .icon.pereezd {
            background-image: url("/static/img/icon-pereezd.png"); }
          .s-index-start .index-start-type .column-type .item .icon.avito {
            background-image: url("/static/img/icon-avito.png"); }
          .s-index-start .index-start-type .column-type .item .icon.gruz {
            background-image: url("/static/img/icon-gruz.png"); }
  .s-index-start .index-start-app {
    margin: 34px 0 0; }
  .s-index-start .index-start-widget-address {
    margin-top: 10px; }
  .s-index-start .business-driver {
    width: 100%;
    background-image: linear-gradient(94deg, #be6cab, #1e2faf);
    position: fixed;
    bottom: 0;
    z-index: 999;
    padding: 15px 0; }
    .s-index-start .business-driver .inner {
      position: relative;
      display: flex;
      padding: 0 0 0 98px; }
    .s-index-start .business-driver .container {
      position: relative; }
    .s-index-start .business-driver .text {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 25px;
      line-height: 1.36;
      color: #ffffff;
      margin: 0; }
    .s-index-start .business-driver .close {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url(/static/img/input-clear-white-big.svg);
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 9px;
      opacity: 1; }
    .s-index-start .business-driver .buttons {
      flex: 1;
      text-align: right; }
      .s-index-start .business-driver .buttons a {
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        font-size: 15px;
        font-weight: 370;
        height: 40px;
        border-radius: 10px;
        border: solid 1px #ffffff;
        background-color: rgba(65, 116, 214, 0); }
        .s-index-start .business-driver .buttons a:hover {
          text-decoration: none; }
          .s-index-start .business-driver .buttons a:hover .icon {
            margin-left: 16px; }
        .s-index-start .business-driver .buttons a:active {
          background-color: rgba(65, 116, 214, 0);
          border: solid 1px #ffffff; }
        .s-index-start .business-driver .buttons a .icon {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin-left: 8px;
          width: 16px;
          height: 16px;
          background-image: url(/static/img/ic-arrow-long.svg);
          background-size: 100% 100%;
          transition: 0.25s ease-in-out; }

.index-start-app-list {
  margin: 50px 0 0; }
  .index-start-app-list li {
    float: left; }
    .index-start-app-list li:not(:last-child) {
      margin-right: 15px; }
  .index-start-app-list a, .index-start-app-list span {
    display: block;
    width: 140px;
    height: 42px;
    background-image: url("/static/img/sprite-index.svg"); }
    .index-start-app-list a.google, .index-start-app-list span.google {
      background-position: -418px -142px; }
    .index-start-app-list a.apple, .index-start-app-list span.apple {
      background-position: -418px -90px; }
    .index-start-app-list a.mover, .index-start-app-list span.mover {
      cursor: pointer;
      background-position: -418px -194px; }

.s-index {
  padding: 70px 0 70px; }
  .s-index__title {
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 37px;
    font-weight: 700;
    line-height: 1.38; }
    .s-index__title:not(.t-left):not(.t-right) {
      text-align: center; }
    .s-index__title.t-right {
      text-align: right; }
    .s-index__title:not(.t-white) {
      color: #323c5d; }

.s-index-adv .s-index__title {
  margin-bottom: 95px; }

.s-index-adv .index-adv-item .cnt {
  max-width: 303px;
  margin-top: 15px; }

.s-index-adv .index-adv-item .title {
  min-height: 54px;
  font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  color: #323c5d;
  margin-bottom: 5px; }

.s-index-adv .index-adv-item .desc {
  font-size: 15px;
  line-height: 1.33;
  color: rgba(50, 60, 93, 0.85); }

.s-index-adv .index-adv-item .img {
  min-height: 97px;
  display: flex;
  align-items: flex-end; }

.s-index-adv .index-adv-item .img-icon.time {
  width: 106px;
  height: 96px;
  background-image: url("/static/img/index-adv/img_96-Fast.svg"); }

.s-index-adv .index-adv-item .img-icon.driver {
  width: 96px;
  height: 97px;
  background-image: url("/static/img/index-adv/img_96-Driver-more.svg"); }

.s-index-adv .index-adv-item .img-icon.price {
  width: 72px;
  height: 96px;
  background-image: url("/static/img/sprite-business.svg");
  background-position: -529px -632px; }

.s-index-adv .index-adv-item .img-icon.fast {
  width: 78px;
  height: 88px;
  background-image: url("/static/img/sprite-business.svg");
  background-position: -659px -640px; }

.s-index-steps {
  position: relative; }
  .s-index-steps .s-index__title {
    margin-bottom: 35px; }
  .s-index-steps .index-steps-img {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: 0.25s ease-in-out; }
    .s-index-steps .index-steps-img img {
      height: 521px;
      width: auto; }
    .s-index-steps .index-steps-img:not(.active) {
      opacity: 0; }
    .s-index-steps .index-steps-img-wrap {
      position: relative;
      height: 804px;
      margin: 0 -87px; }
      .s-index-steps .index-steps-img-wrap .bg-abs {
        position: absolute;
        left: 2px;
        right: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%; }
        .s-index-steps .index-steps-img-wrap .bg-abs::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          background-image: url("/static/img/sprite-index.svg");
          background-position: -35px -34px;
          width: 382px;
          height: 664px;
          margin-top: -332px;
          margin-left: -192px; }
  .s-index-steps .index-steps-cnt {
    padding: 145px 0 0 85px; }
  .s-index-steps .index-steps-count-list li {
    min-width: 166px;
    max-width: 166px;
    float: left; }
    .s-index-steps .index-steps-count-list li:not(.active) {
      cursor: pointer; }
    .s-index-steps .index-steps-count-list li:not(:last-child) {
      margin-right: 30px; }
    .s-index-steps .index-steps-count-list li .count {
      font-family: "RobotoSlab Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 37px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: left;
      color: #dfe6f4; }
    .s-index-steps .index-steps-count-list li .desc {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 370;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.5);
      margin-top: 15px;
      margin-bottom: 0; }
    .s-index-steps .index-steps-count-list li.active .count,
    .s-index-steps .index-steps-count-list li.active .desc {
      color: #fc921f; }
  .s-index-steps .index-steps-count-cnt {
    max-width: 572px;
    margin-top: 70px; }
  .s-index-steps .index-steps-count-item:not(.active) {
    display: none; }
  .s-index-steps .index-steps-count-item .title {
    max-width: 464px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 23px;
    font-weight: 600;
    line-height: 1.39;
    color: #323c5d;
    margin-bottom: 20px; }
  .s-index-steps .index-steps-count-item .desc {
    font-size: 17px;
    line-height: 1.35;
    color: rgba(50, 60, 93, 0.85); }
  .s-index-steps .index-steps-count-item .type-list-wrapper {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0; }
    .s-index-steps .index-steps-count-item .type-list-wrapper [class*="col-"] {
      padding-left: 0;
      padding-right: 0; }
  .s-index-steps .index-steps-count-item .type-list {
    font-size: 0;
    margin-bottom: 0; }
    .s-index-steps .index-steps-count-item .type-list li {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      position: relative;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.33;
      color: #4174d6;
      padding-left: 32px;
      margin-bottom: 20px; }
      .s-index-steps .index-steps-count-item .type-list li .icon {
        position: absolute;
        left: 0;
        top: -3px;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background-image: url("/static/img/sprite-index.svg");
        background-position: -812px -450px; }
  .s-index-steps .index-steps-count-item .payment-list {
    font-size: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0; }
    .s-index-steps .index-steps-count-item .payment-list li {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-size: 17px;
      line-height: 1.35;
      color: #323c5d;
      padding-left: 49px;
      margin-top: 30px; }
      .s-index-steps .index-steps-count-item .payment-list li .icon {
        position: absolute;
        left: 0;
        top: -2px;
        width: 33px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center; }
      .s-index-steps .index-steps-count-item .payment-list li .img-icon {
        background-image: url("/static/img/sprite-index.svg"); }
        .s-index-steps .index-steps-count-item .payment-list li .img-icon.bank_card {
          width: 32px;
          height: 22px;
          background-position: -650px -375px; }
        .s-index-steps .index-steps-count-item .payment-list li .img-icon.bank {
          width: 32px;
          height: 25px;
          background-position: -692px -373px; }
        .s-index-steps .index-steps-count-item .payment-list li .img-icon.cash {
          width: 32px;
          height: 18px;
          background-position: -608px -377px; }
        .s-index-steps .index-steps-count-item .payment-list li .img-icon.promocode {
          width: 33px;
          height: 21px;
          background-position: -733px -376px; }
  .s-index-steps .index-steps-form {
    margin-top: 40px; }
    .s-index-steps .index-steps-form .label {
      font-size: 15px;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.85);
      margin-bottom: 10px; }
    .s-index-steps .index-steps-form .hint-info {
      max-width: 358px;
      font-size: 13px;
      font-style: italic;
      line-height: 1.38;
      color: rgba(50, 60, 93, 0.5);
      margin-bottom: 20px; }
      .s-index-steps .index-steps-form .hint-info .color {
        color: #fc921f; }
    .s-index-steps .index-steps-form .success {
      position: relative;
      background-color: #d6ead3;
      border-radius: 0.25rem;
      padding: 7px 13px 7px 50px; }
      .s-index-steps .index-steps-form .success .icon {
        position: absolute;
        left: 13px;
        top: 8px;
        width: 24px;
        height: 22px;
        background-image: url("/static/img/sprite-calc.svg");
        background-position: -170px -69px; }
  .s-index-steps .index-steps-app {
    margin-top: 30px; }

.s-index-example {
  padding: 70px 0 130px; }
  .s-index-example.t-top {
    margin-top: -70px; }
  .s-index-example .s-index__title {
    margin-bottom: 100px; }
  .s-index-example .index-example {
    position: relative; }
    .s-index-example .index-example-cnt.t-left {
      float: left; }
    .s-index-example .index-example-cnt.t-right {
      float: right; }
      .s-index-example .index-example-cnt.t-right .type-list-wrapper {
        max-width: 474px; }
    .s-index-example .index-example-cnt .img {
      margin-bottom: 20px; }
    .s-index-example .index-example-cnt .img-icon {
      background-image: url("/static/img/sprite-index.svg");
      width: 54px;
      height: 54px; }
      .s-index-example .index-example-cnt .img-icon.demand {
        background-position: -608px -260px; }
      .s-index-example .index-example-cnt .img-icon.renta {
        background-position: -672px -260px; }
      .s-index-example .index-example-cnt .img-icon.moving {
        background-position: -736px -260px; }
      .s-index-example .index-example-cnt .img-icon.delivery {
        background-position: -800px -260px; }
      .s-index-example .index-example-cnt .img-icon.qb {
        background-image: url("/static/img/qb/logo.svg"); }
    .s-index-example .index-example-cnt .title {
      max-width: 474px;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      font-weight: 600;
      line-height: 1.37;
      color: #323c5d;
      margin-bottom: 15px; }
    .s-index-example .index-example-cnt .desc {
      max-width: 474px;
      font-size: 17px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.6);
      white-space: pre-line; }
    .s-index-example .index-example-cnt .type-list {
      font-size: 0;
      margin-bottom: 0; }
      .s-index-example .index-example-cnt .type-list-wrapper {
        margin-top: 45px;
        margin-left: 0;
        margin-right: 0; }
        .s-index-example .index-example-cnt .type-list-wrapper [class*="col"] {
          padding-left: 0;
          padding-right: 0; }
      .s-index-example .index-example-cnt .type-list li {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        position: relative;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.33;
        color: #4174d6;
        padding-left: 32px;
        margin-bottom: 20px; }
        .s-index-example .index-example-cnt .type-list li .icon {
          position: absolute;
          left: 0;
          top: -3px;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          background-image: url("/static/img/sprite-index.svg");
          background-position: -812px -450px; }
    .s-index-example .index-example-cnt .more {
      margin-top: 20px; }
    .s-index-example .index-example__link {
      font-size: 15px;
      line-height: 1.33;
      color: #4174d6; }
    .s-index-example .index-example-card {
      position: relative;
      border-radius: 10px;
      background-color: #ffffff;
      border: solid 1px #dfe6f4;
      min-height: 288px;
      padding: 30px 30px 34px; }
      .s-index-example .index-example-card:first-child ~ .index-example-card {
        margin-top: -207px; }
      .s-index-example .index-example-card.t-1 {
        box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.08);
        z-index: 3;
        margin-left: 40px; }
      .s-index-example .index-example-card.t-2 {
        box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.06);
        z-index: 2;
        margin-left: 20px;
        margin-right: 20px; }
      .s-index-example .index-example-card.t-3 {
        opacity: 0.5;
        box-shadow: 1px 2px 11px 0 rgba(0, 0, 0, 0.04);
        z-index: 1;
        margin-right: 40px; }
      .s-index-example .index-example-card .title {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 23px;
        font-weight: 700;
        line-height: 1.39;
        color: #4174d6;
        margin-bottom: 10px; }
      .s-index-example .index-example-card .desc {
        max-width: 376px;
        font-size: 17px;
        line-height: 1.35;
        color: rgba(50, 60, 93, 0.6); }
      .s-index-example .index-example-card .more {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        padding: 0 30px 34px; }
    .s-index-example .index-example-img img {
      vertical-align: top; }
    .s-index-example .index-example-img .illustration {
      background-image: url("/static/img/sprite-index.svg"); }
      .s-index-example .index-example-img .illustration.illustration-1 {
        width: 532px;
        height: 357px;
        background-position: -1056px -99px; }
      .s-index-example .index-example-img .illustration.illustration-2 {
        width: 552px;
        height: 316px;
        background-position: -1618px -138px; }
      .s-index-example .index-example-img .illustration.illustration-3 {
        width: 552px;
        height: 298px;
        background-position: -1056px -523px; }
      .s-index-example .index-example-img .illustration.illustration-4 {
        width: 533px;
        height: 308px;
        background-position: -1618px -485px; }
      .s-index-example .index-example-img .illustration.illustration-qb {
        width: 552px;
        height: 368px;
        background-image: url("/static/img/qb/illustration.svg"); }
    .s-index-example .index-example [class*="col"]:nth-child(1) .index-example-img {
      margin-right: -12px; }
    .s-index-example .index-example [class*="col"]:nth-child(2) .index-example-img {
      margin-left: -12px; }
    .s-index-example .index-example-wrap {
      position: relative;
      height: 400%;
      transition: top 0.55s ease-in-out; }
    .s-index-example .index-example-item {
      position: relative;
      height: 25%; }
      .s-index-example .index-example-item:not(.active) .col-anim {
        opacity: 0; }
        .s-index-example .index-example-item:not(.active) .col-anim:nth-child(1) {
          top: -100px; }
        .s-index-example .index-example-item:not(.active) .col-anim:nth-child(2) {
          top: 100px; }
      .s-index-example .index-example-item.active {
        z-index: 1; }
        .s-index-example .index-example-item.active .col-anim:nth-child(1) {
          top: 0; }
        .s-index-example .index-example-item.active .col-anim:nth-child(2) {
          top: 0; }
      .s-index-example .index-example-item .col-anim {
        transition: 0.35s ease-in-out;
        transition-property: opacity, top; }

.s-index-callback {
  position: relative;
  padding-bottom: 80px; }
  .s-index-callback .index-callback {
    padding-top: 50px; }
  .s-index-callback.t-1 {
    background: -webkit-linear-gradient(106deg, #f4f9ff, #f2f3f8);
    background: -moz-linear-gradient(106deg, #f4f9ff, #f2f3f8);
    background: -o-linear-gradient(106deg, #f4f9ff, #f2f3f8);
    background: linear-gradient(106deg, #f4f9ff, #f2f3f8); }
    .s-index-callback.t-1 .img {
      margin-bottom: -80px; }
  .s-index-callback.t-2 {
    background: -webkit-linear-gradient(106deg, #fffbf4, #ffdfa2);
    background: -moz-linear-gradient(106deg, #fffbf4, #ffdfa2);
    background: -o-linear-gradient(106deg, #fffbf4, #ffdfa2);
    background: linear-gradient(106deg, #fffbf4, #ffdfa2); }
    .s-index-callback.t-2::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      background: url("/static/img/index-callback/iPhone_X_Share_2@2x.png") 100% 100% no-repeat;
      background-size: 712px 480px; }
  .s-index-callback.t-3 {
    min-height: 470px;
    color: #ffffff;
    padding-top: 30px; }
    .s-index-callback.t-3::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      background: url("/static/img/index-callback/img_share_c@2x.png") 50% 50% no-repeat;
      background-size: cover; }
    .s-index-callback.t-3 .index-callback .desc {
      color: rgba(255, 255, 255, 0.7); }
    .s-index-callback.t-3 .index-callback-sale {
      max-width: 334px;
      background-color: #fb5c3f;
      border-radius: 33px;
      box-shadow: 0 10px 40px 0 rgba(251, 92, 63, 0.3);
      color: #ffffff;
      padding: 16px 25px 22px;
      position: absolute;
      top: 83px;
      right: 22px; }
      .s-index-callback.t-3 .index-callback-sale > * {
        display: block; }
      .s-index-callback.t-3 .index-callback-sale .price {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 75px;
        line-height: 1.36; }
      .s-index-callback.t-3 .index-callback-sale .desc {
        font-size: 25px;
        line-height: 1.36;
        letter-spacing: 0.8px; }

.index-callback {
  position: relative;
  max-width: 474px; }
  .index-callback .s-index__title {
    margin-bottom: 20px; }
  .index-callback .desc {
    font-size: 23px;
    color: rgba(50, 60, 93, 0.7);
    line-height: 1.39; }
  .index-callback-form {
    margin-top: 30px; }
    .index-callback-form .form {
      margin-left: -8px;
      margin-right: -8px; }
      .index-callback-form .form .form-group {
        padding-left: 8px;
        padding-right: 8px; }
    .index-callback-form .hint-form {
      font-size: 13px;
      line-height: 1.38;
      color: #fc921f;
      margin-top: -8px; }
      .index-callback-form .hint-form .icon {
        position: relative;
        top: 1px;
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: #fb5c3f;
        border-radius: 50%;
        margin-right: 4px; }
      .index-callback-form .hint-form .sum {
        position: relative;
        top: -1px;
        display: inline-block;
        background-color: rgba(251, 92, 63, 0.15);
        border-radius: 4px;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 10px;
        font-weight: 600;
        line-height: 1.4;
        color: #fb5c3f;
        padding: 3px 8px;
        margin-right: 5px; }

.s-index-question-list {
  background: linear-gradient(58deg, rgba(245, 246, 248, 0.5), rgba(223, 230, 244, 0.5));
  padding: 122px 0 122px; }

.index-question-list.t-big {
  max-width: 464px; }

.index-question-list.t-small {
  margin-top: -60px; }

.index-question-list-item.t-big:not(:last-child) {
  margin-bottom: 40px; }

.index-question-list-item.t-big .title {
  font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 23px;
  font-weight: 600;
  line-height: 1.39;
  color: #323c5d;
  margin-bottom: 10px; }

.index-question-list-item.t-big .desc {
  font-size: 15px;
  line-height: 1.33;
  color: rgba(50, 60, 93, 0.85); }

.index-question-list-item.t-small {
  margin-top: 60px; }
  .index-question-list-item.t-small .inner {
    max-width: 278px; }
  .index-question-list-item.t-small .title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
    color: #323c5d;
    margin-bottom: 10px; }
  .index-question-list-item.t-small .desc {
    font-size: 15px;
    line-height: 1.33;
    color: rgba(50, 60, 93, 0.85); }
  .index-question-list-item.t-small .more {
    margin-top: 20px; }
  .index-question-list-item.t-small .link {
    font-size: 13px;
    line-height: 1.38;
    color: #4174d6; }

.s-index-settings {
  padding: 100px 0 137px; }
  .s-index-settings .index-settings-cnt {
    max-width: 473px; }
    .s-index-settings .index-settings-cnt .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 37px;
      font-weight: 600;
      line-height: 1.38;
      color: #4174d6;
      margin-bottom: 80px; }
    .s-index-settings .index-settings-cnt .subtitle {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 37px;
      font-weight: 600;
      line-height: 1.38;
      color: #323c5d;
      margin-bottom: 20px; }
    .s-index-settings .index-settings-cnt .desc {
      font-size: 23px;
      line-height: 1.39;
      color: rgba(50, 60, 93, 0.7); }
  .s-index-settings .index-settings-list {
    margin-top: 24px; }
    .s-index-settings .index-settings-list li {
      float: left;
      max-width: 180px;
      margin-top: 14px; }
      .s-index-settings .index-settings-list li:not(:last-child) {
        margin-right: 40px; }
      .s-index-settings .index-settings-list li .img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        margin-bottom: 10px; }
        .s-index-settings .index-settings-list li .img.t-1 {
          background-color: #fc921f; }
        .s-index-settings .index-settings-list li .img.t-2 {
          background-color: #4174d6; }
      .s-index-settings .index-settings-list li .title {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: #323c5d;
        margin-bottom: 5px; }
      .s-index-settings .index-settings-list li .desc {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.7); }
  .s-index-settings .index-settings-questions {
    padding-top: 131px; }

.s-index-questions {
  padding: 100px 0 109px; }
  .s-index-questions .s-index__title {
    margin-bottom: 80px; }

.s-index-news {
  padding: 105px 0 106px; }
  .s-index-news .s-index__title {
    margin-bottom: 93px; }
  .s-index-news .index-news {
    margin-top: -30px; }
    .s-index-news .index-news-item {
      margin-top: 30px; }
      .s-index-news .index-news-item .img {
        height: 240px;
        background-color: transparent;
        border: 1px solid transparent; }
      .s-index-news .index-news-item .cnt {
        margin-top: 20px; }
      .s-index-news .index-news-item .title {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 20px;
        font-weight: 600;
        line-height: 1.35;
        color: #323c5d; }
      .s-index-news .index-news-item .desc {
        max-width: 474px;
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.7); }
      .s-index-news .index-news-item.t-1 .img {
        background-color: rgba(65, 116, 214, 0.2);
        border-color: #4174d6; }
      .s-index-news .index-news-item.t-2 .img {
        background-color: rgba(255, 198, 88, 0.2);
        border-color: #ffc658; }
      .s-index-news .index-news-item.t-3 .img {
        background-color: rgba(210, 88, 255, 0.2);
        border-color: #d258ff; }
    .s-index-news .index-news-more {
      text-align: center;
      margin-top: 53px; }
      .s-index-news .index-news-more .link {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        font-weight: 700;
        line-height: 1.38;
        color: #4174d6; }

.s-job .job-head {
  border-bottom: 1px solid #dfe6f4;
  padding: 70px 0 80px; }

.s-job .job-info {
  margin-bottom: 50px; }
  .s-job .job-info::after {
    display: block;
    clear: both;
    content: ""; }
  .s-job .job-info > * {
    display: inline-block;
    vertical-align: top; }
  .s-job .job-info .s-index__title {
    margin-bottom: 0; }
  .s-job .job-info .tab-list {
    float: right;
    padding-top: 11px; }

.s-job .job-desc {
  margin-bottom: 60px; }
  .s-job .job-desc .desc {
    max-width: 75%;
    font-size: 23px;
    line-height: 1.39;
    color: rgba(50, 60, 93, 0.7); }
    .s-job .job-desc .desc:last-child {
      margin-bottom: 0; }

.s-job .job-links {
  border-left: 1px solid #dfe6f4;
  float: right;
  width: 100%;
  text-align: right; }
  .s-job .job-links .link {
    display: inline-block;
    vertical-align: top;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 23px;
    line-height: 1.39;
    color: #4174d6;
    margin-bottom: 14px; }
  .s-job .job-links .offer {
    display: block;
    font-size: 13px;
    line-height: 1.38;
    color: rgba(50, 60, 93, 0.5);
    cursor: pointer; }

.s-job .job-item {
  position: relative; }
  .s-job .job-item:not(:first-child) {
    margin-top: -1px; }
  .s-job .job-item:hover {
    z-index: 1;
    background-color: #fafbfc; }
    .s-job .job-item:hover .job-item-head .title {
      color: #4174d6; }
      .s-job .job-item:hover .job-item-head .title a {
        color: #4174d6; }
  .s-job .job-item.t-vacancies:hover .btn {
    display: inline-block; }
  .s-job .job-item:not(:last-child) .job-item-inner::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #dfe6f4; }
  .s-job .job-item:not(:last-child):hover .job-item-inner::before {
    background-color: #fafbfc; }
  .s-job .job-item-inner {
    position: relative;
    padding: 60px 0 60px; }
    .s-job .job-item-inner::after {
      display: block;
      clear: both;
      content: ""; }
    .s-job .job-item-inner .btn {
      display: none;
      position: absolute;
      right: 0;
      top: 60px;
      z-index: 1;
      border-radius: 6px;
      box-shadow: 0 2px 10px 0 rgba(65, 116, 214, 0.3);
      font-size: 11px; }
  .s-job .job-item.t-vacancies .job-item-img {
    width: 64px;
    height: 64px;
    font-size: 45px;
    line-height: 64px;
    color: #323c5d;
    text-align: center; }
  .s-job .job-item.t-vacancies .job-item-cnt {
    margin-left: 196px; }
  .s-job .job-item.t-partners .job-item-img {
    width: 136px;
    padding-top: 5px; }
    .s-job .job-item.t-partners .job-item-img img {
      width: auto;
      max-width: 100%;
      height: auto; }
  .s-job .job-item.t-partners .job-item-cnt {
    margin-left: 196px; }
  .s-job .job-item-img {
    display: block;
    font-size: 0;
    line-height: 1;
    text-decoration: none;
    float: left; }
    .s-job .job-item-img img {
      vertical-align: top; }
  .s-job .job-item-cnt {
    position: relative;
    padding-right: 295px; }
  .s-job .job-item-head {
    margin-bottom: 15px; }
    .s-job .job-item-head > * {
      display: inline-block; }
    .s-job .job-item-head .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      line-height: 1.37;
      color: #323c5d;
      margin-bottom: 0; }
      .s-job .job-item-head .title a {
        color: #323c5d;
        text-decoration: none; }
    .s-job .job-item-head .position {
      position: relative;
      top: -4px;
      border-radius: 4px;
      background-color: rgba(252, 146, 31, 0.2);
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 10px;
      line-height: 1.4;
      color: #fc921f;
      padding: 3px 8px;
      margin-left: 16px; }
  .s-job .job-item__desc {
    font-size: 17px;
    line-height: 1.35;
    color: rgba(50, 60, 93, 0.7); }
  .s-job .job-item__tags {
    font-size: 15px;
    line-height: 1.33;
    color: #323c5d;
    margin-top: 15px; }
  .s-job .job-item__price {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 25px;
    line-height: 1.36;
    color: #323c5d;
    margin-top: 20px; }
  .s-job .job-item__sum-type {
    display: inline-block;
    vertical-align: top;
    font-size: 13px;
    line-height: 1.38;
    color: rgba(50, 60, 93, 0.7); }
  .s-job .job-item-link {
    margin-top: 30px; }
  .s-job .job-item__link {
    font-size: 15px;
    line-height: 1.33;
    color: #4174d6; }
  .s-job .job-item.is-hide {
    display: none; }

.s-tariff {
  padding: 70px 0 0; }
  .s-tariff .tariff-item:not(:last-child) {
    margin-bottom: 50px; }
  .s-tariff .tariff-head {
    margin-bottom: 70px; }
    .s-tariff .tariff-head > * {
      display: inline-block;
      vertical-align: top; }
    .s-tariff .tariff-head .s-index__title {
      margin-bottom: 0; }
    .s-tariff .tariff-head .type-list {
      margin-bottom: 0;
      margin-left: 46px;
      padding-top: 11px; }
      .s-tariff .tariff-head .type-list li {
        float: left; }
        .s-tariff .tariff-head .type-list li:not(:last-child) {
          margin-right: 10px; }
        .s-tariff .tariff-head .type-list li.active a, .s-tariff .tariff-head .type-list li.active span {
          background-color: #4174d6;
          color: #ffffff;
          text-decoration: none;
          cursor: default; }
      .s-tariff .tariff-head .type-list a, .s-tariff .tariff-head .type-list span {
        display: block;
        border-radius: 20px;
        background-color: transparent;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: rgba(50, 60, 93, 0.7);
        padding: 10px 15px; }
        .s-tariff .tariff-head .type-list a:hover, .s-tariff .tariff-head .type-list span:hover {
          text-decoration: none;
          color: #4174d6; }
    .s-tariff .tariff-head .link-pdf {
      float: right;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
      color: #4174d6;
      margin-top: 21px; }
      .s-tariff .tariff-head .link-pdf .icon {
        position: relative;
        top: 1px;
        display: inline-block;
        vertical-align: top;
        width: 16px;
        height: 16px;
        background-image: url("/static/img/tariff/ic_document.svg");
        background-size: 100% 100%;
        margin-right: 6px; }
  .s-tariff .tariff-desc {
    margin-bottom: 60px; }
    .s-tariff .tariff-desc .desc {
      font-size: 23px;
      line-height: 1.39;
      color: rgba(50, 60, 93, 0.7);
      margin-bottom: 0;
      padding-top: 5px; }
  .s-tariff .tariff-questions-item {
    position: relative;
    overflow: hidden;
    min-height: 106px;
    border-radius: 10px;
    background-position: 50% 50%;
    background-size: cover;
    color: #ffffff;
    padding: 15px 20px; }
    .s-tariff .tariff-questions-item::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(26, 48, 85, 0.47); }
    .s-tariff .tariff-questions-item > * {
      position: relative; }
    .s-tariff .tariff-questions-item.t-1 {
      background-image: url("/static/img/tariff/questions-1.png"); }
    .s-tariff .tariff-questions-item.t-2 {
      background-image: url("/static/img/tariff/questions-2.png"); }
    .s-tariff .tariff-questions-item .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      font-weight: 600;
      line-height: 1.35;
      margin-bottom: 4px; }
    .s-tariff .tariff-questions-item .descrip {
      font-size: 13px;
      line-height: 1.38;
      margin-bottom: 0; }
  .s-tariff .tariff-table {
    overflow: hidden;
    margin-right: -41px;
    margin-left: -30px;
    padding: 50px 41px 50px 30px; }
    .s-tariff .tariff-table::after {
      display: block;
      clear: both;
      content: ""; }
    .s-tariff .tariff-table .column {
      position: relative;
      float: left; }
      .s-tariff .tariff-table .column:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 1px;
        background-color: #d0d8e6; }
    .s-tariff .tariff-table .column.item {
      position: relative;
      padding-bottom: 78px; }
      .s-tariff .tariff-table .column.item::before {
        content: "";
        position: absolute;
        left: -1px;
        top: -10px;
        right: -1px;
        bottom: -10px;
        z-index: 1;
        background-color: #ffffff;
        opacity: 0;
        border-radius: 10px;
        box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.16); }
      .s-tariff .tariff-table .column.item::after {
        bottom: 78px; }
      .s-tariff .tariff-table .column.item.t-1 {
        width: calc((100% - 162px) / 4); }
      .s-tariff .tariff-table .column.item.t-2 {
        width: calc((100% - 162px) / 3); }
      .s-tariff .tariff-table .column.item .cell {
        padding-left: 25px;
        padding-right: 25px; }
      .s-tariff .tariff-table .column.item .item-title {
        padding-top: 15px;
        padding-bottom: 15px; }
        .s-tariff .tariff-table .column.item .item-title .img {
          display: flex;
          align-items: flex-end;
          height: 72px;
          margin-bottom: 10px;
          margin-right: -24px; }
        .s-tariff .tariff-table .column.item .item-title .img-icon {
          background-image: url("/static/img/sprite-tariffs.png"); }
          .s-tariff .tariff-table .column.item .item-title .img-icon.mini {
            width: 148px;
            height: 48px;
            background-position: -1px -21px; }
          .s-tariff .tariff-table .column.item .item-title .img-icon.standart {
            width: 155px;
            height: 62px;
            background-position: -241px -8px; }
          .s-tariff .tariff-table .column.item .item-title .img-icon.extra {
            width: 185px;
            height: 68px;
            background-position: -482px 0; }
          .s-tariff .tariff-table .column.item .item-title .img-icon.maxi {
            width: 179px;
            height: 69px;
            background-position: -723px 0; }
        .s-tariff .tariff-table .column.item .item-title .title {
          font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 30px;
          font-weight: 700;
          line-height: 1.37;
          color: #323c5d;
          margin-bottom: 0; }
        .s-tariff .tariff-table .column.item .item-title .count {
          display: block;
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 15px;
          font-weight: 600;
          line-height: 1.33;
          color: rgba(50, 60, 93, 0.7); }
      .s-tariff .tariff-table .column.item .item-weight {
        padding-top: 20px;
        padding-bottom: 20px; }
        .s-tariff .tariff-table .column.item .item-weight > * {
          display: block; }
        .s-tariff .tariff-table .column.item .item-weight .count {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 20px;
          font-weight: 600;
          line-height: 1.35;
          color: rgba(50, 60, 93, 0.7);
          margin-bottom: 2px; }
        .s-tariff .tariff-table .column.item .item-weight .desc {
          font-size: 13px;
          line-height: 1.38;
          color: rgba(50, 60, 93, 0.5); }
      .s-tariff .tariff-table .column.item .item-size {
        padding-top: 20px;
        padding-bottom: 20px; }
        .s-tariff .tariff-table .column.item .item-size .size-list {
          margin-bottom: 0; }
          .s-tariff .tariff-table .column.item .item-size .size-list li::after {
            display: block;
            clear: both;
            content: ""; }
          .s-tariff .tariff-table .column.item .item-size .size-list li:not(:last-child) {
            margin-bottom: 10px; }
          .s-tariff .tariff-table .column.item .item-size .size-list li > * {
            display: inline-block;
            vertical-align: top; }
          .s-tariff .tariff-table .column.item .item-size .size-list li .count {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 17px;
            font-weight: 600;
            line-height: 1.35;
            color: rgba(50, 60, 93, 0.7); }
      .s-tariff .tariff-table .column.item .item-car {
        padding-top: 20px;
        padding-bottom: 20px; }
        .s-tariff .tariff-table .column.item .item-car .car-list {
          margin: -11px -10px 0; }
          .s-tariff .tariff-table .column.item .item-car .car-list li {
            float: left;
            border-radius: 4px;
            border: solid 1px #e9edf5;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 10px;
            font-weight: 600;
            line-height: 1.4;
            color: rgba(50, 60, 93, 0.7);
            padding: 3px 5px;
            margin-top: 11px; }
            .s-tariff .tariff-table .column.item .item-car .car-list li:not(:last-child) {
              margin-right: 8px; }
            .s-tariff .tariff-table .column.item .item-car .car-list li .icon {
              display: inline-block;
              vertical-align: top;
              width: 24px;
              height: 13.5px;
              margin-right: 4px;
              opacity: 0.7;
              background: url(/static/img/sprite-cartype.svg);
              background-size: 260px auto; }
              .s-tariff .tariff-table .column.item .item-car .car-list li .icon.furgon {
                background-position-y: -30px;
                background-position-x: -29px; }
              .s-tariff .tariff-table .column.item .item-car .car-list li .icon.tent {
                background-position-y: -54px;
                background-position-x: -29px; }
              .s-tariff .tariff-table .column.item .item-car .car-list li .icon.bort {
                background-position-y: -7px;
                background-position-x: -29px; }
              .s-tariff .tariff-table .column.item .item-car .car-list li .icon.fermer-bort {
                background-position-y: -7px;
                background-position-x: 0; }
              .s-tariff .tariff-table .column.item .item-car .car-list li .icon.fullmetall {
                background-position-y: -30px;
                background-position-x: -29px; }
              .s-tariff .tariff-table .column.item .item-car .car-list li .icon.fermer {
                background-position-y: -30px;
                background-position-x: 0; }
      .s-tariff .tariff-table .column.item .item-type {
        padding-top: 20px;
        padding-bottom: 20px; }
        .s-tariff .tariff-table .column.item .item-type .type-list {
          margin: -11px -10px 0; }
          .s-tariff .tariff-table .column.item .item-type .type-list li {
            float: left;
            border-radius: 4px;
            border: solid 1px #e9edf5;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 10px;
            font-weight: 600;
            line-height: 1.4;
            color: rgba(50, 60, 93, 0.7);
            padding: 3px 5px;
            margin-top: 11px; }
            .s-tariff .tariff-table .column.item .item-type .type-list li:not(:last-child) {
              margin-right: 5px; }
      .s-tariff .tariff-table .column.item .item-price {
        padding-top: 25px;
        padding-bottom: 25px; }
        .s-tariff .tariff-table .column.item .item-price .price {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 15px;
          font-weight: 600;
          line-height: 1.33;
          color: rgba(50, 60, 93, 0.5); }
      .s-tariff .tariff-table .column.item .item-count {
        padding-top: 20px;
        padding-bottom: 25px; }
        .s-tariff .tariff-table .column.item .item-count > * {
          position: relative;
          display: block; }
        .s-tariff .tariff-table .column.item .item-count .count {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 20px;
          font-weight: 600;
          line-height: 1.35;
          color: #323c5d;
          margin-bottom: 2px; }
          .s-tariff .tariff-table .column.item .item-count .count .als-rubl {
            line-height: 1.25; }
          .s-tariff .tariff-table .column.item .item-count .count .time {
            top: 7px; }
        .s-tariff .tariff-table .column.item .item-count .desc {
          font-size: 13px;
          line-height: 1.38;
          color: rgba(50, 60, 93, 0.5); }
          .s-tariff .tariff-table .column.item .item-count .desc .time {
            top: 2px; }
        .s-tariff .tariff-table .column.item .item-count .time {
          display: none;
          position: absolute;
          right: 0;
          font-size: 10px;
          line-height: 1.4; }
          .s-tariff .tariff-table .column.item .item-count .time .als-rubl {
            font-size: 11px;
            line-height: 1.1; }
          .s-tariff .tariff-table .column.item .item-count .time .icon {
            position: relative;
            top: 3px;
            display: inline-block;
            vertical-align: top;
            width: 8px;
            height: 8px;
            background-size: 100% 100%;
            margin-right: 2px; }
          .s-tariff .tariff-table .column.item .item-count .time.t-1 {
            color: #fb5c3f; }
            .s-tariff .tariff-table .column.item .item-count .time.t-1 .icon {
              background-image: url("/static/img/tariff/ic_triangle_more.svg"); }
          .s-tariff .tariff-table .column.item .item-count .time.t-2 {
            color: #63b463; }
            .s-tariff .tariff-table .column.item .item-count .time.t-2 .icon {
              background-image: url("/static/img/tariff/ic_triangle_less.svg"); }
      .s-tariff .tariff-table .column.item .item-work {
        padding-top: 20px;
        padding-bottom: 20px; }
        .s-tariff .tariff-table .column.item .item-work .list {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 15px;
          font-weight: 600;
          line-height: 1.33;
          color: rgba(50, 60, 93, 0.7);
          margin-bottom: 0; }
      .s-tariff .tariff-table .column.item .item-btn {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        display: none;
        padding-top: 20px;
        padding-bottom: 20px; }
        .s-tariff .tariff-table .column.item .item-btn .btn {
          border-radius: 10px;
          box-shadow: 0 5px 20px 0 rgba(65, 116, 214, 0.5); }
      .s-tariff .tariff-table .column.item.active, .s-tariff .tariff-table .column.item:hover {
        z-index: 2; }
        .s-tariff .tariff-table .column.item.active::before, .s-tariff .tariff-table .column.item:hover::before {
          opacity: 1; }
        .s-tariff .tariff-table .column.item.active .cell::before, .s-tariff .tariff-table .column.item:hover .cell::before {
          left: -1px;
          right: -1px; }
        .s-tariff .tariff-table .column.item.active .cell.t-border-b-1::after, .s-tariff .tariff-table .column.item:hover .cell.t-border-b-1::after {
          left: -1px;
          right: -1px; }
        .s-tariff .tariff-table .column.item.active .item-title .count, .s-tariff .tariff-table .column.item:hover .item-title .count {
          color: #4174d6; }
        .s-tariff .tariff-table .column.item.active .item-weight .count, .s-tariff .tariff-table .column.item:hover .item-weight .count {
          color: #323c5d; }
        .s-tariff .tariff-table .column.item.active .item-weight .desc, .s-tariff .tariff-table .column.item:hover .item-weight .desc {
          color: #323c5d; }
        .s-tariff .tariff-table .column.item.active .item-work .list, .s-tariff .tariff-table .column.item:hover .item-work .list {
          color: #323c5d; }
        .s-tariff .tariff-table .column.item.active .item-price .price, .s-tariff .tariff-table .column.item:hover .item-price .price {
          color: #63b463; }
        .s-tariff .tariff-table .column.item.active .item-size .size-list li .count, .s-tariff .tariff-table .column.item:hover .item-size .size-list li .count {
          color: #323c5d; }
        .s-tariff .tariff-table .column.item.active .item-car .car-list li, .s-tariff .tariff-table .column.item:hover .item-car .car-list li {
          color: #4174d6; }
          .s-tariff .tariff-table .column.item.active .item-car .car-list li .icon, .s-tariff .tariff-table .column.item:hover .item-car .car-list li .icon {
            opacity: 1; }
        .s-tariff .tariff-table .column.item.active .item-btn, .s-tariff .tariff-table .column.item:hover .item-btn {
          display: block; }
    .s-tariff .tariff-table .column.desc {
      width: 162px; }
      .s-tariff .tariff-table .column.desc .cell {
        padding-right: 20px; }
        .s-tariff .tariff-table .column.desc .cell:not(.not-h)::before {
          left: -30px;
          right: 1px; }
        .s-tariff .tariff-table .column.desc .cell .title {
          display: block;
          font-size: 17px;
          line-height: 1.35;
          color: rgba(50, 60, 93, 0.6); }
          .s-tariff .tariff-table .column.desc .cell .title.t-bold {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 20px;
            font-weight: 600;
            color: #323c5d; }
      .s-tariff .tariff-table .column.desc .desc-title {
        padding-top: 25px;
        padding-bottom: 15px; }
      .s-tariff .tariff-table .column.desc .desc-weight {
        padding-top: 23px;
        padding-bottom: 20px; }
      .s-tariff .tariff-table .column.desc .desc-size {
        padding-top: 20px;
        padding-bottom: 20px; }
        .s-tariff .tariff-table .column.desc .desc-size .size-list {
          margin-bottom: 0; }
          .s-tariff .tariff-table .column.desc .desc-size .size-list li::after {
            display: block;
            clear: both;
            content: ""; }
          .s-tariff .tariff-table .column.desc .desc-size .size-list li:not(:last-child) {
            margin-bottom: 10px; }
          .s-tariff .tariff-table .column.desc .desc-size .size-list li > * {
            display: inline-block;
            vertical-align: top; }
          .s-tariff .tariff-table .column.desc .desc-size .size-list li .title {
            font-size: 17px;
            line-height: 1.35;
            color: rgba(50, 60, 93, 0.6); }
            .s-tariff .tariff-table .column.desc .desc-size .size-list li .title .icon {
              position: relative;
              top: -1px;
              display: inline-block;
              vertical-align: middle;
              background-image: url("/static/img/sprite-autopark.svg");
              margin-right: 10px; }
            .s-tariff .tariff-table .column.desc .desc-size .size-list li .title.t-1 .icon {
              width: 12px;
              height: 8px;
              background-position: -392px -206px; }
            .s-tariff .tariff-table .column.desc .desc-size .size-list li .title.t-2 .icon {
              width: 13px;
              height: 12px;
              background-position: -369px -204px; }
            .s-tariff .tariff-table .column.desc .desc-size .size-list li .title.t-3 .icon {
              width: 12px;
              height: 12px;
              background-position: -348px -204px; }
            .s-tariff .tariff-table .column.desc .desc-size .size-list li .title.t-4 .icon {
              width: 12px;
              height: 12px;
              background-position: -414px -204px; }
      .s-tariff .tariff-table .column.desc .desc-car {
        padding-top: 18px;
        padding-bottom: 20px; }
      .s-tariff .tariff-table .column.desc .desc-type {
        padding-top: 18px;
        padding-bottom: 20px; }
      .s-tariff .tariff-table .column.desc .desc-price {
        padding-top: 21px;
        padding-bottom: 5px; }
      .s-tariff .tariff-table .column.desc .desc-count {
        padding-top: 23px;
        padding-bottom: 20px; }
      .s-tariff .tariff-table .column.desc .desc-work {
        padding-top: 16px;
        padding-bottom: 20px; }
    .s-tariff .tariff-table .cell {
      position: relative;
      z-index: 1;
      cursor: pointer; }
      .s-tariff .tariff-table .cell:not(.not-h)::before {
        content: "";
        position: absolute;
        left: 0;
        right: 1px;
        top: 0;
        bottom: 1px;
        opacity: 0;
        background-color: #fafbfc; }
      .s-tariff .tariff-table .cell.active > *, .s-tariff .tariff-table .cell:hover > * {
        position: relative;
        z-index: 2; }
      .s-tariff .tariff-table .cell.active::before, .s-tariff .tariff-table .cell:hover::before {
        opacity: 1; }
      .s-tariff .tariff-table .cell.active::after, .s-tariff .tariff-table .cell:hover::after {
        z-index: -1; }
      .s-tariff .tariff-table .cell.active.item-count .time, .s-tariff .tariff-table .cell:hover.item-count .time {
        display: block; }
      .s-tariff .tariff-table .cell:not(:last-child)::after {
        content: '';
        opacity: 0;
        position: absolute;
        bottom: 0;
        height: 1px; }
      .s-tariff .tariff-table .cell[class*="t-border-b-"]::after {
        opacity: 1;
        background-color: #d0d8e6; }
      .s-tariff .tariff-table .cell.t-border-b-1::after {
        left: 0;
        right: 0; }
      .s-tariff .tariff-table .cell.t-border-b-2::after {
        left: 15px;
        right: 15px; }
      .s-tariff .tariff-table .cell.cell-title.t-1 {
        height: 172px; }
      .s-tariff .tariff-table .cell.cell-title.t-2 {
        height: 90px; }
      .s-tariff .tariff-table .cell.cell-weight {
        height: 120px; }
      .s-tariff .tariff-table .cell.cell-size {
        height: 166px; }
      .s-tariff .tariff-table .cell.cell-car {
        height: 161px; }
      .s-tariff .tariff-table .cell.cell-type {
        height: 194px; }
      .s-tariff .tariff-table .cell.cell-price {
        height: 74px; }
      .s-tariff .tariff-table .cell.cell-count {
        height: 90px; }
      .s-tariff .tariff-table .cell.cell-work {
        height: 135px; }

.s-tariff-questions {
  background-color: #fbfcfd;
  padding: 70px 0 109px; }
  .s-tariff-questions .s-index__title {
    margin-bottom: 80px; }

.s-index-one-wrapper {
  position: relative;
  margin-top: -60px; }

.s-index-delivery {
  position: relative;
  padding: 115px 0 40px; }
  .s-index-delivery .container {
    position: relative; }
  .s-index-delivery .s-index__title {
    margin-bottom: 10px;
    margin-left: 186px; }
    .s-index-delivery .s-index__title .color {
      color: #fc921f; }
  .s-index-delivery .index-delivery-route {
    background-color: #ffffff;
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    max-width: 808px;
    margin: 0 auto 70px;
    padding: 15px 20px 20px; }
    .s-index-delivery .index-delivery-route-head {
      margin-bottom: 15px; }
      .s-index-delivery .index-delivery-route-head::after {
        display: block;
        clear: both;
        content: ""; }
      .s-index-delivery .index-delivery-route-head > * {
        display: inline-block;
        vertical-align: top; }
      .s-index-delivery .index-delivery-route-head .title {
        float: left;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: #323c5d; }
      .s-index-delivery .index-delivery-route-head .desc {
        position: relative;
        float: right;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        color: #323c5d;
        padding-top: 1px;
        cursor: pointer; }
        .s-index-delivery .index-delivery-route-head .desc:hover .hint-info {
          display: block; }
        .s-index-delivery .index-delivery-route-head .desc .time {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        .s-index-delivery .index-delivery-route-head .desc .icon {
          position: relative;
          top: 1px;
          display: inline-block;
          width: 12px;
          height: 12px;
          background-image: url("/static/img/sprite-calc.svg");
          background-position: -44px -102px;
          margin-right: 4px; }
      .s-index-delivery .index-delivery-route-head .hint-info {
        display: none;
        position: absolute;
        left: 100%;
        top: -20px;
        z-index: 1;
        width: 210px;
        border-radius: 16px;
        background-color: rgba(0, 0, 0, 0.7);
        font-family: "Open Sans Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        line-height: 1.38;
        color: #ffffff;
        margin-left: 7px;
        padding: 20px 20px; }
        .s-index-delivery .index-delivery-route-head .hint-info .count {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .s-index-delivery .index-delivery-route-inputs {
      position: relative;
      height: 50px;
      background-color: #ffffff;
      border: solid 1px #dfe6f4;
      border-radius: 10px; }
      .s-index-delivery .index-delivery-route-inputs::after {
        display: block;
        clear: both;
        content: ""; }
      .s-index-delivery .index-delivery-route-inputs::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 12px;
        height: 12px;
        background-image: url("/static/img/sprite-index.svg");
        background-position: -636px -486px;
        margin-left: -6px;
        margin-top: -6px; }
    .s-index-delivery .index-delivery-route-input {
      position: relative;
      float: left;
      width: calc(50% - 8px);
      height: 48px;
      padding: 4px 8px 4px 49px; }
      .s-index-delivery .index-delivery-route-input:nth-child(1) {
        margin-right: 8px; }
      .s-index-delivery .index-delivery-route-input:nth-child(2) {
        margin-left: 8px; }
      .s-index-delivery .index-delivery-route-input .icon {
        position: absolute;
        left: 15px;
        top: 50%;
        width: 24px;
        height: 24px;
        background-image: url("/static/img/sprite-index.svg");
        border-radius: 4px;
        margin-top: -12px; }
      .s-index-delivery .index-delivery-route-input .geo-icon {
        position: absolute;
        right: 8px;
        top: 50%;
        z-index: 1;
        width: 16px;
        height: 16px;
        background-image: url("/static/img/sprite-calc.svg");
        background-position: 0 -42px;
        cursor: pointer;
        margin-top: -8px; }
      .s-index-delivery .index-delivery-route-input.t-1:not(.active) .icon {
        background-position: -744px -450px; }
      .s-index-delivery .index-delivery-route-input.t-1 .adress-selector .delete {
        right: 15px; }
      .s-index-delivery .index-delivery-route-input.t-2:not(.active) .icon {
        background-position: -778px -450px; }
      .s-index-delivery .index-delivery-route-input.active.t-1 .icon {
        background-position: -744px -450px; }
      .s-index-delivery .index-delivery-route-input.active.t-2 .icon {
        background-position: -778px -450px; }
      .s-index-delivery .index-delivery-route-input .input > * {
        display: block; }
      .s-index-delivery .index-delivery-route-input .input .empty {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: rgba(50, 60, 93, 0.3);
        margin-top: 7px; }
      .s-index-delivery .index-delivery-route-input .input .address {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: #323c5d; }
      .s-index-delivery .index-delivery-route-input .input .city {
        font-size: 10px;
        line-height: 1.4;
        color: rgba(60, 78, 90, 0.5); }
      .s-index-delivery .index-delivery-route-input .adress-selector {
        width: 100%;
        border-color: transparent;
        padding-left: 0;
        padding-right: 0; }
      .s-index-delivery .index-delivery-route-input .menu {
        width: auto;
        right: 0; }
  .s-index-delivery .index-delivery-tariffs {
    position: relative;
    min-height: 270px;
    font-size: 0;
    margin: 0 -8px 0; }
  .s-index-delivery .index-delivery-column {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(25% - 16px);
    height: 270px;
    font-size: 16px;
    margin: 0 8px; }
    .s-index-delivery .index-delivery-column .loader {
      background-color: #ffffff; }
  .s-index-delivery .index-delivery-tariff {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    min-height: 270px;
    cursor: pointer;
    transition: top 0.25s ease-in-out; }
    .s-index-delivery .index-delivery-tariff::before {
      content: "";
      position: absolute;
      left: 0;
      top: -15px;
      right: 0;
      bottom: 0;
      opacity: 0;
      background-color: #ffffff;
      border-radius: 20px;
      box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.16);
      transition: opacity 0.15s ease-in-out; }
    .s-index-delivery .index-delivery-tariff > * {
      position: relative; }
    .s-index-delivery .index-delivery-tariff .label-type {
      position: absolute;
      left: 8px;
      top: 14px;
      z-index: 1;
      border-radius: 4px;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 13px;
      font-weight: 600;
      line-height: 1.38;
      padding: 6px 10px; }
      .s-index-delivery .index-delivery-tariff .label-type.t-1 {
        background-color: #ffc658;
        color: #323c5d; }
    .s-index-delivery .index-delivery-tariff .img {
      overflow: hidden;
      height: 130px; }
      .s-index-delivery .index-delivery-tariff .img .inner {
        display: flex;
        align-items: flex-end;
        height: 100%; }
      .s-index-delivery .index-delivery-tariff .img img {
        vertical-align: top;
        max-width: 100%;
        height: auto; }
      .s-index-delivery .index-delivery-tariff .img .img-icon {
        background-image: url("/static/img/sprite-index_rastr.png");
        margin: 0 auto; }
        .s-index-delivery .index-delivery-tariff .img .img-icon.mini2 {
          width: 211px;
          height: 79px; }
          .s-index-delivery .index-delivery-tariff .img .img-icon.mini2:not(.active) {
            background-position: 0 -45px; }
          .s-index-delivery .index-delivery-tariff .img .img-icon.mini2.active {
            background-position: 0 -225px; }
        .s-index-delivery .index-delivery-tariff .img .img-icon.standart2 {
          width: 209px;
          height: 101px; }
          .s-index-delivery .index-delivery-tariff .img .img-icon.standart2:not(.active) {
            background-position: -344px -24px; }
          .s-index-delivery .index-delivery-tariff .img .img-icon.standart2.active {
            background-position: -344px -203px; }
        .s-index-delivery .index-delivery-tariff .img .img-icon.extra2 {
          width: 261px;
          height: 115px; }
          .s-index-delivery .index-delivery-tariff .img .img-icon.extra2:not(.active) {
            background-position: -661px -9px; }
          .s-index-delivery .index-delivery-tariff .img .img-icon.extra2.active {
            background-position: -661px -189px; }
        .s-index-delivery .index-delivery-tariff .img .img-icon.maxi2 {
          width: 247px;
          height: 119px; }
          .s-index-delivery .index-delivery-tariff .img .img-icon.maxi2:not(.active) {
            background-position: -990px -6px; }
          .s-index-delivery .index-delivery-tariff .img .img-icon.maxi2.active {
            background-position: -990px -186px; }
    .s-index-delivery .index-delivery-tariff .cnt {
      padding: 25px 20px 15px; }
    .s-index-delivery .index-delivery-tariff .tariff {
      margin-bottom: 5px; }
      .s-index-delivery .index-delivery-tariff .tariff > * {
        display: inline-block;
        vertical-align: middle; }
      .s-index-delivery .index-delivery-tariff .tariff .title {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 21px;
        font-weight: 700;
        line-height: 1.33;
        color: #323c5d; }
      .s-index-delivery .index-delivery-tariff .tariff .weight {
        position: relative;
        top: 1px;
        border-radius: 4px;
        background-color: rgba(50, 60, 93, 0.06);
        border: 1px solid transparent;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 10px;
        font-weight: 600;
        line-height: 1.4;
        color: rgba(50, 60, 93, 0.7);
        padding: 4px 5px;
        margin-left: 10px; }
        .s-index-delivery .index-delivery-tariff .tariff .weight .icon {
          position: relative;
          top: 1px;
          display: inline-block;
          vertical-align: top;
          width: 12px;
          height: 12px;
          background-image: url("/static/img/index-delivery/ic_size_weight.svg");
          background-size: 100% 100%;
          opacity: 0.5;
          margin-right: 5px; }
    .s-index-delivery .index-delivery-tariff .price::after {
      display: block;
      clear: both;
      content: ""; }
    .s-index-delivery .index-delivery-tariff .price > * {
      display: inline-block;
      vertical-align: middle; }
    .s-index-delivery .index-delivery-tariff .price .sum {
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      font-weight: 700;
      line-height: 1.37;
      color: #323c5d; }
      .s-index-delivery .index-delivery-tariff .price .sum .als-rubl {
        font-size: 31px; }
      .s-index-delivery .index-delivery-tariff .price .sum .start {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 16px;
        font-weight: 600; }
      .s-index-delivery .index-delivery-tariff .price .sum .count {
        font-family: "Open Sans Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 16px;
        font-weight: 400;
        color: rgba(50, 60, 93, 0.5); }
    .s-index-delivery .index-delivery-tariff .price .desc {
      display: none;
      opacity: 0.7;
      float: left;
      width: 105px;
      font-size: 12px;
      font-style: italic;
      line-height: 1.42;
      color: rgba(50, 60, 93, 0.5);
      margin-bottom: 0;
      margin-top: 5px; }
      .s-index-delivery .index-delivery-tariff .price .desc .count {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 600;
        font-style: normal;
        color: rgba(50, 60, 93, 0.7); }
    .s-index-delivery .index-delivery-tariff .info {
      display: none;
      margin-top: 12px;
      margin-bottom: 15px; }
      .s-index-delivery .index-delivery-tariff .info .label {
        display: block;
        font-size: 13px;
        line-height: 1.38;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 600;
        color: #323c5d; }
      .s-index-delivery .index-delivery-tariff .info .desc {
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d;
        margin-bottom: 4px; }
      .s-index-delivery .index-delivery-tariff .info .list {
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d; }
    .s-index-delivery .index-delivery-tariff .type {
      display: none;
      margin-top: 17px; }
      .s-index-delivery .index-delivery-tariff .type .item {
        position: relative;
        border-radius: 10px;
        background-color: rgba(50, 60, 93, 0.05);
        padding: 10px 10px;
        cursor: pointer; }
        .s-index-delivery .index-delivery-tariff .type .item::after {
          display: block;
          clear: both;
          content: ""; }
        .s-index-delivery .index-delivery-tariff .type .item:hover {
          background-color: rgba(65, 116, 214, 0.05);
          padding-left: 31px; }
          .s-index-delivery .index-delivery-tariff .type .item:hover .icon {
            opacity: 1; }
          .s-index-delivery .index-delivery-tariff .type .item:hover .count {
            color: #4174d6; }
        .s-index-delivery .index-delivery-tariff .type .item.is-click {
          background-color: rgba(99, 180, 99, 0.1);
          padding-left: 31px;
          cursor: default; }
          .s-index-delivery .index-delivery-tariff .type .item.is-click .icon {
            opacity: 1; }
            .s-index-delivery .index-delivery-tariff .type .item.is-click .icon .cls-1 {
              opacity: 1; }
            .s-index-delivery .index-delivery-tariff .type .item.is-click .icon .cls-3 {
              fill: #63b463; }
          .s-index-delivery .index-delivery-tariff .type .item.is-click .count {
            color: #63b463; }
          .s-index-delivery .index-delivery-tariff .type .item.is-click .sum {
            color: #323c5d; }
        .s-index-delivery .index-delivery-tariff .type .item > * {
          display: inline-block;
          vertical-align: top; }
        .s-index-delivery .index-delivery-tariff .type .item:not(:last-child) {
          margin-bottom: 10px; }
        .s-index-delivery .index-delivery-tariff .type .item .icon {
          opacity: 0;
          position: absolute;
          left: 10px;
          top: 50%;
          width: 16px;
          height: 16px;
          font-size: 0;
          line-height: 1;
          margin-top: -8px; }
          .s-index-delivery .index-delivery-tariff .type .item .icon .svg {
            width: 100%;
            height: 100%; }
          .s-index-delivery .index-delivery-tariff .type .item .icon .cls-1 {
            opacity: 0.2; }
          .s-index-delivery .index-delivery-tariff .type .item .icon .cls-2 {
            fill: none; }
          .s-index-delivery .index-delivery-tariff .type .item .icon .cls-3 {
            fill: #4174d6; }
        .s-index-delivery .index-delivery-tariff .type .item .count {
          float: left;
          font-size: 15px;
          line-height: 1.33;
          color: rgba(50, 60, 93, 0.7); }
        .s-index-delivery .index-delivery-tariff .type .item .sum {
          float: right;
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 15px;
          font-weight: 600;
          line-height: 1.33;
          color: rgba(50, 60, 93, 0.7); }
          .s-index-delivery .index-delivery-tariff .type .item .sum .time {
            font-size: 10px;
            font-weight: normal;
            line-height: 1.4; }
    .s-index-delivery .index-delivery-tariff .buttons {
      display: none;
      margin-top: 15px; }
      .s-index-delivery .index-delivery-tariff .buttons .btn {
        background-color: #fc921f;
        border-radius: 10px;
        box-shadow: 0 5px 20px 0 rgba(252, 146, 31, 0.45); }
        .s-index-delivery .index-delivery-tariff .buttons .btn:hover {
          color: #ffffff;
          background-color: #fb5c3f;
          border-color: #fb5c3f; }
      .s-index-delivery .index-delivery-tariff .buttons .demo {
        display: block;
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.5);
        text-align: center;
        margin-top: 10px;
        cursor: pointer; }
    .s-index-delivery .index-delivery-tariff .count-cars {
      font-size: 13px;
      line-height: 1.38;
      color: rgba(50, 60, 93, 0.5);
      margin-top: 5px; }
    .s-index-delivery .index-delivery-tariff.hovered, .s-index-delivery .index-delivery-tariff.active, .s-index-delivery .index-delivery-tariff:hover {
      top: -35px;
      z-index: 1; }
      .s-index-delivery .index-delivery-tariff.hovered::before, .s-index-delivery .index-delivery-tariff.active::before, .s-index-delivery .index-delivery-tariff:hover::before {
        opacity: 1; }
      .s-index-delivery .index-delivery-tariff.hovered .img .inner:nth-child(1), .s-index-delivery .index-delivery-tariff.active .img .inner:nth-child(1), .s-index-delivery .index-delivery-tariff:hover .img .inner:nth-child(1) {
        margin-top: -50%; }
      .s-index-delivery .index-delivery-tariff.hovered .tariff .weight, .s-index-delivery .index-delivery-tariff.active .tariff .weight, .s-index-delivery .index-delivery-tariff:hover .tariff .weight {
        background-color: rgba(65, 116, 214, 0);
        border-color: #4174d6;
        color: #4174d6; }
        .s-index-delivery .index-delivery-tariff.hovered .tariff .weight .icon, .s-index-delivery .index-delivery-tariff.active .tariff .weight .icon, .s-index-delivery .index-delivery-tariff:hover .tariff .weight .icon {
          display: none;
          opacity: 1; }
      .s-index-delivery .index-delivery-tariff.hovered .price, .s-index-delivery .index-delivery-tariff.active .price, .s-index-delivery .index-delivery-tariff:hover .price {
        margin-top: 15px; }
        .s-index-delivery .index-delivery-tariff.hovered .price .sum, .s-index-delivery .index-delivery-tariff.active .price .sum, .s-index-delivery .index-delivery-tariff:hover .price .sum {
          float: right; }
          .s-index-delivery .index-delivery-tariff.hovered .price .sum .start,
          .s-index-delivery .index-delivery-tariff.hovered .price .sum .count, .s-index-delivery .index-delivery-tariff.active .price .sum .start,
          .s-index-delivery .index-delivery-tariff.active .price .sum .count, .s-index-delivery .index-delivery-tariff:hover .price .sum .start,
          .s-index-delivery .index-delivery-tariff:hover .price .sum .count {
            display: none; }
        .s-index-delivery .index-delivery-tariff.hovered .price .desc, .s-index-delivery .index-delivery-tariff.active .price .desc, .s-index-delivery .index-delivery-tariff:hover .price .desc {
          display: block; }
      .s-index-delivery .index-delivery-tariff.hovered .info, .s-index-delivery .index-delivery-tariff.active .info, .s-index-delivery .index-delivery-tariff:hover .info {
        display: block; }
      .s-index-delivery .index-delivery-tariff.hovered .type, .s-index-delivery .index-delivery-tariff.active .type, .s-index-delivery .index-delivery-tariff:hover .type {
        display: block; }
      .s-index-delivery .index-delivery-tariff.hovered .buttons, .s-index-delivery .index-delivery-tariff.active .buttons, .s-index-delivery .index-delivery-tariff:hover .buttons {
        display: block; }
      .s-index-delivery .index-delivery-tariff.hovered .count-cars, .s-index-delivery .index-delivery-tariff.active .count-cars, .s-index-delivery .index-delivery-tariff:hover .count-cars {
        display: none; }
  .s-index-delivery .index-delivery-abs {
    position: absolute;
    right: 15px;
    top: -28px; }
  .s-index-delivery .index-delivery-sale {
    border-radius: 21px;
    background-color: #4174d6;
    text-align: center;
    padding: 3px 15px 5px; }
    .s-index-delivery .index-delivery-sale > * {
      display: block; }
    .s-index-delivery .index-delivery-sale .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
      color: #ffffff; }
    .s-index-delivery .index-delivery-sale .desc {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 10px;
      font-weight: 600;
      line-height: 1.4;
      color: rgba(255, 255, 255, 0.7); }
  .s-index-delivery .index-delivery-help {
    text-align: right;
    margin-top: 30px; }
    .s-index-delivery .index-delivery-help .link {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #323c5d;
      opacity: 0.5; }
  .s-index-delivery .index-delivery-partners {
    margin-top: 100px; }
    .s-index-delivery .index-delivery-partners::after {
      display: block;
      clear: both;
      content: ""; }
    .s-index-delivery .index-delivery-partners .label {
      float: left;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 23px;
      font-weight: 600;
      line-height: 1.39;
      color: rgba(50, 60, 93, 0.7);
      margin-top: 7px; }
    .s-index-delivery .index-delivery-partners-list {
      justify-content: space-between;
      float: left;
      width: calc(100% - 175px);
      padding-left: 60px; }
      .s-index-delivery .index-delivery-partners-list li:not(:last-child) {
        margin-right: 15px; }
  .s-index-delivery .index-delivery__desc {
    max-width: 670px;
    font-size: 15px;
    line-height: 1.33;
    color: rgba(50, 60, 93, 0.7);
    text-align: center;
    margin: 0 auto 20px; }

.index-delivery-partners {
  position: relative; }
  .index-delivery-partners-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 0;
    margin-bottom: 0; }
    .index-delivery-partners-list li {
      display: inline-block;
      vertical-align: middle;
      padding: 7px 0; }
      .index-delivery-partners-list li img {
        vertical-align: top; }
      .index-delivery-partners-list li a {
        display: block;
        font-size: 0;
        line-height: 1;
        text-decoration: none; }
      .index-delivery-partners-list li .img-icon {
        background-image: url("/static/img/sprite-business.svg"); }
        .index-delivery-partners-list li .img-icon.bacardi {
          width: 110px;
          height: 27px;
          background-position: -222px 0; }
        .index-delivery-partners-list li .img-icon.michelin {
          width: 106px;
          height: 32px;
          background-position: -222px -46px; }
        .index-delivery-partners-list li .img-icon.coca_cola {
          width: 79px;
          height: 26px;
          background-position: -222px -96px; }
        .index-delivery-partners-list li .img-icon.mtc {
          width: 66px;
          height: 32px;
          background-position: -222px -140px; }
        .index-delivery-partners-list li .img-icon.fifa {
          width: 113px;
          height: 23px;
          background-position: -222px -190px; }
        .index-delivery-partners-list li .img-icon.myz {
          width: 29px;
          height: 31px;
          background-position: -222px -231px; }
        .index-delivery-partners-list li .img-icon.moscow {
          width: 122px;
          height: 16px;
          background-position: -222px -279px; }
    .index-delivery-partners-list.t-press li {
      margin: 0 15px; }
      .index-delivery-partners-list.t-press li img {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: filter 0.25s ease-in-out; }
      .index-delivery-partners-list.t-press li a:hover img {
        -webkit-filter: grayscale(1%);
        -moz-filter: grayscale(1%);
        -ms-filter: grayscale(1%);
        -o-filter: grayscale(1%);
        filter: grayscale(1%); }

.s-index-press {
  background-color: #fbfcfd;
  padding: 80px 0 80px; }
  .s-index-press .s-index__title {
    margin-bottom: 50px; }

.s-index-reviews {
  background-color: #fbfcfd;
  padding: 80px 0 80px; }
  .s-index-reviews .s-index__title {
    margin-bottom: 75px; }

.reviews {
  width: 570px;
  margin: 0 auto; }
  .reviews.t-2 {
    width: 768px; }
    .reviews.t-2 .reviews-item {
      width: 768px; }
  .reviews-wrapper {
    width: 30000px;
    margin: 0 -30px;
    transition: 0.45s ease-in-out; }
    .reviews-wrapper::after {
      display: block;
      clear: both;
      content: ""; }
  .reviews-item {
    float: left;
    width: 570px;
    margin: 0 30px; }
    .reviews-item-head {
      display: flex;
      justify-content: center;
      align-items: center; }
      .reviews-item-head > * {
        display: inline-block;
        vertical-align: middle; }
    .reviews-item-img {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px; }
      .reviews-item-img img {
        border-radius: 50%;
        vertical-align: top;
        width: 100%;
        height: 100%; }
    .reviews-item-brand {
      min-height: 109px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .reviews-item-brand .img {
        background-image: url("/static/img/sprite-company-feedback.png"); }
        .reviews-item-brand .img.michelin {
          width: 297px;
          height: 90px;
          background-position: -311px 0; }
        .reviews-item-brand .img.kidburg {
          width: 230px;
          height: 78px;
          background-position: -965px -15px; }
        .reviews-item-brand .img.dkgm {
          width: 92px;
          height: 109px;
          background-position: -724px 0; }
        .reviews-item-brand .img.nuzhnapomosh {
          width: 276px;
          height: 59px;
          background-position: -1253px -25px; }
        .reviews-item-brand .img.fifa2018 {
          width: 261px;
          height: 102px;
          background-position: -24px -3px; }
    .reviews-item-user .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      font-weight: 600;
      line-height: 1.35;
      color: #323c5d;
      margin-bottom: 5px; }
    .reviews-item-user .position {
      font-size: 15px;
      line-height: 1.33;
      color: rgba(21, 35, 82, 0.49);
      margin-bottom: 0; }
    .reviews-item-cnt {
      position: relative;
      background-color: #f3f4f7;
      border-radius: 10px;
      margin-top: 36px;
      padding: 46px 26px;
      /*&::before {
        content: "";
        position: absolute;
        top: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 8px 9px;
        border-color: transparent;
        border-bottom-color: #dfe6f4;
      }*/ }
      .reviews-item-cnt::before, .reviews-item-cnt::after {
        position: absolute;
        font-size: 75px;
        color: #dfe6f4;
        line-height: 1; }
      .reviews-item-cnt::before {
        content: "\00ab";
        left: 10px;
        top: -40px; }
      .reviews-item-cnt::after {
        content: "\00bb";
        right: 10px;
        bottom: -20px; }
      .reviews-item-cnt .text {
        font-size: 17px;
        line-height: 1.33;
        color: rgba(50, 60, 93, 0.85);
        margin-bottom: 0; }
    .reviews-item-position {
      text-align: center;
      margin-top: 20px; }
      .reviews-item-position .title {
        font-size: 23px;
        line-height: 1.39;
        color: rgba(50, 60, 93, 0.7);
        margin-bottom: 0; }
    .reviews-item-text {
      position: relative;
      text-align: center;
      margin-top: 19px; }
      .reviews-item-text .text {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 30px;
        line-height: 1.37;
        color: #323c5d;
        margin-bottom: 0; }
      .reviews-item-text .quote {
        position: relative;
        display: inline-block;
        width: 27px;
        height: 21px;
        background-size: 100% 100%; }
        .reviews-item-text .quote.t-left {
          top: -10px;
          background-image: url("/static/img/reviews/quote_1.svg");
          margin-right: 5px; }
        .reviews-item-text .quote.t-right {
          top: 10px;
          background-image: url("/static/img/reviews/quote_2.svg");
          margin-left: 5px; }

.s-index-support {
  position: relative;
  background-color: #fcfbfa;
  padding: 100px 0 110px; }
  .s-index-support .container {
    position: relative; }
  .s-index-support::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 25%;
    width: 516px;
    height: 297px;
    background-image: url("/static/img/sprite-index_rastr.png");
    background-position: 0 -655px; }
  .s-index-support .tab-title {
    margin-bottom: 80px; }
    .s-index-support .tab-title > * {
      display: inline-block;
      vertical-align: top; }
  .s-index-support .type-list {
    margin-bottom: 0;
    margin-left: 65px;
    padding-top: 10px; }
    .s-index-support .type-list li {
      float: left;
      cursor: pointer; }
      .s-index-support .type-list li:not(:last-child) {
        margin-right: 10px; }
      .s-index-support .type-list li.active a, .s-index-support .type-list li.active span {
        background-color: #4174d6;
        color: #ffffff;
        text-decoration: none;
        cursor: default; }
    .s-index-support .type-list a, .s-index-support .type-list span {
      display: block;
      border-radius: 20px;
      background-color: transparent;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 370;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.7);
      padding: 10px 15px; }
      .s-index-support .type-list a:hover, .s-index-support .type-list span:hover {
        text-decoration: none;
        color: #4174d6; }
  .s-index-support .s-index__title {
    color: #4174d6;
    margin-bottom: 0; }
    .s-index-support .s-index__title .icon {
      position: relative;
      top: -3px;
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 34px;
      background-image: url("/static/img/sprite-index.svg");
      background-position: -611px -325px;
      margin-right: 22px; }
  .s-index-support .index-support {
    max-width: 670px; }
    .s-index-support .index-support-cnt {
      min-height: 270px;
      margin-bottom: 89px; }
    .s-index-support .index-support .subtitle {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      font-weight: 600;
      line-height: 1.37;
      color: #323c5d;
      margin-bottom: 20px; }
    .s-index-support .index-support .desc {
      font-size: 20px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.7); }
    .s-index-support .index-support .more-btn {
      margin-top: 50px; }
      .s-index-support .index-support .more-btn a {
        border-radius: 10px;
        background-color: #323c5d;
        border-color: #323c5d;
        font-size: 15px;
        font-weight: 370; }
        .s-index-support .index-support .more-btn a .icon {
          display: inline-block;
          margin-left: 8px;
          width: 11px;
          height: 9px;
          background-image: url("/static/img/sprite-index.svg");
          background-position: -611px -488px;
          transition: 0.25s ease-in-out; }
        .s-index-support .index-support .more-btn a:hover .icon {
          margin-left: 16px; }
    .s-index-support .index-support .more {
      margin-top: 50px; }
      .s-index-support .index-support .more .btn {
        background-color: #323c5d;
        border-color: #323c5d; }
        .s-index-support .index-support .more .btn .icon {
          display: inline-block;
          margin-left: 8px;
          width: 11px;
          height: 9px;
          background-image: url("/static/img/ic_arrow_right.svg");
          background-size: 100% 100%; }
    .s-index-support .index-support-item .cnt {
      margin-top: 15px; }
      .s-index-support .index-support-item .cnt .link-open-intercom {
        color: #323c5d;
        cursor: pointer; }
        .s-index-support .index-support-item .cnt .link-open-intercom:hover {
          color: #0056b3; }
    .s-index-support .index-support-item .title {
      min-height: 54px;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      font-weight: 600;
      line-height: 1.35;
      color: #323c5d; }
      .s-index-support .index-support-item .title a {
        color: #323c5d;
        text-decoration: none; }
        .s-index-support .index-support-item .title a:hover {
          color: #0056b3; }
    .s-index-support .index-support-item .desc {
      font-size: 15px;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.85); }
      .s-index-support .index-support-item .desc .link {
        color: #4174d6; }
    .s-index-support .index-support-item .more {
      margin-top: 20px; }
    .s-index-support .index-support-item .more-link {
      font-size: 13px;
      line-height: 1.38;
      color: #4174d6; }
    .s-index-support .index-support-item .img-icon {
      background-image: url("/static/img/sprite-index.svg");
      width: 54px;
      height: 54px; }
      .s-index-support .index-support-item .img-icon.book {
        background-position: -608px -196px; }
      .s-index-support .index-support-item .img-icon.faq {
        background-position: -672px -196px; }
      .s-index-support .index-support-item .img-icon.chat {
        background-position: -736px -196px; }
      .s-index-support .index-support-item .img-icon.hotline {
        background-position: -800px -196px; }

.s-index-steps-road {
  padding: 100px 0 80px; }
  .s-index-steps-road .title {
    margin-bottom: 80px; }
    .s-index-steps-road .title > * {
      display: inline-block;
      vertical-align: top; }
  .s-index-steps-road .more-btn {
    margin-top: 35px; }
    .s-index-steps-road .more-btn .btn {
      border-radius: 10px;
      background-color: #323c5d;
      border-color: #323c5d;
      font-size: 15px;
      font-weight: 370; }
      .s-index-steps-road .more-btn .btn .icon {
        display: inline-block;
        margin-left: 8px;
        width: 11px;
        height: 9px;
        background-image: url("/static/img/sprite-index.svg");
        background-position: -611px -488px;
        transition: 0.25s ease-in-out; }
      .s-index-steps-road .more-btn .btn:hover .icon {
        margin-left: 16px; }
  .s-index-steps-road .type-list {
    margin-bottom: 0;
    margin-left: 65px;
    padding-top: 10px; }
    .s-index-steps-road .type-list li {
      float: left;
      cursor: pointer; }
      .s-index-steps-road .type-list li:not(:last-child) {
        margin-right: 10px; }
      .s-index-steps-road .type-list li.active a, .s-index-steps-road .type-list li.active span {
        background-color: #4174d6;
        color: #ffffff;
        text-decoration: none;
        cursor: default; }
    .s-index-steps-road .type-list a, .s-index-steps-road .type-list span {
      display: block;
      border-radius: 20px;
      background-color: transparent;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 370;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.7);
      padding: 10px 15px; }
      .s-index-steps-road .type-list a:hover, .s-index-steps-road .type-list span:hover {
        text-decoration: none;
        color: #4174d6; }
  .s-index-steps-road .s-index__title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    margin-bottom: 0; }
  .s-index-steps-road .index-steps-road-item {
    min-height: 300px; }
    .s-index-steps-road .index-steps-road-item:not(.active) {
      display: none; }

.s-index-devices {
  background-color: #fbfcfd;
  padding: 80px 0 80px; }
  .s-index-devices .s-index__title {
    margin-bottom: 20px; }
  .s-index-devices .subtitle {
    max-width: 768px;
    font-size: 23px;
    line-height: 1.39;
    color: rgba(50, 60, 93, 0.7);
    text-align: center;
    margin: 0 auto; }
  .s-index-devices .index-devices {
    margin-top: 80px; }
    .s-index-devices .index-devices-item .img {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      min-height: 182px;
      font-size: 0;
      line-height: 1;
      text-decoration: none;
      margin: 0 -11.5px;
      cursor: pointer; }
      .s-index-devices .index-devices-item .img img {
        max-width: 100%;
        height: auto; }
    .s-index-devices .index-devices-item .img-icon {
      background-image: url("/static/img/sprite-index_rastr.png");
      margin: 0 auto; }
      .s-index-devices .index-devices-item .img-icon.macbook {
        width: 266px;
        height: 163px;
        background-position: -6px -364px; }
      .s-index-devices .index-devices-item .img-icon.driver {
        width: 153px;
        height: 177px;
        background-position: -390px -359px; }
      .s-index-devices .index-devices-item .img-icon.iphones {
        width: 149px;
        height: 166px;
        background-position: -720px -370px; }
      .s-index-devices .index-devices-item .img-icon.android {
        width: 148px;
        height: 178px;
        background-position: -1049px -359px; }
    .s-index-devices .index-devices-item .cnt {
      margin-top: 35px; }
    .s-index-devices .index-devices-item .type {
      display: block;
      position: relative;
      font-size: 15px;
      line-height: 1.33;
      color: #4174d6;
      text-decoration: none;
      padding-left: 38px;
      margin-bottom: 17px;
      cursor: pointer; }
      .s-index-devices .index-devices-item .type .icon {
        position: absolute;
        left: 0;
        background-image: url("/static/img/sprite-index.svg"); }
      .s-index-devices .index-devices-item .type.t-1 .icon {
        top: -3px;
        width: 24px;
        height: 24px;
        background-position: -610px -414px; }
      .s-index-devices .index-devices-item .type.t-2 .icon {
        top: -6px;
        width: 22px;
        height: 27px;
        background-position: -687px -412px; }
      .s-index-devices .index-devices-item .type.t-3 .icon {
        top: -6px;
        width: 24px;
        height: 28px;
        background-position: -724px -412px; }
      .s-index-devices .index-devices-item .type.t-4 .icon {
        top: -3px;
        width: 24px;
        height: 24px;
        background-position: -648px -414px; }
    .s-index-devices .index-devices-item .desc {
      min-height: 57px;
      font-size: 15px;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.85); }
    .s-index-devices .index-devices-item .more {
      margin-top: 20px; }
    .s-index-devices .index-devices-item .more-link {
      font-size: 13px;
      line-height: 1.38;
      color: #4174d6; }

.s-index-business {
  position: relative;
  background-size: cover;
  color: #ffffff;
  /*&::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 28, 36, 0.58);
  }*/ }
  .s-index-business.t-1 {
    background: url("/static/img/index-business/img_business@2x.png") 50% 50% no-repeat;
    padding: 100px 0 145px; }
  .s-index-business.t-2 {
    background: url("/static/img/business/business/img_b_1@2x.png") 50% 50% no-repeat;
    padding: 100px 0 55px; }
  .s-index-business .bitrix {
    width: 570px;
    text-align: center;
    margin: 0 auto; }
  .s-index-business .container {
    position: relative; }
  .s-index-business .s-index__title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    margin-bottom: 20px; }
  .s-index-business .index-business {
    max-width: 572px; }
    .s-index-business .index-business .desc {
      max-width: 474px;
      font-size: 23px;
      line-height: 1.39;
      color: rgba(255, 255, 255, 0.7); }
    .s-index-business .index-business .more {
      margin-top: 40px; }
      .s-index-business .index-business .more .btn {
        border-radius: 10px;
        background-color: #4174d6;
        border-color: #4174d6;
        font-size: 13px; }
    .s-index-business .index-business .link {
      margin-top: 10px; }
    .s-index-business .index-business .more-link {
      font-size: 13px;
      line-height: 1.38;
      color: rgba(255, 255, 255, 0.5); }
    .s-index-business .index-business-list {
      margin-top: -11px; }
    .s-index-business .index-business-item {
      margin-top: 20px; }
      .s-index-business .index-business-item .img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        overflow: hidden;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%; }
        .s-index-business .index-business-item .img img {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -12px;
          margin-top: -12px; }
      .s-index-business .index-business-item .img-icon {
        background-image: url("/static/img/sprite-index.svg"); }
        .s-index-business .index-business-item .img-icon.docs {
          width: 24px;
          height: 22px;
          background-position: -642px -451px; }
        .s-index-business .index-business-item .img-icon.timer {
          width: 24px;
          height: 24px;
          background-position: -710px -450px; }
        .s-index-business .index-business-item .img-icon.cards {
          width: 22px;
          height: 16px;
          background-position: -609px -454px; }
        .s-index-business .index-business-item .img-icon.pro {
          width: 25px;
          height: 24px;
          background-position: -675px -450px; }
      .s-index-business .index-business-item .cnt {
        max-width: 180px;
        margin-top: 15px; }
      .s-index-business .index-business-item .title {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        margin-bottom: 5px; }
        .s-index-business .index-business-item .title .pro {
          display: inline-block;
          vertical-align: top;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 0.2);
          margin-left: 5px;
          padding: 0 5px; }
      .s-index-business .index-business-item .desc {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(255, 255, 255, 0.7); }

.s-index-widgets {
  overflow: hidden;
  padding: 143px 0 62px; }
  .s-index-widgets .index-widgets {
    margin-left: -28px;
    margin-right: -28px; }
    .s-index-widgets .index-widgets__title {
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      font-weight: 700;
      line-height: 1.37;
      color: #323c5d;
      margin-bottom: 15px; }
    .s-index-widgets .index-widgets__desc {
      font-size: 17px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.7);
      margin-bottom: 30px; }
    .s-index-widgets .index-widgets-more {
      margin-top: 20px; }
    .s-index-widgets .index-widgets__link {
      font-size: 13px;
      line-height: 1.38;
      color: #4174d6; }
    .s-index-widgets .index-widgets-column {
      padding-left: 28px;
      padding-right: 28px; }
    .s-index-widgets .index-widgets-1-item {
      position: relative;
      padding-left: 34px; }
      .s-index-widgets .index-widgets-1-item:not(:last-child) {
        margin-bottom: 20px; }
      .s-index-widgets .index-widgets-1-item .img {
        position: absolute;
        left: 0;
        top: -5px; }
        .s-index-widgets .index-widgets-1-item .img img {
          vertical-align: top; }
      .s-index-widgets .index-widgets-1-item .info::after {
        display: block;
        clear: both;
        content: ""; }
      .s-index-widgets .index-widgets-1-item .info > * {
        display: inline-block;
        vertical-align: top; }
      .s-index-widgets .index-widgets-1-item .type {
        float: left; }
        .s-index-widgets .index-widgets-1-item .type > * {
          display: block; }
      .s-index-widgets .index-widgets-1-item .tariff {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: #323c5d; }
      .s-index-widgets .index-widgets-1-item .desc {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.5); }
      .s-index-widgets .index-widgets-1-item .price {
        float: right;
        font-size: 15px;
        line-height: 1.33;
        color: #323c5d; }
    .s-index-widgets .index-widgets-2-item {
      position: relative;
      border-bottom: 1px solid #d0d8e6;
      padding: 0 0 20px 55px;
      margin-bottom: 20px; }
      .s-index-widgets .index-widgets-2-item .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 8px;
        background-color: #63b463; }
        .s-index-widgets .index-widgets-2-item .img img {
          vertical-align: top; }
      .s-index-widgets .index-widgets-2-item .title {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.35;
        margin-bottom: 3px; }
        .s-index-widgets .index-widgets-2-item .title .link {
          color: #4174d6; }
      .s-index-widgets .index-widgets-2-item .desc {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.85);
        margin-bottom: 0; }
    .s-index-widgets .index-widgets-3-item {
      border-radius: 20px;
      background-color: rgba(223, 230, 244, 0.4);
      padding: 20px 20px 16px; }
      .s-index-widgets .index-widgets-3-item .head {
        position: relative;
        min-height: 40px;
        padding-left: 75px;
        margin-bottom: 20px; }
      .s-index-widgets .index-widgets-3-item .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 40px;
        background-color: #0061a9;
        border-radius: 8px;
        overflow: hidden; }
        .s-index-widgets .index-widgets-3-item .img img {
          vertical-align: top; }
      .s-index-widgets .index-widgets-3-item .title {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.35;
        margin-bottom: 3px; }
        .s-index-widgets .index-widgets-3-item .title .link {
          color: #4174d6; }
      .s-index-widgets .index-widgets-3-item .desc {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.5);
        margin-bottom: 0; }
      .s-index-widgets .index-widgets-3-item .type {
        position: relative;
        overflow: hidden;
        color: #ffffff;
        border-radius: 10px;
        padding: 15px 15px 23px; }
        .s-index-widgets .index-widgets-3-item .type::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.58); }
        .s-index-widgets .index-widgets-3-item .type .text {
          position: relative;
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 20px;
          font-weight: 600;
          line-height: 1.35;
          margin-bottom: 0; }
      .s-index-widgets .index-widgets-3-item .adv-list {
        margin-top: 15px; }
      .s-index-widgets .index-widgets-3-item .adv-item > * {
        display: block; }
      .s-index-widgets .index-widgets-3-item .adv-item .time {
        font-size: 10px;
        line-height: 1.4;
        color: rgba(50, 60, 93, 0.5);
        margin-bottom: 5px; }
      .s-index-widgets .index-widgets-3-item .adv-item .count {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 18px;
        font-weight: 600;
        line-height: 1.33;
        color: #323c5d; }

.s-index-calculator {
  background-color: rgba(0, 0, 0, 0.8); }

.s-share-form {
  position: relative;
  padding-bottom: 80px;
  min-height: 470px; }
  .s-share-form .container {
    position: relative;
    z-index: 1; }
  .s-share-form::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background: -webkit-linear-gradient(106deg, #fffbf4, #ffdfa2);
    background: -moz-linear-gradient(106deg, #fffbf4, #ffdfa2);
    background: -o-linear-gradient(106deg, #fffbf4, #ffdfa2);
    background: linear-gradient(106deg, #fffbf4, #ffdfa2); }
  .s-share-form::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 712px;
    height: 377px;
    background-image: url("/static/img/sprite-apps.png");
    background-position: -813px -103px; }
  .s-share-form .index-callback {
    padding-top: 50px; }

.share-form {
  padding: 52px 30px 24px; }
  .share-form-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 459px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.08);
    position: absolute;
    top: -70px;
    left: 15px;
    right: 15px; }
    .share-form-wrap > * {
      width: 100%; }
  .share-form .title {
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 30px;
    color: #323c5d;
    line-height: 1.38;
    margin-bottom: 15px; }
  .share-form .desc {
    font-size: 17px;
    line-height: 1.35;
    color: rgba(50, 60, 93, 0.6); }
  .share-form .form {
    margin-top: 35px; }
    .share-form .form .label {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      color: rgba(50, 60, 93, 0.7);
      line-height: 1.33;
      margin-bottom: 15px; }
    .share-form .form input::-webkit-input-placeholder {
      color: #323c5d;
      opacity: 0.3;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      height: 18px; }
  .share-form-success {
    position: relative;
    text-align: center;
    padding: 90px 30px 30px; }
    .share-form-success .close {
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 1;
      width: 32px;
      height: 32px;
      background-image: url("/static/img/ic_circ_close.svg");
      background-size: 100% 100%;
      cursor: pointer; }
    .share-form-success .icon {
      margin-bottom: 56px; }
    .share-form-success .img-icon {
      width: 114px;
      height: 114px;
      background-image: url("/static/img/sprite-index.svg");
      margin: 0 auto; }
      .share-form-success .img-icon.success {
        background-position: -723px -531px; }
      .share-form-success .img-icon.error {
        background-position: -605px -531px; }
    .share-form-success .title {
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      color: #323c5d;
      line-height: 1.37;
      margin-bottom: 15px; }
    .share-form-success .desc {
      font-size: 17px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.7);
      margin-bottom: 0; }

.s-business-start {
  position: relative;
  padding: 70px 0 80px; }
  .s-business-start .container {
    position: relative; }
  .s-business-start .bg-1 {
    position: absolute;
    right: 0;
    bottom: 120px;
    width: 660px;
    height: 458px;
    background-image: url("/static/img/sprite-bs.png");
    background-position: -4px -62px; }
  .s-business-start .bg-2 {
    position: absolute;
    left: -5%;
    top: -100%;
    right: -35%;
    bottom: 11%;
    background: -webkit-linear-gradient(-90deg, rgba(255, 223, 162, 0.36), rgba(245, 249, 255, 0.1));
    background: -moz-linear-gradient(-90deg, rgba(255, 223, 162, 0.36), rgba(245, 249, 255, 0.1));
    background: -o-linear-gradient(-90deg, rgba(255, 223, 162, 0.36), rgba(245, 249, 255, 0.1));
    background: linear-gradient(-90deg, rgba(255, 223, 162, 0.36), rgba(245, 249, 255, 0.1));
    transform: rotate(27deg); }
  .s-business-start .s-index__title {
    font-size: 47px;
    line-height: 1.36;
    margin-bottom: 20px; }
    .s-business-start .s-index__title .color {
      color: #fc921f; }
  .s-business-start .index-delivery-partners {
    margin-top: 100px; }
    .s-business-start .index-delivery-partners::after {
      display: block;
      clear: both;
      content: ""; }
    .s-business-start .index-delivery-partners .label {
      float: left;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 23px;
      font-weight: 600;
      line-height: 1.39;
      color: rgba(50, 60, 93, 0.7);
      margin-top: 7px; }
    .s-business-start .index-delivery-partners-list {
      justify-content: space-between;
      float: left;
      width: calc(100% - 175px);
      padding-left: 60px; }
      .s-business-start .index-delivery-partners-list li:not(:last-child) {
        margin-right: 15px; }
  .s-business-start .business-start__desc {
    max-width: 572px;
    font-size: 23px;
    line-height: 1.39;
    color: rgba(50, 60, 93, 0.7); }
  .s-business-start .business-start-form {
    max-width: 602px; }
    .s-business-start .business-start-form .hint-form {
      color: rgba(50, 60, 93, 0.5); }
      .s-business-start .business-start-form .hint-form .color {
        color: #fc921f; }
  .s-business-start .business-start-adv {
    max-width: 787px;
    margin-top: 44px; }
    .s-business-start .business-start-adv-item .cnt {
      margin-top: 10px; }
    .s-business-start .business-start-adv-item .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
      color: #323c5d;
      margin-bottom: 5px; }
    .s-business-start .business-start-adv-item .desc {
      max-width: 210px;
      font-size: 13px;
      line-height: 1.38;
      color: rgba(50, 60, 93, 0.7); }
    .s-business-start .business-start-adv-item .img-icon {
      background-image: url("/static/img/sprite-business.svg"); }
      .s-business-start .business-start-adv-item .img-icon.insurance {
        width: 22px;
        height: 22px;
        background-position: -1px -299px; }
      .s-business-start .business-start-adv-item .img-icon.done_list {
        width: 22px;
        height: 18px;
        background-position: -1px -346px; }
      .s-business-start .business-start-adv-item .img-icon.wallet {
        width: 23px;
        height: 20px;
        background-position: 0 -388px; }
  .s-business-start .business-start-btn {
    text-align: center;
    margin-top: 72px; }
    .s-business-start .business-start-btn .btn {
      background-color: #4174d6;
      border-color: #4174d6;
      border-radius: 10px;
      box-shadow: 0 5px 20px 0 rgba(65, 116, 214, 0.5);
      padding: 15px 44px; }

.s-business-path {
  position: relative;
  padding: 80px 0 50px; }
  .s-business-path .s-index__title {
    margin-bottom: 20px; }
  .s-business-path .business-path__desc {
    position: relative;
    max-width: 768px;
    float: right;
    font-size: 23px;
    line-height: 1.39;
    text-align: right;
    color: rgba(50, 60, 93, 0.7); }
  .s-business-path .business-path-img {
    clear: right; }
    .s-business-path .business-path-img img {
      width: auto;
      max-width: 100%;
      height: auto;
      margin-top: -65px; }
    .s-business-path .business-path-img .img-icon {
      background-image: url("/static/img/sprite-business.svg");
      background-position: -400px -12px;
      width: 1152px;
      height: 578px; }

.s-business-decision {
  padding: 100px 0 70px; }
  .s-business-decision .s-index__title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px; }
    .s-business-decision .s-index__title .color {
      color: #fc921f; }
  .s-business-decision .business-decision__desc {
    max-width: 964px;
    font-size: 23px;
    line-height: 1.39;
    text-align: center;
    color: rgba(50, 60, 93, 0.7);
    margin-left: auto;
    margin-right: auto; }
  .s-business-decision .business-decision-list {
    max-width: 950px;
    margin: 10px auto 0; }
    .s-business-decision .business-decision-list [class*="col"]:nth-child(even) .business-decision-item {
      float: right; }
  .s-business-decision .business-decision-item {
    max-width: 356px;
    margin-top: 60px; }
    .s-business-decision .business-decision-item .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      line-height: 1.37;
      color: #323c5d;
      margin-bottom: 10px; }
    .s-business-decision .business-decision-item .desc {
      font-size: 17px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.85); }
    .s-business-decision .business-decision-item .type-list {
      margin-top: 28px; }
      .s-business-decision .business-decision-item .type-list::after {
        display: block;
        clear: both;
        content: ""; }
      .s-business-decision .business-decision-item .type-list li {
        position: relative;
        padding-left: 23px;
        float: left;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.6); }
        .s-business-decision .business-decision-item .type-list li:not(:last-child)::after {
          content: "+";
          padding: 0 13px; }
        .s-business-decision .business-decision-item .type-list li .icon {
          position: absolute;
          left: 0;
          background-image: url("/static/img/sprite-business.svg"); }
        .s-business-decision .business-decision-item .type-list li.t-1 .icon {
          top: 0;
          width: 16px;
          height: 16px;
          background-position: -44px -298px; }
        .s-business-decision .business-decision-item .type-list li.t-2 .icon {
          top: 2px;
          width: 16px;
          height: 12px;
          background-position: -44px -336px; }
    .s-business-decision .business-decision-item .more {
      margin-top: 25px; }
      .s-business-decision .business-decision-item .more .link {
        font-size: 15px;
        line-height: 1.33;
        color: #4174d6; }
  .s-business-decision .business-decision-more {
    text-align: center;
    margin-top: 63px; }
    .s-business-decision .business-decision-more .link {
      font-size: 15px;
      line-height: 1.33;
      color: #4174d6; }

.s-business-category {
  padding: 120px 0 80px; }
  .s-business-category .s-index__title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px; }
    .s-business-category .s-index__title .color {
      color: #fc921f; }
  .s-business-category .business-category__desc {
    max-width: 768px;
    font-size: 23px;
    line-height: 1.39;
    text-align: center;
    color: rgba(50, 60, 93, 0.7);
    margin-left: auto;
    margin-right: auto; }
  .s-business-category .business-category-list {
    max-width: 1050px;
    margin: 50px auto 0; }
  .s-business-category .business-category-item {
    position: relative;
    margin-top: 50px;
    padding-left: 49px; }
    .s-business-category .business-category-item .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      line-height: 1.35;
      color: #323c5d;
      margin-bottom: 1px; }
    .s-business-category .business-category-item .count {
      font-size: 12px;
      line-height: 1.42;
      color: rgba(50, 60, 93, 0.6); }
    .s-business-category .business-category-item .img {
      position: absolute;
      left: 0;
      top: 1px;
      font-size: 31px;
      line-height: 1.35; }

.s-business-terminal {
  position: relative;
  padding: 120px 0 94px; }
  .s-business-terminal::before {
    content: "";
    position: absolute;
    left: -25%;
    right: -25%;
    top: 25%;
    bottom: -50%;
    transform: rotate(-20deg);
    background: -webkit-linear-gradient(0deg, #d0e0fd, #ffffff);
    background: -moz-linear-gradient(0deg, #d0e0fd, #ffffff);
    background: -o-linear-gradient(0deg, #d0e0fd, #ffffff);
    background: linear-gradient(0deg, #d0e0fd, #ffffff); }
  .s-business-terminal .container {
    position: relative; }
  .s-business-terminal .s-index__title {
    max-width: 508px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px; }
  .s-business-terminal .business-terminal__desc {
    max-width: 508px;
    font-size: 23px;
    line-height: 1.39;
    color: rgba(50, 60, 93, 0.7); }
  .s-business-terminal .business-terminal-adv {
    max-width: 400px;
    margin-top: 40px; }
    .s-business-terminal .business-terminal-adv-item .cnt {
      margin-top: 10px; }
    .s-business-terminal .business-terminal-adv-item .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
      color: #323c5d;
      margin-bottom: 5px; }
    .s-business-terminal .business-terminal-adv-item .desc {
      max-width: 180px;
      font-size: 13px;
      line-height: 1.38;
      color: rgba(50, 60, 93, 0.7); }
    .s-business-terminal .business-terminal-adv-item .img-icon {
      background-image: url("/static/img/sprite-business.svg");
      width: 54px;
      height: 54px; }
      .s-business-terminal .business-terminal-adv-item .img-icon.tarrifs {
        background-position: 0 -518px; }
      .s-business-terminal .business-terminal-adv-item .img-icon.timer {
        background-position: -74px -518px; }
  .s-business-terminal .business-terminal-btn {
    margin-top: 70px; }
    .s-business-terminal .business-terminal-btn .btn {
      background-color: #4174d6;
      border-color: #4174d6;
      border-radius: 10px;
      box-shadow: 0 5px 20px 0 rgba(65, 116, 214, 0.5);
      padding: 15px 44px; }
  .s-business-terminal .business-terminal-img {
    width: 258px;
    height: 677px;
    background-image: url("/static/img/sprite-bs.png");
    background-position: -10103px 0;
    margin: 0 auto; }

.s-business-banner {
  position: relative;
  color: #ffffff;
  padding: 47px 0 62px; }
  .s-business-banner .container {
    position: relative; }
  .s-business-banner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("/static/img/business/banner/img_city@2x.png") 50% 50% no-repeat;
    background-size: cover; }
  .s-business-banner .s-index__title {
    max-width: 572px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px; }
  .s-business-banner .business-banner__desc {
    max-width: 572px;
    font-size: 23px;
    line-height: 1.39;
    color: rgba(255, 255, 255, 0.7); }

.s-business-business .index-callback-form .hint-form {
  color: rgba(255, 255, 255, 0.8); }

.s-business-control {
  padding: 90px 0 155px; }
  .s-business-control .s-index__title {
    max-width: 572px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px; }
  .s-business-control .business-control__desc {
    max-width: 572px;
    font-size: 23px;
    line-height: 1.39;
    color: rgba(50, 60, 93, 0.7); }
  .s-business-control .business-control-cnt {
    margin-top: 20px; }
  .s-business-control .business-control-item {
    position: relative; }
  .s-business-control .business-control-img {
    position: absolute;
    right: 0;
    top: -25px;
    width: auto;
    padding-right: 0; }
    .s-business-control .business-control-img img {
      position: relative; }
    .s-business-control .business-control-img .img-abs {
      position: absolute;
      z-index: 1; }
    .s-business-control .business-control-img.slide-0 .img-abs {
      left: 155px;
      bottom: 0; }
    .s-business-control .business-control-img.slide-1 .img-abs {
      left: 80px;
      bottom: 5px; }
    .s-business-control .business-control-img.slide-2 .img-abs {
      left: 125px;
      bottom: 100px; }
    .s-business-control .business-control-img.slide-3 .img-abs {
      left: 75px;
      bottom: -5px; }
  .s-business-control .business-control-list {
    max-width: 343px;
    margin-top: 60px; }
    .s-business-control .business-control-list-item {
      position: relative;
      padding-left: 80px;
      margin-top: 30px; }
      .s-business-control .business-control-list-item .img {
        position: absolute;
        left: 0;
        top: 2px;
        width: 55px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center; }
      .s-business-control .business-control-list-item .img-icon {
        background-image: url("/static/img/sprite-business.svg"); }
        .s-business-control .business-control-list-item .img-icon.video_live {
          width: 48px;
          height: 32px;
          background-position: -3px -13px; }
        .s-business-control .business-control-list-item .img-icon.settings {
          width: 55px;
          height: 54px;
          background-position: -73px -2px; }
        .s-business-control .business-control-list-item .img-icon.computer_network {
          width: 48px;
          height: 48px;
          background-position: -151px -5px; }
        .s-business-control .business-control-list-item .img-icon.puzzle {
          width: 48px;
          height: 48px;
          background-position: -151px -79px; }
        .s-business-control .business-control-list-item .img-icon.chat {
          width: 52px;
          height: 44px;
          background-position: -75px -81px; }
        .s-business-control .business-control-list-item .img-icon.history {
          width: 48px;
          height: 48px;
          background-position: -3px -79px; }
        .s-business-control .business-control-list-item .img-icon.transp {
          width: 48px;
          height: 48px;
          background-position: -3px -153px; }
        .s-business-control .business-control-list-item .img-icon.money_deal {
          width: 48px;
          height: 48px;
          background-position: -77px -153px; }
        .s-business-control .business-control-list-item .img-icon.stat {
          width: 50px;
          height: 46px;
          background-position: -150px -155px; }
        .s-business-control .business-control-list-item .img-icon.telegram {
          width: 54px;
          height: 45px;
          background-position: 0 -229px; }
        .s-business-control .business-control-list-item .img-icon.doc {
          width: 51px;
          height: 54px;
          background-position: -75px -224px; }
        .s-business-control .business-control-list-item .img-icon.take_money {
          width: 52px;
          height: 41px;
          background-position: -149px -231px; }
      .s-business-control .business-control-list-item .title {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: #323c5d;
        margin-bottom: 5px; }
      .s-business-control .business-control-list-item .desc {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.7);
        margin-bottom: 0; }
  .s-business-control .business-control-tab-list {
    margin-bottom: 0; }
    .s-business-control .business-control-tab-list li {
      float: left; }
      .s-business-control .business-control-tab-list li:not(:last-child) {
        margin-right: 10px; }
      .s-business-control .business-control-tab-list li.active a, .s-business-control .business-control-tab-list li.active span {
        color: #fb5c3f;
        text-decoration: none;
        cursor: default; }
        .s-business-control .business-control-tab-list li.active a::after, .s-business-control .business-control-tab-list li.active span::after {
          background-color: rgba(251, 92, 63, 0.5); }
    .s-business-control .business-control-tab-list a, .s-business-control .business-control-tab-list span {
      position: relative;
      display: block;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
      color: rgba(50, 60, 93, 0.45);
      padding: 8px 20px 10px;
      cursor: pointer; }
      .s-business-control .business-control-tab-list a:hover, .s-business-control .business-control-tab-list span:hover {
        text-decoration: none;
        color: #fb5c3f; }
      .s-business-control .business-control-tab-list a::after, .s-business-control .business-control-tab-list span::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        border-radius: 1px;
        background-color: rgba(50, 60, 93, 0.05); }

.s-business-comparison {
  padding: 80px 0 120px; }
  .s-business-comparison .s-index__title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px; }
  .s-business-comparison .business-comparison__desc {
    max-width: 768px;
    font-size: 23px;
    line-height: 1.39;
    text-align: center;
    color: rgba(50, 60, 93, 0.7);
    margin-left: auto;
    margin-right: auto; }
  .s-business-comparison .business-comparison-table {
    max-width: 1050px;
    margin: 100px auto 0; }
    .s-business-comparison .business-comparison-table .table-wrapper {
      display: table;
      width: 100%; }
    .s-business-comparison .business-comparison-table .table-row {
      display: table-row; }
    .s-business-comparison .business-comparison-table .table-th,
    .s-business-comparison .business-comparison-table .table-td {
      display: table-cell;
      vertical-align: middle; }
    .s-business-comparison .business-comparison-table .table-th {
      border-bottom: 2px solid #c1c4ce;
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      line-height: 1.35;
      padding: 27px 10px 26px; }
      .s-business-comparison .business-comparison-table .table-th:nth-child(1) {
        width: 350px;
        color: rgba(50, 60, 93, 0.5);
        padding-left: 75px; }
      .s-business-comparison .business-comparison-table .table-th:nth-child(2) {
        width: 350px;
        color: #485faa;
        padding-right: 75px; }
    .s-business-comparison .business-comparison-table .table-td {
      border-bottom: 1px solid #d0d8e6;
      padding: 37px 10px 37px; }
    .s-business-comparison .business-comparison-table .desc {
      position: relative;
      max-width: 245px;
      font-size: 17px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.6);
      padding-left: 65px; }
      .s-business-comparison .business-comparison-table .desc .img {
        position: absolute;
        left: 0;
        top: 1px;
        font-size: 27px;
        line-height: 1.37;
        color: #323c5d; }
    .s-business-comparison .business-comparison-table .size {
      font-size: 37px;
      line-height: 1.38;
      color: #323c5d; }
    .s-business-comparison .business-comparison-table .img-icon {
      background-image: url("/static/img/sprite-business.svg"); }
      .s-business-comparison .business-comparison-table .img-icon.cancel {
        width: 28px;
        height: 28px;
        background-position: -90px -308px; }
      .s-business-comparison .business-comparison-table .img-icon.done {
        width: 31px;
        height: 22px;
        background-position: -89px -380px; }
      .s-business-comparison .business-comparison-table .img-icon.brand {
        width: 120px;
        height: 28px;
        background-position: 0 -450px; }

.s-404 {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 101px);
  padding: 30px 0 30px; }
  .s-404::before {
    content: "";
    position: absolute;
    left: -5%;
    top: -100%;
    right: -35%;
    bottom: 11%;
    background: -webkit-linear-gradient(-90deg, rgba(166, 227, 213, 0.36), rgba(245, 249, 255, 0.1));
    background: -moz-linear-gradient(-90deg, rgba(166, 227, 213, 0.36), rgba(245, 249, 255, 0.1));
    background: -o-linear-gradient(-90deg, rgba(166, 227, 213, 0.36), rgba(245, 249, 255, 0.1));
    background: linear-gradient(-90deg, rgba(166, 227, 213, 0.36), rgba(245, 249, 255, 0.1));
    transform: rotate(27deg); }
  .s-404 .container {
    position: relative;
    flex: 1 1 auto; }
  .s-404 .inner {
    text-align: center; }
    .s-404 .inner > * {
      display: block; }
  .s-404 .title {
    font-size: 130px;
    line-height: 1.36;
    letter-spacing: 5.2px;
    color: #a3abb7; }
  .s-404 .desc {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 25px;
    line-height: 1.36;
    color: #a3abb7; }

.s-stock.t-small {
  padding: 35px 0 50px; }

.s-stock.t-big {
  padding: 50px 0 100px; }

.s-stock__title {
  text-align: center;
  font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 37px;
  line-height: 1.38;
  color: #323c5d;
  text-decoration: none; }

.s-stock__desc {
  max-width: 964px;
  font-size: 23px;
  line-height: 1.39;
  color: rgba(50, 60, 93, 0.7);
  text-align: center;
  margin: 20px auto 52px; }

.s-stock .stock-item {
  display: block;
  height: 100%;
  border-radius: 15px;
  background-color: #ffffff;
  border: 2px solid #dfe6f4;
  text-decoration: none;
  cursor: pointer;
  transition: 0.25s ease-in-out; }
  .s-stock .stock-item .img-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    overflow: hidden;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: -2px -2px 0;
    padding: 30px 22px 15px 22px; }
    .s-stock .stock-item .img-wrapper img {
      vertical-align: top;
      width: 100%;
      object-fit: cover; }
    .s-stock .stock-item .img-wrapper .img {
      background-image: url("/static/img/sprite-promo.svg"); }
      .s-stock .stock-item .img-wrapper .img.stock-1 {
        width: 104px;
        height: 88px;
        background-position-x: -2px;
        background-position-y: -10px; }
      .s-stock .stock-item .img-wrapper .img.stock-2 {
        width: 104px;
        height: 82px;
        background-position-x: -2px;
        background-position-y: -142px; }
      .s-stock .stock-item .img-wrapper .img.stock-3 {
        width: 108px;
        height: 90px;
        background-position-x: 0;
        background-position-y: -266px; }
      .s-stock .stock-item .img-wrapper .img.stock-4 {
        width: 108px;
        height: 80px;
        background-position-x: 0;
        background-position-y: -398px; }
  .s-stock .stock-item .cnt {
    padding: 15px 10px 15px; }
  .s-stock .stock-item .title {
    display: block;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #323c5d; }
  .s-stock .stock-item .desc {
    color: rgba(50, 60, 93, 0.85);
    margin-bottom: 0; }
  .s-stock .stock-item .more-link .link {
    color: #4174d6; }
  .s-stock .stock-item.t-small:hover {
    border-color: #ffffff;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1); }
  .s-stock .stock-item.t-small.stock-1:hover .img {
    background-position: -130px -10px; }
  .s-stock .stock-item.t-small.stock-2:hover .img {
    background-position: -130px -142px; }
  .s-stock .stock-item.t-small.stock-3:hover .img {
    background-position: -127px -266px; }
  .s-stock .stock-item.t-small.stock-4:hover .img {
    background-position: -128px -398px; }
  .s-stock .stock-item.t-small .img-wrapper {
    height: 153px; }
  .s-stock .stock-item.t-small .title {
    min-height: 54px;
    font-size: 20px;
    line-height: 1.35;
    margin-bottom: 15px; }
  .s-stock .stock-item.t-small .desc {
    font-size: 15px;
    line-height: 1.33; }
  .s-stock .stock-item.t-big .img-wrapper {
    height: 163px; }
  .s-stock .stock-item.t-big .title {
    min-height: 1px;
    font-size: 25px;
    line-height: 1.36;
    margin-bottom: 15px; }
  .s-stock .stock-item.t-big .desc {
    font-size: 17px;
    line-height: 1.35; }
  .s-stock .stock-item.t-big .more-link {
    margin-top: 20px; }
    .s-stock .stock-item.t-big .more-link .link {
      font-size: 15px;
      line-height: 1.33; }

.s-stock-slider {
  position: relative;
  padding: 51px 0 35px; }
  .s-stock-slider .bg-1 {
    position: absolute;
    left: -5%;
    top: -100%;
    right: -35%;
    bottom: -50%;
    background: -webkit-linear-gradient(-90deg, rgba(166, 227, 213, 0.36), rgba(245, 249, 255, 0.1));
    background: -moz-linear-gradient(-90deg, rgba(166, 227, 213, 0.36), rgba(245, 249, 255, 0.1));
    background: -o-linear-gradient(-90deg, rgba(166, 227, 213, 0.36), rgba(245, 249, 255, 0.1));
    background: linear-gradient(-90deg, rgba(166, 227, 213, 0.36), rgba(245, 249, 255, 0.1));
    transform: rotate(27deg); }
  .s-stock-slider .stock-slider-wrapper {
    position: relative;
    height: 441px; }
    .s-stock-slider .stock-slider-wrapper[data-current="0"] .slide:nth-child(2) {
      left: 20px;
      top: 20px;
      right: 0;
      bottom: 0;
      opacity: 0.43; }
    .s-stock-slider .stock-slider-wrapper[data-current="0"] .slide:nth-child(3) {
      left: 40px;
      top: 40px;
      right: 0;
      bottom: 0;
      opacity: 0.25; }
    .s-stock-slider .stock-slider-wrapper[data-current="1"] .slide:nth-child(1) {
      left: 20px;
      top: 20px;
      right: 0;
      bottom: 0;
      opacity: 0.43; }
    .s-stock-slider .stock-slider-wrapper[data-current="1"] .slide:nth-child(3) {
      left: 40px;
      top: 40px;
      right: 0;
      bottom: 0;
      opacity: 0.25; }
    .s-stock-slider .stock-slider-wrapper[data-current="2"] .slide:nth-child(1) {
      left: 20px;
      top: 20px;
      right: 20px;
      bottom: 20px;
      opacity: 0.43; }
    .s-stock-slider .stock-slider-wrapper[data-current="2"] .slide:nth-child(2) {
      left: 40px;
      top: 40px;
      right: 0;
      bottom: 0;
      opacity: 0.25; }
  .s-stock-slider .stock-slider-slide {
    position: absolute;
    min-height: 421px;
    overflow: hidden;
    background-color: #dfe6f4;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    padding: 40px 40px 66px;
    transition: 0.25s ease-in-out; }
    .s-stock-slider .stock-slider-slide::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.35); }
    .s-stock-slider .stock-slider-slide > * {
      position: relative; }
    .s-stock-slider .stock-slider-slide.is-active {
      left: 0;
      top: 0;
      right: 20px;
      bottom: 20px;
      z-index: 1; }
    .s-stock-slider .stock-slider-slide.t-bg-1 {
      background: -webkit-linear-gradient(110deg, #fde843, #fcc91f);
      background: -moz-linear-gradient(110deg, #fde843, #fcc91f);
      background: -o-linear-gradient(110deg, #fde843, #fcc91f);
      background: linear-gradient(110deg, #fde843, #fcc91f); }
    .s-stock-slider .stock-slider-slide.t-bg-2 {
      background: -webkit-linear-gradient(110deg, #c199e8, #3e0ae8);
      background: -moz-linear-gradient(110deg, #c199e8, #3e0ae8);
      background: -o-linear-gradient(110deg, #c199e8, #3e0ae8);
      background: linear-gradient(110deg, #c199e8, #3e0ae8); }
    .s-stock-slider .stock-slider-slide.t-bg-3 {
      background: -webkit-linear-gradient(110deg, #eae6b5, #67986b);
      background: -moz-linear-gradient(110deg, #eae6b5, #67986b);
      background: -o-linear-gradient(110deg, #eae6b5, #67986b);
      background: linear-gradient(110deg, #eae6b5, #67986b); }
    .s-stock-slider .stock-slider-slide .title {
      display: block;
      max-width: 767px;
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 47px;
      line-height: 1.36;
      color: #ffffff;
      margin-bottom: 20px;
      /*.bold {
            background-color: #fb5c3f;
            border-radius: 3px;
            padding: 0 5px;
          }*/ }
    .s-stock-slider .stock-slider-slide .desc {
      max-width: 572px;
      font-size: 23px;
      line-height: 1.39;
      color: #ffffff; }
      .s-stock-slider .stock-slider-slide .desc .bold {
        border-bottom: 1px solid currentColor;
        /*background-color: #fb5c3f;
            border-radius: 3px;
            padding: 0 5px;*/ }
    .s-stock-slider .stock-slider-slide .bottom {
      margin-top: 20px; }
      .s-stock-slider .stock-slider-slide .bottom::after {
        display: block;
        clear: both;
        content: ""; }
    .s-stock-slider .stock-slider-slide .hint-bottom {
      float: left;
      max-width: 572px;
      font-size: 15px;
      line-height: 1.33;
      color: #ffffff;
      /*.bold {
            background-color: #fb5c3f;
            border-radius: 3px;
            padding: 0 5px;
          }*/ }
    .s-stock-slider .stock-slider-slide .btn-bottom {
      float: right; }
      .s-stock-slider .stock-slider-slide .btn-bottom .btn {
        min-width: 205px;
        background-color: #fb5c3f;
        border-color: #fb5c3f;
        border-radius: 10px;
        -webkit-box-shadow: 0 5px 20px 0 rgba(251, 92, 63, 0.5);
        -moz-box-shadow: 0 5px 20px 0 rgba(251, 92, 63, 0.5);
        box-shadow: 0 5px 20px 0 rgba(251, 92, 63, 0.5);
        padding: 15px 20px; }
  .s-stock-slider .stock-slider-dots {
    font-size: 0;
    line-height: 1;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0; }
    .s-stock-slider .stock-slider-dots li {
      display: inline-block;
      vertical-align: top;
      width: 8px;
      height: 8px;
      background-color: #000000;
      border-radius: 50%;
      margin: 0 4px;
      transition: 0.25s ease-in-out; }
      .s-stock-slider .stock-slider-dots li:not(.is-active) {
        opacity: 0.15;
        cursor: pointer; }

.s-stock-app {
  padding: 50px 0 165px; }
  .s-stock-app .app-list {
    font-size: 0;
    text-align: center;
    margin-bottom: 0;
    margin-top: 40px; }
    .s-stock-app .app-list li {
      display: inline-block;
      vertical-align: top;
      margin: 0 10px; }
      .s-stock-app .app-list li a {
        display: inline-block;
        vertical-align: top;
        width: 140px;
        height: 42px;
        background-image: url("/static/img/sprite-footer.svg"); }
        .s-stock-app .app-list li a.app-google {
          background-position: 0 -34px; }
        .s-stock-app .app-list li a.app-apple {
          background-position: 0 -86px; }

.s-driver-bg {
  background-color: #dfe6f4;
  background-position: 50% 50%;
  background-size: cover;
  min-height: 305px;
  margin-top: -60px; }

.s-driver-wrapper {
  position: relative;
  margin-top: -105px;
  padding-bottom: 71px; }
  .s-driver-wrapper::before {
    content: '';
    position: absolute;
    left: 0;
    top: 105px;
    right: 0;
    bottom: 0;
    background-color: #F5F7F9; }
  .s-driver-wrapper .container {
    position: relative; }
    .s-driver-wrapper .container::after {
      display: block;
      clear: both;
      content: ""; }
  .s-driver-wrapper .column-left {
    float: left;
    width: 269px;
    padding-right: 16px; }
  .s-driver-wrapper .column-right {
    float: right;
    width: 269px;
    padding-left: 16px;
    padding-top: 150px; }
  .s-driver-wrapper .column-center {
    margin: 0 269px; }
  .s-driver-wrapper .driver-img {
    width: 210px;
    height: 210px;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 5px; }
    .s-driver-wrapper .driver-img .inner {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      background-color: #dfe6f4;
      display: flex;
      text-align: center; }
      .s-driver-wrapper .driver-img .inner img {
        vertical-align: top;
        width: 100%;
        object-fit: cover; }
  .s-driver-wrapper .driver-info {
    margin-top: 35px; }
    .s-driver-wrapper .driver-info > * {
      display: block; }
    .s-driver-wrapper .driver-info .info-name {
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      line-height: 27px;
      color: #323c5d; }
    .s-driver-wrapper .driver-info .info-nikname {
      font-size: 13px;
      line-height: 18px;
      color: rgba(50, 60, 93, 0.7); }
    .s-driver-wrapper .driver-info .info-label {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: rgba(50, 60, 93, 0.5);
      margin-top: 20px;
      margin-bottom: 10px; }
    .s-driver-wrapper .driver-info .info-menu {
      margin-bottom: 0; }
      .s-driver-wrapper .driver-info .info-menu li {
        position: relative;
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.83);
        padding: 6px 6px 6px 22px; }
        .s-driver-wrapper .driver-info .info-menu li .icon {
          position: absolute;
          left: 0;
          top: 8px;
          width: 12px;
          height: 12px;
          background-size: 100% 100%; }
          .s-driver-wrapper .driver-info .info-menu li .icon.icon-1 {
            background-image: url("/static/img/page-driver/info-menu/ic_route_12.svg"); }
          .s-driver-wrapper .driver-info .info-menu li .icon.icon-2 {
            background-image: url("/static/img/page-driver/info-menu/ic_clock_12.svg"); }
          .s-driver-wrapper .driver-info .info-menu li .icon.icon-3 {
            background-image: url("/static/img/page-driver/info-menu/ic_calendar_12.svg"); }
          .s-driver-wrapper .driver-info .info-menu li .icon.icon-4 {
            background-image: url("/static/img/page-driver/info-menu/ic_wallet_12.svg"); }
        .s-driver-wrapper .driver-info .info-menu li .color {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          color: #4174d6; }
  .s-driver-wrapper .driver-box {
    border-radius: 15px;
    background-color: #ffffff; }
    .s-driver-wrapper .driver-box:not(:last-child) {
      margin-bottom: 16px; }
    .s-driver-wrapper .driver-box .header-box {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .s-driver-wrapper .driver-box .title-box {
      display: inline-block;
      vertical-align: middle;
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      color: #323c5d;
      padding-right: 15px; }
      .s-driver-wrapper .driver-box .title-box .count {
        font-family: "Open Sans Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        color: rgba(50, 60, 93, 0.5); }
    .s-driver-wrapper .driver-box.style-1 {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      .s-driver-wrapper .driver-box.style-1 .header-box {
        border-bottom: 1px solid #dfe6f4;
        padding: 30px 30px; }
      .s-driver-wrapper .driver-box.style-1 .cnt-box {
        padding: 30px 30px; }
      .s-driver-wrapper .driver-box.style-1 .title-box {
        font-size: 28px;
        line-height: 1.36; }
    .s-driver-wrapper .driver-box.style-2 .cnt-box {
      padding: 20px 20px; }
    .s-driver-wrapper .driver-box.style-2 .title-box {
      font-size: 17px;
      line-height: 1.35; }
  .s-driver-wrapper .driver-char .item > * {
    display: block; }
  .s-driver-wrapper .driver-char .item.t-big .title {
    margin-bottom: 10px; }
  .s-driver-wrapper .driver-char .item.t-big .count {
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 30px;
    line-height: 1.37; }
  .s-driver-wrapper .driver-char .item.t-small .title {
    margin-bottom: 5px; }
  .s-driver-wrapper .driver-char .item.t-small .count {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 21px;
    line-height: 1.33; }
  .s-driver-wrapper .driver-char .title {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: rgba(50, 60, 93, 0.5); }
  .s-driver-wrapper .driver-char .count {
    color: #323c5d; }
  .s-driver-wrapper .driver-stat .stat-desc {
    font-size: 15px;
    line-height: 1.33;
    color: rgba(50, 60, 93, 0.85);
    margin-bottom: 0; }
  .s-driver-wrapper .driver-stat .stat-char {
    margin-bottom: 20px; }
  .s-driver-wrapper .driver-car .car-tariff {
    display: block;
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    line-height: 1.35;
    color: #323c5d;
    margin-bottom: 22px; }
    .s-driver-wrapper .driver-car .car-tariff .color {
      color: #4174d6; }
  .s-driver-wrapper .driver-car .car-desc {
    margin-bottom: 10px; }
    .s-driver-wrapper .driver-car .car-desc .title {
      display: block;
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      line-height: 1.35;
      color: #323c5d;
      margin-bottom: 5px; }
    .s-driver-wrapper .driver-car .car-desc .desc {
      font-size: 13px;
      line-height: 1.38;
      color: rgba(50, 60, 93, 0.85);
      margin-bottom: 0; }
  .s-driver-wrapper .driver-car .car-size {
    margin: 0 -30px 26px; }
    .s-driver-wrapper .driver-car .car-size li {
      position: relative;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dfe6f4;
      padding: 15px 30px 15px 52px; }
      .s-driver-wrapper .driver-car .car-size li > * {
        display: inline-block;
        vertical-align: middle; }
      .s-driver-wrapper .driver-car .car-size li .title {
        font-size: 15px;
        line-height: 1.33;
        color: rgba(50, 60, 93, 0.7); }
      .s-driver-wrapper .driver-car .car-size li .count {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        line-height: 1.33;
        color: #323c5d; }
      .s-driver-wrapper .driver-car .car-size li .icon {
        position: absolute;
        left: 30px;
        top: 19px;
        width: 12px;
        height: 12px;
        background-size: 100% 100%; }
        .s-driver-wrapper .driver-car .car-size li .icon.icon-1 {
          background-image: url("/static/img/page-driver/size/ic_download_12.svg"); }
        .s-driver-wrapper .driver-car .car-size li .icon.icon-2 {
          background-image: url("/static/img/tariff/ic_size_length.svg"); }
        .s-driver-wrapper .driver-car .car-size li .icon.icon-3 {
          background-image: url("/static/img/tariff/ic_size_width.svg"); }
        .s-driver-wrapper .driver-car .car-size li .icon.icon-4 {
          background-image: url("/static/img/tariff/ic_size_height.svg"); }
        .s-driver-wrapper .driver-car .car-size li .icon.icon-5 {
          background-image: url("/static/img/tariff/ic_size_volume.svg"); }
  .s-driver-wrapper .driver-car .car-label {
    flex-shrink: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid rgba(65, 116, 214, 0.6);
    border-radius: 16px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    line-height: 1.38;
    color: #4174d6;
    padding: 7px 10px 7px 41px; }
    .s-driver-wrapper .driver-car .car-label .img {
      position: absolute;
      left: 10px;
      top: 9px;
      font-size: 0;
      line-height: 1; }
  .s-driver-wrapper .driver-reviews .wrapper-reviews {
    margin: -30px -30px 0; }
  .s-driver-wrapper .driver-reviews .item-reviews {
    padding: 30px 30px; }
    .s-driver-wrapper .driver-reviews .item-reviews:not(:last-child) {
      border-bottom: 1px solid #dfe6f4; }
    .s-driver-wrapper .driver-reviews .item-reviews .header-item {
      position: relative;
      min-height: 30px;
      padding-left: 40px;
      padding-right: 106px; }
      .s-driver-wrapper .driver-reviews .item-reviews .header-item > * {
        display: block; }
      .s-driver-wrapper .driver-reviews .item-reviews .header-item .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #dfe6f4;
        display: flex;
        text-align: center; }
        .s-driver-wrapper .driver-reviews .item-reviews .header-item .img img {
          vertical-align: top;
          width: 100%;
          object-fit: cover; }
      .s-driver-wrapper .driver-reviews .item-reviews .header-item .author {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .s-driver-wrapper .driver-reviews .item-reviews .header-item .car {
        font-size: 10px;
        line-height: 1.4;
        color: rgba(50, 60, 93, 0.7);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .s-driver-wrapper .driver-reviews .item-reviews .header-item .stars-list {
        position: absolute;
        right: 0;
        top: 7px;
        margin-bottom: 0; }
        .s-driver-wrapper .driver-reviews .item-reviews .header-item .stars-list::after {
          display: block;
          clear: both;
          content: ""; }
        .s-driver-wrapper .driver-reviews .item-reviews .header-item .stars-list li {
          float: left;
          font-size: 0;
          line-height: 1; }
          .s-driver-wrapper .driver-reviews .item-reviews .header-item .stars-list li:not(:last-child) {
            margin-right: 4px; }
          .s-driver-wrapper .driver-reviews .item-reviews .header-item .stars-list li .star-icon {
            display: inline-block;
            vertical-align: top;
            width: 16px;
            height: 16px;
            background-image: url("/static/img/lk/widgets/edit/ic_star_border_black_24px.svg");
            background-size: 100% 100%; }
          .s-driver-wrapper .driver-reviews .item-reviews .header-item .stars-list li.is-active .star-icon {
            background-image: url("/static/img/lk/widgets/edit/ic_star_border_black_24px_active.svg"); }
    .s-driver-wrapper .driver-reviews .item-reviews .cnt-item {
      margin-top: 11px; }
      .s-driver-wrapper .driver-reviews .item-reviews .cnt-item .desc {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.85);
        margin-bottom: 0; }
  .s-driver-wrapper .driver-reviews .reviews-add {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 13px;
    line-height: 1.38;
    color: #4174d6;
    cursor: pointer;
    padding: 7px 10px 7px 29px;
    margin-top: 5px; }
    .s-driver-wrapper .driver-reviews .reviews-add:hover {
      background-color: rgba(65, 116, 214, 0.05); }
    .s-driver-wrapper .driver-reviews .reviews-add .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/filter/ic_plus_12.svg");
      background-size: 100% 100%; }
  .s-driver-wrapper .driver-top .wrapper-top {
    margin: 10px -20px 0; }
  .s-driver-wrapper .driver-top .item-top {
    padding: 11px 20px; }
    .s-driver-wrapper .driver-top .item-top .cnt-item {
      position: relative;
      min-height: 36px;
      padding: 2px 33px 0 46px; }
      .s-driver-wrapper .driver-top .item-top .cnt-item > * {
        display: block; }
      .s-driver-wrapper .driver-top .item-top .cnt-item .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #dfe6f4;
        display: flex;
        text-align: center; }
        .s-driver-wrapper .driver-top .item-top .cnt-item .img img {
          vertical-align: top;
          width: 100%;
          object-fit: cover; }
      .s-driver-wrapper .driver-top .item-top .cnt-item .author {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        line-height: 18px;
        color: #323c5d;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .s-driver-wrapper .driver-top .item-top .cnt-item .car {
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.3px;
        color: rgba(50, 60, 93, 0.5);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .s-driver-wrapper .driver-top .item-top .cnt-item .count {
        position: absolute;
        right: 0;
        top: 3px;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 21px;
        line-height: 28px;
        color: #323c5d; }
  .s-driver-wrapper .driver-slider {
    margin: -30px -30px 30px; }
    .s-driver-wrapper .driver-slider img {
      width: 100%;
      max-width: 100%;
      height: auto; }
  .s-driver-wrapper .driver-position .label-position {
    display: block;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: rgba(50, 60, 93, 0.5); }
  .s-driver-wrapper .driver-position .count-position {
    display: block;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 25px;
    line-height: 1.36;
    color: #323c5d; }
  .s-driver-wrapper .driver-position .position-rank {
    margin-top: 14px; }
    .s-driver-wrapper .driver-position .position-rank > [class*="col-"] {
      padding-left: 0;
      padding-right: 0; }
    .s-driver-wrapper .driver-position .position-rank .item {
      text-align: center; }
    .s-driver-wrapper .driver-position .position-rank .icon {
      display: inline-block;
      vertical-align: top;
      width: 15px;
      height: 20px;
      background-size: 100% 100%; }
      .s-driver-wrapper .driver-position .position-rank .icon.icon-1 {
        background-image: url("/static/img/page-driver/position/position-1.svg"); }
      .s-driver-wrapper .driver-position .position-rank .icon.icon-2 {
        background-image: url("/static/img/page-driver/position/position-2.svg"); }
      .s-driver-wrapper .driver-position .position-rank .icon.icon-3 {
        background-image: url("/static/img/page-driver/position/position-3.svg"); }
    .s-driver-wrapper .driver-position .position-rank .desc {
      display: block;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 10px;
      line-height: 1.2;
      color: #323c5d;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  .s-driver-wrapper .driver-sum .sum-tariff {
    flex-shrink: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #dfe6f4;
    border-radius: 20px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    line-height: 1.33;
    color: #323c5d;
    cursor: pointer;
    padding: 10px 37px 10px 21px; }
    .s-driver-wrapper .driver-sum .sum-tariff .icon {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -5px;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/ic_chevron_right_12.svg");
      background-size: 100% 100%; }
    .s-driver-wrapper .driver-sum .sum-tariff-wrapper {
      position: relative; }
  .s-driver-wrapper .driver-sum .sum-path {
    border-bottom: 1px solid #dfe6f4;
    padding: 30px 0 30px; }
    .s-driver-wrapper .driver-sum .sum-path-wrapper {
      margin-top: -30px; }
    .s-driver-wrapper .driver-sum .sum-path .label {
      position: relative;
      display: block;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 18px;
      line-height: 1.33;
      color: #323c5d;
      padding-left: 34px;
      margin-bottom: 20px; }
    .s-driver-wrapper .driver-sum .sum-path .icon {
      position: absolute;
      left: 0;
      top: -1px;
      width: 24px;
      height: 24px;
      background-size: 100% 100%; }
      .s-driver-wrapper .driver-sum .sum-path .icon.icon-start {
        background-image: url("/static/img/lk/info/ic_route_A_16.svg"); }
      .s-driver-wrapper .driver-sum .sum-path .icon.icon-finish {
        background-image: url("/static/img/lk/info/ic_route_B_16.svg"); }
    .s-driver-wrapper .driver-sum .sum-path .adress-selector {
      width: 100%;
      background-color: #f5f8fc;
      border-color: #f5f8fc; }
      .s-driver-wrapper .driver-sum .sum-path .adress-selector .wrap-inp::after {
        background: -moz-linear-gradient(left, rgba(245, 248, 252, 0.01), #f5f8fc 100%);
        background: -webkit-linear-gradient(left, rgba(245, 248, 252, 0.01), #f5f8fc 100%);
        background: -o-linear-gradient(left, rgba(245, 248, 252, 0.01), #f5f8fc 100%);
        background: -ms-linear-gradient(left, rgba(245, 248, 252, 0.01), #f5f8fc 100%);
        background: linear-gradient(to right, rgba(245, 248, 252, 0.01), #f5f8fc 100%); }
  .s-driver-wrapper .driver-sum .form-btn {
    text-align: right;
    margin-top: 30px; }
    .s-driver-wrapper .driver-sum .form-btn .btn {
      border-radius: 6px;
      background-color: #4174d6;
      border-color: #4174d6;
      box-shadow: 0 5px 20px 0 rgba(65, 116, 214, 0.45);
      font-size: 11px; }
  .s-driver-wrapper .driver-sum .sum-lift {
    flex-shrink: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 40px;
    background-color: #f5f8fc;
    border: 1px solid #f5f8fc;
    border-radius: 5px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    color: rgba(50, 60, 93, 0.3);
    cursor: pointer;
    padding: 7px 42px 9px 15px; }
    .s-driver-wrapper .driver-sum .sum-lift .arrow {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -5px;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/ic_chevron_right_12.svg");
      background-size: 100% 100%; }
    .s-driver-wrapper .driver-sum .sum-lift-wrapper {
      position: relative; }

.hint {
  position: relative;
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px 20px; }
  .hint.hidden {
    display: none; }
  .hint.info {
    background-color: rgba(99, 180, 99, 0.2);
    color: #323c5d; }
    .hint.info .icon {
      background-image: url("/static/img/sprite-calc.svg");
      background-position: -160px -46px;
      opacity: 0.5; }
  .hint.error {
    background-color: #fb5c3f;
    color: #FFFFFF; }
    .hint.error .icon {
      background-image: url("/static/img/sprite-calc.svg");
      background-position: -160px -46px; }
  .hint .icon {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
    cursor: pointer;
    width: 8px;
    height: 8px; }
  .hint__title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 17px;
    font-weight: 600; }
  .hint__desc {
    font-size: 13px;
    opacity: 0.7; }

body.ReactModal__Body--open {
  padding-right: 17px; }
  body.ReactModal__Body--open .header_big.fixed {
    padding-right: 17px; }

.orderBox .ReactModal__Overlay {
  overflow-y: auto; }

.orderBox .ReactModal__Content {
  min-height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto; }

.orderBox .ReactModal__Overlay {
  z-index: 10000; }

.orderBox .ReactModal__Content {
  overflow: hidden; }
  .orderBox .ReactModal__Content.t-static {
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    border-radius: 20px;
    width: 808px;
    height: auto !important;
    margin-left: auto;
    margin-right: auto; }
    .orderBox .ReactModal__Content.t-static:not(:last-child) {
      margin-bottom: 50px; }
  .orderBox .ReactModal__Content .сalculator-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 759px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.16); }
    .orderBox .ReactModal__Content .сalculator-wrapper .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffffad;
      z-index: 500; }
    .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header {
      color: #323C5D;
      padding: 36px 40px 19px 30px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header::after {
        display: block;
        clear: both;
        content: ""; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .column-item > * {
        display: inline-block;
        vertical-align: middle; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .column-item:nth-child(1) {
        float: right; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .column-item:nth-child(2) {
        float: right;
        text-align: right; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .title {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 23px;
        font-weight: 700;
        line-height: 1.39;
        color: #323c5d; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .drop {
        position: relative;
        top: 1px;
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        font-weight: 700;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.7);
        cursor: pointer;
        margin-right: 26px;
        padding-left: 42px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .drop .arrow {
          position: absolute;
          left: 0;
          top: -8px;
          width: 32px;
          height: 32px;
          background-color: #4174d6;
          border-radius: 50%; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .drop .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 17px;
          height: 8px;
          background-color: #ffffff;
          margin-left: -8.5px;
          margin-top: -4px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .buttons .btn {
        border-radius: 6px;
        font-size: 11px;
        padding: 7px 13px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .buttons .btn:not(:last-child) {
          margin-right: 14px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-header .buttons .btn .icon {
          position: relative;
          top: 1px;
          display: inline-block;
          width: 11px;
          height: 11px;
          background-image: url("/static/img/ic_info.svg");
          background-size: 100% 100%;
          margin-right: 5px; }
    .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-wrapper > * {
      height: 100%; }
    .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process {
      text-align: center;
      padding: 19px 30px 30px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .max-width {
        margin: 0 auto; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .max-width.t-1 {
          max-width: 494px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .max-width.t-2 {
          max-width: 572px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .cnt > * {
        display: block; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .cnt .pic {
        background-repeat: no-repeat;
        background-image: url("/static/img/search-modal.svg");
        margin-top: 50px;
        margin-bottom: 30px;
        width: 548px;
        height: 174px;
        position: relative;
        left: -16px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .cnt .title {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 30px;
        font-weight: 700;
        line-height: 1.39;
        letter-spacing: 0.5px;
        color: #323c5d;
        margin-bottom: 3px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .cnt .count {
        font-size: 17px;
        line-height: 1.41;
        letter-spacing: 0.2px;
        color: #8898a3; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .cnt .desc-title {
        margin-top: 20px;
        font-size: 23px;
        color: #323C5DB3; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .cnt .desc {
        font-size: 17px;
        line-height: 1.39;
        color: rgba(50, 60, 93, 0.7);
        margin-top: 25px;
        min-height: 108px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .cnt .scheme-link {
        margin-top: 30px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .cnt .scheme-link .link {
          font-size: 15px;
          line-height: 1.33;
          color: #4174d6; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .controls {
        display: flex;
        justify-content: space-around; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .controls button {
          width: 180px;
          height: 40px;
          cursor: pointer;
          border-radius: 6px;
          border: 1px #4174D6 solid;
          font-weight: bold;
          font-size: 13px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .controls button:focus {
            outline: 0; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .controls .questions-btn {
          background: #4174D600;
          color: #4174D6; }
          .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .controls .questions-btn:hover {
            background: #4174D6;
            color: white; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .controls .private-btn {
          background: #4174D6;
          color: white; }
          .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-process .controls .private-btn:hover {
            background: #4174D600;
            color: #4174D6; }
    .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success {
      text-align: center;
      padding: 30px 30px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success .max-width {
        max-width: 474px;
        margin: 0 auto; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success .cnt > * {
        display: block; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success .cnt .title {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 23px;
        font-weight: 700;
        line-height: 1.39;
        letter-spacing: 0.5px;
        color: #323c5d;
        margin-bottom: 3px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success .cnt .count {
        font-size: 17px;
        line-height: 1.41;
        letter-spacing: 0.2px;
        color: #8898a3; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success .cnt .number-order {
        font-size: 23px;
        line-height: 1.39;
        color: #323c5d;
        margin-top: 30px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success .cnt .desc {
        font-size: 17px;
        line-height: 1.35;
        color: rgba(50, 60, 93, 0.7);
        margin-top: 15px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success .cnt .buttons {
        margin-top: 79px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-success .cnt .buttons .btn {
          background-color: #323c5d;
          border-color: #323c5d; }
    .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-check {
      position: relative;
      border-radius: 50%; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-check.t-big {
        width: 128px;
        height: 128px;
        background-color: #fc921f;
        margin: 0 auto 30px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-check.t-big .time {
          font-size: 17px;
          line-height: 1.41;
          letter-spacing: 0.2px;
          padding: 0 15px;
          margin-top: -12px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-check.t-small {
        width: 48px;
        height: 48px;
        background-color: #fc921f;
        margin: 0 auto 10px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-check.t-small .time {
          font-size: 13px;
          line-height: 1.85;
          letter-spacing: 0.1px;
          padding: 0 5px;
          margin-top: -12px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-check.t-success {
        height: 208px;
        background-image: url("/static/img/finish.svg");
        background-size: 100% 100%;
        margin: 0 auto 30px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-check .time {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        text-align: center;
        color: #323c5d; }
    .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form {
      margin-top: 40px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .desc {
        font-size: 17px;
        line-height: 1.35;
        color: rgba(50, 60, 93, 0.7);
        margin-bottom: 20px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .input {
        position: relative;
        max-width: 376px;
        margin: 0 auto; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .input:not(.active) .icon {
          display: none; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .input:not(.active) .form-control {
          padding: 12px 162px 12px 15px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .input.active .form-control {
          padding: 12px 60px 12px 49px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .input .icon {
          position: absolute;
          left: 18px;
          top: 50%;
          width: 18px;
          height: 18px;
          background-image: url("/static/img/ic-call.svg");
          background-size: 100% 100%;
          margin-top: -9px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .input .form-control {
          border-color: #dfe6f4;
          border-radius: 10px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .input .edit {
          position: absolute;
          right: 20px;
          top: 16px;
          font-size: 13px;
          line-height: 1.38;
          color: rgba(50, 60, 93, 0.5);
          cursor: pointer; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .input .code {
          position: absolute;
          right: 20px;
          top: 16px;
          font-size: 13px;
          line-height: 1.38;
          color: rgba(50, 60, 93, 0.5); }
      .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .buttons {
        margin-top: 15px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .calculator-order-form .buttons .btn {
          border-radius: 6px; }
    .orderBox .ReactModal__Content .сalculator-wrapper .сalculator-header {
      height: 71px;
      color: #323C5D;
      padding: 19px 27px 20px 30px;
      border-bottom: solid 1px #dfe6f4; }
      .orderBox .ReactModal__Content .сalculator-wrapper .сalculator-header .row {
        margin-left: -15px;
        margin-right: -15px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .сalculator-header .title {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 23px;
        font-weight: 600;
        line-height: 1.39;
        color: #323c5d; }
        .orderBox .ReactModal__Content .сalculator-wrapper .сalculator-header .title .open-calendar {
          position: relative;
          color: #4174D6;
          cursor: pointer;
          border-bottom: 1px solid #4174D6;
          display: inline;
          /*
            &:before {
                background-image: url('/static/img/icon-calendar.svg');
                background-repeat: no-repeat;
                background-position-y: center;
                height: 24px;
                width: 24px;
                content:"";
                display: inline-block;
                position: absolute;
                left: 10px;
                top: 4px;
            }
            */ }
        .orderBox .ReactModal__Content .сalculator-wrapper .сalculator-header .title .open-calendar-icon {
          position: relative;
          padding-left: 40px;
          color: #4174D6;
          cursor: pointer; }
          .orderBox .ReactModal__Content .сalculator-wrapper .сalculator-header .title .open-calendar-icon:before {
            background-image: url("/static/img/sprite-calc.svg");
            background-position: -34px -69px;
            height: 22px;
            width: 23px;
            content: "";
            display: inline-block;
            position: absolute;
            left: 10px;
            top: 5px; }
      .orderBox .ReactModal__Content .сalculator-wrapper .сalculator-header .switch-pro {
        border-radius: 5px;
        border: 1px solid #989DAD;
        float: right;
        font-size: 11px;
        font-weight: 400;
        height: 30px;
        width: 100px;
        padding: 6px 5px 5px 5px;
        text-align: center;
        color: #989DAD;
        cursor: pointer;
        margin-top: 4px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .сalculator-header .switch-pro:hover {
          text-decoration: none; }
    .orderBox .ReactModal__Content .сalculator-wrapper .row {
      margin: 0; }
      .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left {
        padding: 0;
        width: 312px;
        height: 707px;
        background-color: #fafbfc;
        display: inline-block; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item {
          padding-left: 33px;
          padding-top: 20px;
          padding-bottom: 20px;
          position: relative;
          cursor: pointer; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 {
            cursor: default; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.empty:not(.item-6) .row .title-wrap {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-weight: 600; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.active {
            border-left: 3px solid #fc921f;
            padding-left: 30px;
            background-color: #FFFFFF;
            cursor: default; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.active:after {
              left: 50px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.active.empty:not(.item-1) .row .title-wrap {
              opacity: 1;
              color: #4174d6; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item .row {
            flex-wrap: nowrap; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item .row .icon {
              flex-shrink: 0; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item .title-wrap {
            flex: 1;
            padding-right: 18px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item:not(.empty) .full .row .title-wrap {
            padding-left: 20px;
            padding-top: 1px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item:not(.empty) .full .row .title-wrap .prefix {
              font-size: 10px;
              display: block;
              color: rgba(21, 35, 82, 0.5);
              text-transform: uppercase;
              letter-spacing: 1px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item:not(.empty) .full .row .title-wrap .type {
              display: block;
              position: relative;
              font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              font-size: 15px;
              font-weight: 600;
              color: #323c5d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1.active:after {
            left: 49px;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1.active .row .icon.end {
            background-color: #FFFFFF; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1.active .row.end .title-wrap {
            color: #4174d6; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1.active.empty:after {
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1:not(.empty) {
            padding-bottom: 10px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1.old .adress-full:after {
            border-left: 2px solid #fbc68d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1:after {
            content: '';
            border-left: 2px dashed #dfe6f4;
            position: absolute;
            left: 52px;
            bottom: 0;
            width: 5px;
            height: 30px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row .icon {
            width: 40px;
            height: 40px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position-y: center; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row .icon.start {
              background-image: url("/static/img/icon-step1-start.svg");
              margin-bottom: 20px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row .icon.start:before {
                content: '';
                border-left: 2px solid #fbc68d;
                position: absolute;
                left: 19px;
                top: 40px;
                width: 5px;
                height: 20px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row .icon.end {
              margin-top: 20px;
              background-color: #f6f8fb;
              z-index: 2; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row .icon.end:before {
                content: '';
                border-left: 2px solid #fbc68d;
                position: absolute;
                left: 19px;
                top: -20px;
                width: 5px;
                height: 20px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row .icon.end.state1 {
                background-image: url("/static/img/icon-step1-end-state1.svg"); }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row .icon.end.state2 {
                background-image: url("/static/img/icon-step1-end-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row.start .title-wrap {
            padding-left: 20px;
            padding-top: 6px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row.end .title-wrap {
            padding-left: 20px;
            padding-top: 26px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .row .title-wrap {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 20px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #323c5d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-empty {
            position: relative; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-empty:after {
              content: '';
              border-left: 2px dashed #dfe6f4;
              position: absolute;
              left: 19px;
              bottom: 0;
              width: 5px;
              height: 8px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full {
            position: relative; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full:after {
              content: '';
              border-left: 2px dashed #dfe6f4;
              position: absolute;
              left: 19px;
              bottom: 0;
              width: 5px;
              height: 8px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row.start .title-wrap {
              padding-top: 0;
              width: 230px;
              overflow: hidden; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row.end .title-wrap {
              padding-top: 15px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row .title-wrap .prefix {
              font-size: 11px;
              display: block;
              color: rgba(21, 35, 82, 0.5);
              text-transform: uppercase;
              margin-bottom: 3px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row .title-wrap .adress {
              font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              font-size: 15px;
              font-weight: 600;
              color: #323c5d;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 200px;
              overflow: hidden;
              display: inline-block;
              position: relative; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row .title-wrap .adress::after {
                content: '';
                /* Выводим элемент */
                position: absolute;
                /* Абсолютное позиционирование */
                right: 0;
                top: 0;
                /* Положение элемента */
                width: 40px;
                /* Ширина градиента*/
                height: 100%;
                /* Высота родителя */
                /* Градиент */
                background: -moz-linear-gradient(left, rgba(246, 248, 251, 0.2), #f6f8fb 100%);
                background: -webkit-linear-gradient(left, rgba(246, 248, 251, 0.2), #f6f8fb 100%);
                background: -o-linear-gradient(left, rgba(246, 248, 251, 0.2), #f6f8fb 100%);
                background: -ms-linear-gradient(left, rgba(246, 248, 251, 0.2), #f6f8fb 100%);
                background: linear-gradient(to right, rgba(246, 248, 251, 0.2), #f6f8fb 100%); }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row .title-wrap .city {
              font-size: 10px;
              display: block;
              color: rgba(21, 35, 82, 0.5);
              margin-top: -4px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row.eta .divider {
              border-left: 2px solid #fbc68d;
              width: 40px;
              height: inherit;
              margin-left: 19px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row.eta .eta-wrap {
              padding-left: 10px;
              padding-top: 10px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row.eta .eta-wrap .prefix {
                font-size: 11px;
                display: block;
                color: rgba(21, 35, 82, 0.5); }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row.eta .eta-wrap .value {
                font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                font-size: 11px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4174D6; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1 .adress-full .row.eta .eta-wrap .more-adreess {
                border-radius: 5px;
                border: solid 1px #dfe6f4;
                opacity: 0.7;
                font-size: 11px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #323c5d;
                padding: 3px 7px;
                margin-bottom: 9px;
                width: 104px;
                text-align: center; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-1.active .adress-full .row .title-wrap .adress::after {
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
            background: -o-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
            background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
            background: linear-gradient(to right, rgba(255, 255, 255, 0.2), #ffffff 100%); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2:before {
            content: '';
            border-left: 2px dashed #dfe6f4;
            position: absolute;
            left: 52px;
            top: 0;
            width: 5px;
            height: 30px;
            z-index: 3; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2:after {
            content: '';
            position: absolute;
            left: 52px;
            bottom: 0;
            width: 5px;
            height: 20px;
            z-index: 3;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2 .row .icon {
            width: 40px;
            height: 40px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position-y: center;
            z-index: 3; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2.active:before {
            left: 49px;
            border-left: 2px solid #fbc68d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2.active:after {
            left: 49px;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2.active.empty:after {
            left: 49px;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2.active .cargo-empty .row .icon {
            background-color: #FFFFFF;
            background-image: url("/static/img/icon-step2-state1.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2.active .cargo-full .row .icon {
            background-image: url("/static/img/icon-step2-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2 .cargo-full .row .icon {
            background-image: url("/static/img/icon-step2-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2 .cargo-empty .row .icon {
            background-image: url("/static/img/icon-step2-state0.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2 .cargo-empty .row .title-wrap {
            padding-left: 20px;
            opacity: 0.38;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 20px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #323c5d;
            padding-top: 6px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2 .cargo-full .title-wrap .type {
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 200px;
            overflow: hidden;
            display: inline-block;
            position: relative; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2 .cargo-full .title-wrap .type::after {
              content: '';
              /* Выводим элемент */
              position: absolute;
              /* Абсолютное позиционирование */
              right: 0;
              top: 0;
              /* Положение элемента */
              width: 40px;
              /* Ширина градиента*/
              height: 100%;
              /* Высота родителя */
              /* Градиент */
              background: -moz-linear-gradient(left, rgba(246, 248, 251, 0.2), #f6f8fb 100%);
              background: -webkit-linear-gradient(left, rgba(246, 248, 251, 0.2), #f6f8fb 100%);
              background: -o-linear-gradient(left, rgba(246, 248, 251, 0.2), #f6f8fb 100%);
              background: -ms-linear-gradient(left, rgba(246, 248, 251, 0.2), #f6f8fb 100%);
              background: linear-gradient(to right, rgba(246, 248, 251, 0.2), #f6f8fb 100%); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-2.active .cargo-full .row .title-wrap .type::after {
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
            background: -o-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
            background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
            background: linear-gradient(to right, rgba(255, 255, 255, 0.2), #ffffff 100%); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3:before {
            content: '';
            border-left: 2px dashed #dfe6f4;
            position: absolute;
            left: 52px;
            top: 0;
            width: 5px;
            height: 30px;
            z-index: 3; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3:after {
            content: '';
            position: absolute;
            left: 52px;
            bottom: 0;
            width: 5px;
            height: 20px;
            z-index: 3;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3 .row .icon {
            width: 40px;
            height: 40px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position-y: center;
            background-color: #f6f8fb;
            z-index: 3; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3.active:before {
            left: 49px;
            border-left: 2px solid #fbc68d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3.active:after {
            left: 49px;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3.active.empty:after {
            left: 49px;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3.active .loaders-empty .row .icon {
            background-color: #FFFFFF;
            background-image: url("/static/img/icon-step3-state1.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3.active .loaders-full .row .icon {
            background-image: url("/static/img/icon-step3-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3 .loaders-empty .row .icon {
            background-image: url("/static/img/icon-step3-state0.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3 .loaders-full .row .icon {
            background-image: url("/static/img/icon-step3-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3 .loaders-empty .row .title-wrap {
            padding-left: 20px;
            opacity: 0.38;
            font-size: 20px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #323c5d;
            padding-top: 6px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-3 .loaders-full .row .title-wrap .count {
            position: absolute;
            right: 0;
            top: 3px;
            font-size: 13px;
            color: #4174d6; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4:before {
            content: '';
            border-left: 2px dashed #dfe6f4;
            position: absolute;
            left: 52px;
            top: 0;
            width: 5px;
            height: 30px;
            z-index: 3; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4:after {
            content: '';
            position: absolute;
            left: 52px;
            bottom: 0;
            width: 5px;
            height: 45px;
            z-index: 2;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .row .icon {
            width: 40px;
            height: 40px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position-y: center;
            background-color: #f6f8fb;
            z-index: 3; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4.active:before {
            left: 49px;
            border-left: 2px solid #fbc68d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4.active:after {
            left: 49px;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4.active.empty:after {
            left: 49px;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4.active.empty:after {
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4.active .tariff-empty .row .icon {
            background-color: #FFFFFF;
            background-image: url("/static/img/icon-step4-state1.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4.active .tariff-full .row .icon {
            background-image: url("/static/img/icon-step4-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-empty .row .icon {
            background-image: url("/static/img/icon-step4-state0.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-full .row .icon {
            background-image: url("/static/img/icon-step4-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-empty .row .title-wrap {
            position: relative;
            padding-left: 20px;
            font-size: 20px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(50, 60, 93, 0.38);
            padding-top: 6px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-empty .row .title-wrap .title {
              font-size: 20px;
              font-weight: 400; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-empty .row .title-wrap .desc {
              position: absolute;
              left: 30px;
              z-index: 1;
              opacity: 1;
              max-width: 170px;
              font-size: 11px;
              color: rgba(50, 60, 93, 0.5);
              line-height: 1.2;
              margin-top: 2px;
              margin-bottom: 0; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-empty .row .title-wrap .desc .name {
                color: #4174d6; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-full .row .title-wrap {
            position: relative; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-full .row .title-wrap .count {
              position: absolute;
              right: 0;
              top: 3px;
              font-size: 13px;
              color: #4174d6; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-4 .tariff-full .row .title-wrap .char {
              z-index: 1;
              display: block;
              max-width: 185px;
              font-size: 11px;
              color: #323c5d;
              opacity: 0.5;
              line-height: 1.36;
              margin-top: 3px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5:before {
            content: '';
            border-left: 2px dashed #dfe6f4;
            position: absolute;
            left: 52px;
            top: 0;
            width: 5px;
            height: 30px;
            z-index: 3; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5 .row .icon {
            width: 40px;
            height: 40px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position-y: center;
            background-color: #f6f8fb;
            z-index: 3; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5.active:before {
            left: 49px;
            border-left: 2px solid #fbc68d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5.active:after {
            left: 49px;
            border-left: 2px dashed #dfe6f4; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5.active .payment-empty .row .icon {
            background-color: #FFFFFF;
            background-image: url("/static/img/icon-step5-state1.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5.active .payment-full .row .icon {
            background-image: url("/static/img/icon-step5-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5 .payment-empty .row .icon {
            background-image: url("/static/img/icon-step5-state0.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5 .payment-full .row .icon {
            background-image: url("/static/img/icon-step5-state2.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-5 .payment-empty .row .title-wrap {
            padding-left: 20px;
            opacity: 0.38;
            font-size: 20px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #323c5d;
            padding-top: 6px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .info::after,
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .info::after {
            display: block;
            clear: both;
            content: ""; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .info > *,
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .info > * {
            display: inline-block;
            vertical-align: top; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .price,
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .price {
            float: right;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 25px;
            color: #323c5d;
            line-height: 1.36; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .price .als-rubl,
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .price .als-rubl {
              font-size: 26px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .price .count,
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .price .count {
              font-size: 15px;
              color: rgba(50, 60, 93, 0.5); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .left-info,
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .left-info {
            float: left; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .time,
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .time {
            display: block;
            position: relative;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 15px;
            color: #323c5d;
            line-height: 1.33;
            padding-left: 18px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .time span,
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .time span {
              font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .time .icon,
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .time .icon {
              position: absolute;
              left: 0;
              top: 4px;
              display: inline-block;
              width: 12px;
              height: 12px;
              background-image: url("/static/img/sprite-calc.svg");
              background-position: -44px -102px;
              cursor: pointer; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .distance,
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .distance {
            font-size: 13px;
            line-height: 1.38;
            color: rgba(50, 60, 93, 0.7);
            margin-top: 2px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .btn-submit,
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .btn-submit {
            margin-top: 21px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .btn-submit .btn,
            .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .btn-submit .btn {
              font-size: 15px;
              border-radius: 10px;
              box-shadow: 0 5px 20px 0 rgba(252, 146, 31, 0.45);
              padding: 10px 15px 10px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-empty .row .title-wrap .btn-submit .btn:hover,
              .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.item-6 .build-full .row .title-wrap .btn-submit .btn:hover {
                background-color: #fb5c3f;
                border-color: #fb5c3f;
                color: #ffffff; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.old::before, .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.old::after {
            border-left: 2px solid #fbc68d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .сalculator-left .Сalculator-sideBar-item.active .row .icon {
            background-color: #ffffff; }
      .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner {
        padding: 0;
        width: calc(100% - 312px);
        height: 707px;
        display: inline-block;
        overflow-y: auto; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content {
          display: none;
          padding: 20px 40px 40px 40px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content.active {
            display: block; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content h2.title {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 23px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #323c5d;
            margin: 0 0 20px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content h3.sub-title {
            font-size: 17px;
            font-weight: bold;
            color: #6a718a; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content h3.sub-title:nth-child(0) {
            margin-bottom: -12px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content h3.sub-title {
            margin: 35px 0 0 0; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-wrap {
            border-radius: 8px;
            background-color: #fafbfc;
            border: solid 1px #dfe6f4;
            overflow: hidden;
            margin-top: 20px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item {
            position: relative; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item:not(:last-child) {
              border-bottom: 1px solid rgba(223, 230, 244, 0.5); }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item.active .check-head {
              background-color: transparent;
              cursor: default; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item.active .check-head .check {
                border-color: #4174d6; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item.active .check-head .check::before {
                  opacity: 1; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item.active .check-head .type {
                color: #323c5d; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item.active .check-head .type .icon {
                  display: inline-block; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item.active .check-head .time,
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item.active .check-head .price {
                display: none; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-item:not(.active) .check-head {
              cursor: pointer; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head {
            position: relative;
            min-height: 48px;
            background-color: #ffffff;
            line-height: 24px;
            padding: 12px 15px 12px 41px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head:first-child {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head:last-child {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .check {
              position: absolute;
              left: 15px;
              top: 17px;
              width: 16px;
              height: 16px;
              background-color: rgba(255, 255, 255, 0);
              border: solid 1px rgba(50, 60, 93, 0.38);
              border-radius: 50%; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .check::before {
                content: "";
                position: absolute;
                left: 3px;
                top: 3px;
                width: 8px;
                height: 8px;
                opacity: 0;
                background-color: #4174d6;
                border-radius: 50%; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .info {
              position: relative; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .info > *:not(.img) {
                display: inline-block;
                vertical-align: top; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .type {
              padding-right: 10px;
              font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              font-size: 15px;
              font-weight: 600;
              color: rgba(50, 60, 93, 0.5); }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .type .icon {
                display: none;
                width: 11px;
                height: 11px;
                background-image: url("/static/img/ic_info.svg");
                background-size: 100% 100%; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .time {
              position: relative;
              top: 2px;
              font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              font-size: 10px;
              font-weight: 600;
              line-height: 1.4;
              color: rgba(50, 60, 93, 0.7);
              padding: 3px 8px;
              border-radius: 4px;
              -webkit-backdrop-filter: blur(30px);
              backdrop-filter: blur(30px);
              background-color: rgba(208, 216, 230, 0.2); }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .time .icon {
                position: relative;
                top: 2px;
                display: inline-block;
                width: 12px;
                height: 12px;
                background-image: url("/static/img/sprite-calc.svg");
                background-position: -44px -102px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .price {
              position: absolute;
              right: 0;
              top: 0;
              font-size: 15px;
              color: #323c5d; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .price .als-rubl {
                font-size: 14px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-head .price .count {
                font-size: 11px;
                color: rgba(50, 60, 93, 0.5); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-info::after {
            display: block;
            clear: both;
            content: ""; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-info > * {
            display: inline-block;
            vertical-align: top; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-price {
            float: right;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 28px;
            color: #323c5d;
            line-height: 1.36; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-price .count {
              font-size: 15px;
              color: rgba(50, 60, 93, 0.5); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-time {
            float: left;
            display: block;
            position: relative;
            min-height: 24px;
            font-size: 10px;
            font-weight: 400;
            color: rgba(50, 60, 93, 0.4);
            line-height: 1.3;
            margin-top: 7px;
            padding-left: 30px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-time span {
              font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              font-weight: 600;
              color: rgba(50, 60, 93, 0.55); }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .check-time .icon {
              position: absolute;
              left: 0;
              top: 1px;
              display: inline-block;
              width: 22px;
              height: 22px;
              background-image: url("/static/img/sprite-calc.svg");
              background-position: -1px -69px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice {
            border: solid 1px #dfe6f4;
            border-radius: 8px;
            margin-left: 0;
            margin-right: 0; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item {
              position: relative;
              padding-left: 0;
              padding-right: 0; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item:first-child .box {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item:first-child .box::before {
                  border-top-left-radius: 8px;
                  border-bottom-left-radius: 8px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item:last-child .box {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item:last-child .box::before {
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item .box {
                padding: 9px 10px;
                font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                font-size: 13px;
                font-weight: 600;
                line-height: 1.38;
                color: rgba(60, 78, 90, 0.5);
                text-align: center; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item .box::before {
                  content: "";
                  position: absolute;
                  left: -1px;
                  top: -1px;
                  right: -1px;
                  bottom: -1px; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item .box .title {
                  position: relative; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item:not(:last-child) {
                border-right: 1px solid #dfe6f4; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item input[type="checkbox"], .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item input[type="radio"] {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item input[type="checkbox"]:checked ~ .box, .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item input[type="radio"]:checked ~ .box {
                color: #FFFFFF; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item input[type="checkbox"]:checked ~ .box::before, .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item input[type="radio"]:checked ~ .box::before {
                  background-color: #4174D6; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .calculator-content .radio-choice-item.is-disabled::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                width: 100%;
                height: 100%; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points:first-child {
          margin-top: 55px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point {
          margin-top: 50px;
          position: relative; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-wrap {
            display: block;
            margin-top: 25px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-wrap .label-point {
              position: relative;
              display: inline-block;
              vertical-align: top;
              margin-bottom: 15px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-wrap .label-point.t-drop label {
                cursor: pointer; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-wrap .label-point label {
                position: relative;
                font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                font-size: 15px;
                font-weight: 600;
                line-height: 1.33;
                color: rgba(50, 60, 93, 0.85);
                margin-bottom: 0;
                padding-right: 15px; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-wrap .label-point label .icon {
                  position: absolute;
                  right: 0;
                  top: 50%;
                  width: 6px;
                  height: 6px;
                  border-style: solid;
                  border-width: 1px;
                  border-color: transparent #4174d6 #4174d6 transparent;
                  transform: rotate(45deg);
                  margin-top: -4px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-wrap .tags {
              margin-top: 18px;
              position: relative; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-wrap .tags .tag {
                position: relative;
                height: 20px;
                border-radius: 4px;
                background-color: #ffffff;
                border: solid 1px #4174d6;
                padding: 3px 8px;
                font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                font-size: 10px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4174d6;
                width: max-content;
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-wrap .tags .tag.filled {
                  background-color: #d9e3f7;
                  border-color: #d9e3f7;
                  color: #4174d6; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-input-wrap {
            position: relative; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-input-wrap .geo-icon {
              position: absolute;
              right: 8px;
              top: 50%;
              margin-top: -8px;
              z-index: 1;
              width: 16px;
              height: 16px;
              background-image: url("/static/img/sprite-calc.svg");
              background-position: 0 -42px;
              cursor: pointer; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .point-input-wrap.t-geo .adress-selector .delete {
              right: 10px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .deletePoint {
          position: relative;
          padding-left: 25px;
          padding-top: 8px;
          display: inline-block;
          font-size: 11px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4174d6;
          cursor: pointer;
          float: right; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .addPoint {
          position: relative;
          padding-left: 25px;
          margin-top: 23px;
          width: 120px;
          display: block;
          font-size: 11px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4174d6;
          cursor: pointer; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .points .addPoint:before {
            content: '';
            width: 12px;
            height: 12px;
            position: absolute;
            left: 0;
            top: 2px;
            cursor: pointer;
            background-image: url("/static/img/sprite-calc.svg");
            background-position: 0 -102px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step1 .map-container {
          width: 100%;
          height: 231px;
          overflow: hidden;
          border-radius: 10px;
          margin-top: 30px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-wrap {
          margin-top: 25px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-wrap.t-services {
            margin-left: -8px;
            margin-right: -8px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-wrap.t-services::after {
              display: block;
              clear: both;
              content: ""; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-service {
          background-color: #fafbfc;
          border: solid 1px #dfe6f4;
          border-radius: 10px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item {
          background-color: #fafbfc;
          border: solid 1px #dfe6f4;
          border-radius: 10px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings {
            padding: 15px 15px 15px;
            position: relative; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings > input[type="checkbox"] {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings > input[type="checkbox"]:checked ~ .controls .switch {
              background-image: url("/static/img/switch-on.svg"); }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings .qb-logo {
              position: absolute;
              right: 9px;
              top: 0;
              width: 54px;
              height: 54px;
              background: url("/static/img/qb/logo.svg"); }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings .note {
              color: #323c5d7f;
              font-size: 12px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings .controls {
              margin-top: 50px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings .controls .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings .controls .row .price {
                  font-size: 15px;
                  font-weight: bold; }
                  .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings .controls .row .price .period {
                    font-size: 12px;
                    color: #323c5d7f;
                    font-weight: normal; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .storage__settings .controls .row .control .switch {
                  position: relative;
                  width: 48px;
                  height: 24px;
                  background-image: url("/static/img/switch-off.svg");
                  background-size: 100% 100%; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item:hover .cargo-item__delete {
            display: inline-block; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item:not(:last-child) {
            margin-bottom: 15px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__settings {
            padding: 15px 15px 15px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__title {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 17px;
            font-weight: 600;
            color: #323c5d;
            display: inline-block;
            margin-right: 60px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__delete {
            display: none;
            font-size: 11px;
            color: #4174d6;
            cursor: pointer;
            float: right; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment {
            position: relative;
            background-color: #ffffff;
            border-top: solid 1px #dfe6f4;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment.t-1 {
              padding: 18px 54px 18px 15px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment.t-1 .cargo-item__add-photo {
                right: 15px;
                top: 16px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment.t-2 {
              padding: 15px 15px 15px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment.t-2 .cargo-item__add-photo {
                right: 15px;
                top: 50%;
                margin-top: -12px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment .desc {
              font-size: 13px;
              color: #323c5d;
              opacity: 0.3; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment .photo-list {
              margin: 3px -5px 0;
              padding-right: 54px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment .photo-list::after {
                display: block;
                clear: both;
                content: ""; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment .photo-item {
              position: relative;
              float: left;
              overflow: hidden;
              width: 50px;
              height: 30px;
              border-radius: 5px;
              background-color: #c4c4c4;
              margin: 10px 5px 0; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment .photo-item img {
                vertical-align: top; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment .photo-item .delete {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
                width: 11px;
                height: 11px;
                background-color: #fb5c3f;
                border-radius: 50%;
                cursor: pointer; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment .photo-item .delete::before {
                  content: "";
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  width: 6px;
                  height: 1px;
                  background-color: #ffffff;
                  border-radius: 1px;
                  margin-left: -3px;
                  margin-top: -1px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment input[type="text"],
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment textarea {
              display: block;
              width: 100%;
              height: 19px;
              min-height: 19px;
              max-height: 95px;
              background-color: transparent;
              border: 0;
              font-size: 13px;
              color: #323c5d;
              padding: 0 34px 0 0; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment input[type="text"]:focus,
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__comment textarea:focus {
                outline: none; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__add-photo {
            position: absolute;
            z-index: 1;
            width: 24px;
            height: 24px;
            overflow: hidden;
            cursor: pointer; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__add-photo input[type="file"] {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__add-photo .imgPreview {
              display: none; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item__add-photo .icon {
              position: absolute;
              left: 1px;
              top: 3px;
              width: 22px;
              height: 18px;
              background-image: url("/static/img/sprite-calc.svg");
              background-position: -137px -71px;
              background-color: transparent;
              border: 0;
              box-shadow: none;
              text-indent: -9999px;
              font-size: 0;
              color: transparent;
              padding: 0; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-item .goods-selector {
            width: 100%; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-select {
          position: relative;
          border-radius: 5px;
          background-color: #ffffff;
          border: solid 1px #dfe6f4;
          padding: 10px 32px 11px 15px;
          margin-top: 5px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-select .title {
            display: block;
            font-size: 13px;
            color: #323c5d;
            opacity: 0.3;
            line-height: normal; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-tags {
          margin-top: 10px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-tags .tag {
            display: inline-block;
            cursor: pointer;
            font-size: 11px;
            color: #4174d6; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo-tags .tag:not(:last-child) {
              margin-right: 15px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo__add-item {
          position: relative;
          display: block;
          font-size: 11px;
          color: #4174d6;
          cursor: pointer;
          padding-left: 17px;
          margin-top: 31px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step2 .cargo__add-item::before {
            content: "";
            position: absolute;
            left: 0;
            top: 2px;
            width: 12px;
            height: 12px;
            background-image: url("/static/img/sprite-calc.svg");
            background-position: 0 -102px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-info {
          padding: 5px 15px 20px 41px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-info .desc {
            font-size: 11px;
            color: rgba(50, 60, 93, 0.85); }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-price {
          border-top: 1px solid rgba(223, 230, 244, 0.5); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-price .price-list {
            padding: 5px 0 15px;
            margin-bottom: 0; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-price .price-list li {
              padding: 5px 15px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-price .price-list li::after {
                display: block;
                clear: both;
                content: ""; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-price .price-list li > * {
                display: inline-block;
                vertical-align: top; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-price .price-list li .title {
                float: left;
                font-size: 13px;
                color: rgba(50, 60, 93, 0.5); }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .porter-price .price-list li .price {
                float: right;
                font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                font-size: 13px;
                font-weight: 600;
                color: #323c5d; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .check-info {
          margin-top: 11px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .check-item.active .check-cnt {
          display: block; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .check-item .check-cnt {
          display: none; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-wrap {
          margin-top: 25px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-wrap.t-services {
            margin-left: -8px;
            margin-right: -8px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-wrap.t-services::after {
              display: block;
              clear: both;
              content: ""; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-services {
          margin-top: 30px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-services__label {
            margin-bottom: 15px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-services__label .label {
              font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              font-size: 15px;
              font-weight: 600;
              color: #323c5d;
              opacity: 0.7;
              margin-bottom: 0; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-services__label .icon {
              display: inline-block;
              width: 11px;
              height: 11px;
              background-image: url("/static/img/tooltip-help-icon.svg");
              background-size: 100% 100%;
              cursor: pointer;
              margin-left: 2px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service {
          position: relative;
          float: left;
          width: calc(50% - 16px);
          background-color: #ffffff;
          border: solid 1px #dfe6f4;
          border-radius: 5px;
          overflow: hidden;
          margin: 0 8px 8px;
          padding: 10px 15px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service input[type="checkbox"] {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service__title {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 15px;
            font-weight: 600;
            color: #323c5d;
            opacity: 0.7; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service__desc {
            font-size: 10px;
            color: #323c5d;
            opacity: 0.4;
            height: 60px;
            display: block; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service__info {
            position: relative;
            line-height: 24px;
            margin-top: 9px;
            opacity: 0.6; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service__info .price {
              display: inline-block;
              vertical-align: top;
              font-size: 15px;
              color: #323c5d; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service__info .price .als-rubl {
                font-size: 14px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service__info .price .count {
                font-size: 11px;
                color: rgba(50, 60, 93, 0.5); }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service__info .icon {
              position: absolute;
              right: 0;
              top: 1px;
              width: 48px;
              height: 24px;
              background-image: url("/static/img/switch-off.svg");
              background-size: 100% 100%; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service input[type="checkbox"]:checked ~ .cargo-service__title {
            opacity: 1; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service input[type="checkbox"]:checked ~ .cargo-service__desc {
            opacity: 1; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service input[type="checkbox"]:checked ~ .cargo-service__info {
            opacity: 1; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step3 .cargo-service input[type="checkbox"]:checked ~ .cargo-service__info .icon {
              background-image: url("/static/img/switch-on.svg"); }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-settings {
          border-top: 1px solid rgba(223, 230, 244, 0.5);
          padding: 15px 15px 21px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-settings .label-choice {
            display: block;
            font-size: 15px;
            line-height: 1.33;
            color: #323c5d;
            margin: 0 0 5px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-size-wrap {
          margin-top: 14px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-size-item {
          justify-content: space-between;
          margin-left: -10px;
          margin-right: -10px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-size-car {
          width: auto;
          flex-basis: initial;
          flex-grow: initial;
          padding-left: 10px;
          padding-right: 10px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-size-car > * {
            display: block; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-size-car .title {
            font-size: 13px;
            color: rgba(50, 60, 93, 0.5); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-size-car .size {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 13px;
            font-weight: 600;
            color: #323c5d; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .tariff-size-car .size.edit {
              color: #4174d6; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-item.active .check-head .type .count {
          color: #4174d6; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-item.active .check-head .info {
          padding-left: 0; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-item.active .check-head .img {
          display: none; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-item.active .check-cnt {
          display: block; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-head .info {
          padding-left: 34px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-head .img {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -6px;
          height: 14px;
          font-size: 0;
          line-height: 1;
          display: flex;
          align-items: center; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-head .img-icon {
          background-image: url("/static/img/sprite-calc.svg"); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-head .img-icon.mini2 {
            width: 20px;
            height: 11px;
            background-position: -206px -77px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-head .img-icon.standart2 {
            width: 22px;
            height: 12px;
            background-position: -239px -76px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-head .img-icon.extra2 {
            width: 24px;
            height: 14px;
            background-position: -272px -74px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-head .img-icon.maxi2 {
            width: 24px;
            height: 13px;
            background-position: -306px -75px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-cnt {
          display: none; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .check-info {
          border-top: 1px solid rgba(223, 230, 244, 0.5);
          padding: 15px 15px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step4 .widget-tariff-car {
          padding: 0 15px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-wrap {
          margin-top: 20px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-row:not(:last-child) {
          border-bottom: 1px solid #dfe6f4;
          padding-bottom: 15px;
          margin-bottom: 15px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-item:not(:last-child) {
          margin-bottom: 15px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment__label {
          font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 15px;
          font-weight: 600;
          color: rgba(50, 60, 93, 0.85);
          margin-bottom: 15px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control {
          position: relative; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control:not(:last-child) {
            margin-bottom: 15px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control .form-control {
            border-radius: 5px;
            border-color: #dfe6f4;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-weight: 600;
            color: #323c5d;
            padding: 12px 15px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control .form-control.is-invalid {
              border-color: #fb5c3f; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control .form-control:focus {
              border-color: #80bdff; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control .form-control:disabled, .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control .form-control[readonly] {
              background-color: #ffffff; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code .form-control {
            padding-right: 130px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code .sum {
            position: absolute;
            right: 15px;
            top: 13px;
            color: #323c5d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code .promocode {
            position: absolute;
            right: 15px;
            top: 16px;
            font-size: 15px;
            line-height: 1.33;
            color: #323c5d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-btn .form-control {
            padding-right: 130px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-btn .wrap-btns {
            display: inline-block;
            width: 218px;
            float: right;
            position: relative; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-btn .code-btn {
            position: absolute;
            right: 15px;
            top: 8.5px;
            z-index: 1;
            cursor: pointer;
            border-radius: 5px;
            background-color: rgba(65, 116, 214, 0);
            border: solid 1px transparent;
            font-size: 13px;
            color: #4174d6;
            text-align: center;
            padding: 6px 9px 6px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-btn .code-btn:hover {
              border-color: #4174d6; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-btn .code-btn:focus {
              background-color: #e2eaf9;
              border-color: #e2eaf9; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-btn .timer-code {
            display: block;
            opacity: 0.5;
            font-size: 13px;
            line-height: 1.38;
            color: #323c5d;
            text-align: right;
            margin: 5px 0 0; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-btn .timer-code .timer {
              font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-edit .form-control:not([type="code"]) {
            min-height: 50px;
            padding-top: 0; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-edit .icon {
            position: absolute;
            left: 13px;
            top: 14px;
            width: 24px;
            height: 22px;
            background-image: url("/static/img/sprite-calc.svg");
            background-position: -170px -69px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-edit .hint-info {
            position: absolute;
            bottom: 7px;
            left: 15px;
            right: 130px;
            font-size: 11px;
            line-height: 1.36;
            color: rgba(50, 60, 93, 0.3); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-edit.is-yes .form-control:not([type="code"]) {
            padding-left: 47px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-control.t-code-edit.is-yes .hint-info {
            left: 47px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment__add-item {
          position: relative;
          display: block;
          font-size: 11px;
          color: #4174d6;
          cursor: pointer;
          padding-left: 17px;
          margin-top: 15px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment__add-item::before {
            content: "";
            position: absolute;
            left: 0;
            top: 2px;
            width: 12px;
            height: 12px;
            background-image: url("/static/img/sprite-calc.svg");
            background-position: 0 -102px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards {
          margin-left: -15px;
          margin-right: -15px;
          margin-top: -15px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item {
            overflow: hidden;
            margin-top: 15px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .form-control {
              padding-left: 56px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .form-control:disabled, .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .form-control[readonly] {
                background-color: #ffffff; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item.t-disabled .form-control {
              cursor: default; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item.t-static .form-control {
              cursor: default;
              padding-right: 57px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item.t-static:hover .form-control {
              background-color: #f0f4fc;
              border-color: #f0f4fc; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item.t-static:hover .edit {
              right: 10px;
              opacity: 1; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item.is-yes .form-control {
              padding-left: 83px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item.is-yes .icon {
              position: absolute;
              left: 13px;
              top: 14px;
              width: 24px;
              height: 22px;
              background-image: url("/static/img/sprite-calc.svg");
              background-position: -170px -69px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item.is-yes .card-bank {
              left: 41px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .card-bank {
              position: absolute;
              left: 10px;
              top: 13px;
              width: 36px;
              height: 24px;
              border-radius: 3.5px;
              background-color: rgba(65, 116, 214, 0.2);
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: 28px 16px; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .card-bank.t-maestro {
                background-image: url("/static/img/calc/step5/Maestro.svg"); }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .card-bank.t-mastercard {
                background-image: url("/static/img/calc/step5/MasterCard.svg"); }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .card-bank.t-mircard {
                background-image: url("/static/img/calc/step5/MirCard.svg"); }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .card-bank.t-visacard {
                background-image: url("/static/img/calc/step5/VisaCard.svg"); }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .edit {
              position: absolute;
              right: -27px;
              opacity: 0;
              top: 17px;
              z-index: 1;
              transition: 0.25s ease-in-out; }
              .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .edit-item {
                float: left;
                width: 16px;
                height: 16px;
                cursor: pointer;
                font-size: 0;
                line-height: 1;
                /*&.t-ok {
                          background-image: url("/static/img/calc/step5/ic_done_16.svg");
                      }

                      &.t-del {
                          background-image: url("/static/img/calc/step5/ic_cancel_16.svg");
                      }*/ }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .edit-item:not(:last-child) {
                  margin-right: 5px; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .edit-item:hover .icon-page {
                  opacity: 1; }
                .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .edit-item .icon-page {
                  opacity: 0.3;
                  transition: 0.25s ease-in-out; }
                  .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .edit-item .icon-page .cls-1 {
                    fill: none; }
                  .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards-item .edit-item .icon-page .cls-2 {
                    fill: #3c4e5a; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment-cards__help {
            font-size: 13px;
            line-height: 1.38;
            color: rgba(50, 60, 93, 0.5); }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step5 .payment__help {
          font-size: 13px;
          line-height: 1.38;
          color: rgba(50, 60, 93, 0.5);
          margin-top: 30px;
          margin-bottom: 0; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-wrap {
          margin-top: 20px; }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-list {
          border-top: 1px solid #dfe6f4;
          padding-top: 19px;
          margin-bottom: 0; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-list li::after {
            display: block;
            clear: both;
            content: ""; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-list li:not(:last-child) {
            margin-bottom: 20px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-list li .info {
            float: left; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-list li .info > * {
              display: block; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-list li .price {
            float: right;
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 15px;
            font-weight: 600;
            color: #323c5d;
            text-align: right; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-list li .title {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 15px;
            font-weight: 600;
            color: #323c5d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-list li .city {
            font-size: 10px;
            color: rgba(60, 78, 90, 0.5); }
        .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-sum {
          border-top: 1px solid #dfe6f4;
          margin-top: 27px;
          padding-top: 20px; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-sum::after {
            display: block;
            clear: both;
            content: ""; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-sum .info {
            float: left;
            margin-top: 3px; }
            .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-sum .info > * {
              display: block; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-sum .title {
            font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 17px;
            font-weight: 600;
            color: #323c5d; }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-sum .desc {
            font-size: 10px;
            color: rgba(60, 78, 90, 0.5); }
          .orderBox .ReactModal__Content .сalculator-wrapper .row .calculator-inner .step6 .payment-total-sum .price {
            float: right;
            font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 37px;
            font-weight: 700;
            color: #323c5d;
            line-height: 1.3; }

.calculator-order-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden; }
  .calculator-order-progress-wrap {
    position: relative;
    height: 89px; }
  .calculator-order-progress .progress-line {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: rgba(65, 116, 214, 0.13);
    animation: progress 7.5s ease-in infinite;
    -webkit-animation: progress 7.5s ease-in infinite; }
  .calculator-order-progress .title {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 30px;
    color: #323c5d;
    text-align: center;
    line-height: 89px;
    /*animation: progressTitle 3.5s ease-in infinite alternate;
      -webkit-animation: progressTitle 3.5s ease-in infinite alternate;*/ }
    .calculator-order-progress .title .count {
      color: #4174d6; }

.calculator-order-crew {
  position: fixed;
  right: 86px;
  top: 0;
  margin-top: 130px;
  width: 580px;
  min-height: 396px;
  z-index: 2;
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  background-color: #ffffff;
  overflow: hidden;
  text-align: left; }
  .calculator-order-crew.hidden {
    display: none !important; }
  .calculator-order-crew-head {
    border-bottom: 1px solid #dfe6f4;
    padding: 18px 20px 19px; }
    .calculator-order-crew-head::after {
      display: block;
      clear: both;
      content: ""; }
    .calculator-order-crew-head > * {
      display: inline-block;
      vertical-align: middle; }
    .calculator-order-crew-head .title {
      position: relative;
      top: -2px;
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 23px;
      font-weight: 700;
      line-height: 1.39;
      color: #323c5d; }
    .calculator-order-crew-head .buttons {
      float: right; }
      .calculator-order-crew-head .buttons .btn {
        border-radius: 6px;
        font-size: 11px;
        padding: 7px 13px; }
  .calculator-order-crew-cnt {
    padding: 20px 55px 14px 30px; }
    .calculator-order-crew-cnt::after {
      display: block;
      clear: both;
      content: ""; }
    .calculator-order-crew-cnt .column-item {
      float: left; }
      .calculator-order-crew-cnt .column-item:nth-child(1) {
        width: 230px; }
      .calculator-order-crew-cnt .column-item:nth-child(2) {
        width: calc(100% - 230px);
        padding-left: 64px; }
    .calculator-order-crew-cnt .car-img {
      height: 100px;
      text-align: center;
      margin-bottom: 5px; }
      .calculator-order-crew-cnt .car-img img {
        max-height: 100%;
        width: auto;
        vertical-align: top; }
    .calculator-order-crew-cnt .car-desc {
      font-size: 13px;
      line-height: 1.23;
      color: rgba(50, 60, 93, 0.6);
      margin-bottom: 0; }
  .calculator-order-crew-foot {
    border-top: 1px solid #dfe6f4;
    padding: 20px 20px 20px 30px; }
    .calculator-order-crew-foot::after {
      display: block;
      clear: both;
      content: ""; }
    .calculator-order-crew-foot .column-item > * {
      display: inline-block;
      vertical-align: middle; }
    .calculator-order-crew-foot .column-item:nth-child(1) {
      float: left; }
    .calculator-order-crew-foot .column-item:nth-child(2) {
      float: right; }
    .calculator-order-crew-foot .char-list {
      margin-bottom: 0; }
      .calculator-order-crew-foot .char-list li {
        float: left; }
        .calculator-order-crew-foot .char-list li:not(:last-child) {
          margin-right: 20px; }
        .calculator-order-crew-foot .char-list li > * {
          display: block; }
      .calculator-order-crew-foot .char-list .title {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.5); }
      .calculator-order-crew-foot .char-list .count {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        color: #323c5d; }
    .calculator-order-crew-foot .price {
      position: relative;
      top: -2px;
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 32px;
      font-weight: 700;
      line-height: 1.34;
      color: #323c5d;
      margin-right: 26px; }
    .calculator-order-crew-foot .btn {
      border-radius: 10px;
      box-shadow: 0 5px 20px 0 rgba(99, 180, 99, 0.45); }
  .calculator-order-crew-user {
    position: relative;
    min-height: 40px; }
    .calculator-order-crew-user:not(.t-success) {
      margin-bottom: 25px;
      padding-left: 55px; }
      .calculator-order-crew-user:not(.t-success) .img {
        left: 0;
        top: 0; }
    .calculator-order-crew-user.t-success {
      max-width: 280px;
      border-radius: 12px;
      background-color: #ffffff;
      border: solid 1px #d0d8e6;
      text-align: left;
      padding: 12px 15px 12px 70px;
      margin: 15px auto 0; }
      .calculator-order-crew-user.t-success .img {
        left: 15px;
        top: 10px; }
    .calculator-order-crew-user .img {
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: #d0d8e6;
      border-radius: 50%;
      overflow: hidden; }
      .calculator-order-crew-user .img img {
        vertical-align: top; }
    .calculator-order-crew-user .cnt > * {
      display: block; }
    .calculator-order-crew-user .user {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
      color: #323c5d;
      margin-bottom: 1px; }
    .calculator-order-crew-user .char {
      font-size: 11px;
      line-height: 1.36;
      color: #4174d6; }
    .calculator-order-crew-user .rating {
      display: inline-block;
      color: #fc921f;
      margin-left: 10px; }
      .calculator-order-crew-user .rating .icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        background-image: url("/static/img/ic_star.svg"); }
    .calculator-order-crew-user .number {
      font-size: 11px;
      line-height: 1.36;
      color: rgba(50, 60, 93, 0.5); }
  .calculator-order-crew-char > * {
    display: block; }
  .calculator-order-crew-char .car-name {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    font-weight: 600;
    line-height: 1.33;
    color: #323c5d; }
  .calculator-order-crew-char .type-list {
    margin-bottom: 0; }
    .calculator-order-crew-char .type-list li {
      border-radius: 4px;
      background-color: rgba(65, 116, 214, 0.2);
      float: left;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 10px;
      font-weight: 600;
      line-height: 1.4;
      color: #4174d6;
      margin-top: 10px;
      padding: 3px 5px; }
      .calculator-order-crew-char .type-list li:not(:last-child) {
        margin-right: 10px; }
      .calculator-order-crew-char .type-list li img {
        position: relative;
        top: 1px;
        vertical-align: top;
        margin-right: 5px; }
  .calculator-order-crew-char .char-list {
    max-width: 180px;
    margin-bottom: 0;
    margin-top: 18px; }
    .calculator-order-crew-char .char-list li {
      position: relative;
      padding-left: 22px; }
      .calculator-order-crew-char .char-list li::after {
        display: block;
        clear: both;
        content: ""; }
      .calculator-order-crew-char .char-list li > * {
        display: inline-block;
        vertical-align: top; }
      .calculator-order-crew-char .char-list li:not(:last-child) {
        margin-bottom: 10px; }
      .calculator-order-crew-char .char-list li .icon {
        position: absolute;
        left: 0;
        top: 2px;
        width: 12px;
        height: 12px;
        background-size: 100% 100%; }
      .calculator-order-crew-char .char-list li .type {
        float: left;
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.85); }
      .calculator-order-crew-char .char-list li .count {
        float: right;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        font-weight: 600;
        line-height: 1.38;
        color: #323c5d; }
      .calculator-order-crew-char .char-list li.t-1 .icon {
        background-image: url("/static/img/tariff/ic_size_height.svg"); }
      .calculator-order-crew-char .char-list li.t-2 .icon {
        background-image: url("/static/img/tariff/ic_size_length.svg"); }
      .calculator-order-crew-char .char-list li.t-3 .icon {
        background-image: url("/static/img/tariff/ic_size_width.svg"); }
      .calculator-order-crew-char .char-list li.t-4 .icon {
        background-image: url("/static/img/tariff/ic_size_volume.svg"); }
  .calculator-order-crew-char .char-help {
    position: relative;
    padding-left: 21px;
    margin-top: 13px; }
    .calculator-order-crew-char .char-help > * {
      display: block; }
    .calculator-order-crew-char .char-help .icon {
      position: absolute;
      left: 0;
      top: 1px;
      width: 11px;
      height: 11px;
      background-image: url("/static/img/ic_warn.svg"); }
    .calculator-order-crew-char .char-help .type {
      font-size: 11px;
      line-height: 1.36;
      color: rgba(50, 60, 93, 0.85); }
    .calculator-order-crew-char .char-help .price {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 13px;
      font-weight: 600;
      line-height: 1.38;
      color: #323c5d; }
  .calculator-order-crew-message {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 106px 40px 40px; }
    .calculator-order-crew-message > * {
      display: block; }
    .calculator-order-crew-message .title-message {
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      line-height: 1.37;
      letter-spacing: 0.6px;
      color: #323c5d;
      margin-bottom: 20px; }
    .calculator-order-crew-message .desc-message {
      max-width: 474px;
      font-size: 17px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.7);
      margin-left: auto;
      margin-right: auto; }
    .calculator-order-crew-message .loader-message {
      width: 100%;
      height: 45px;
      background: url("/static/img/preloader_rings.svg") 50% 50% no-repeat;
      margin-top: 35px; }
    .calculator-order-crew-message .btn-message {
      margin-top: 99px; }
      .calculator-order-crew-message .btn-message .btn {
        border-radius: 8px;
        margin: 0 10px; }
        .calculator-order-crew-message .btn-message .btn-dark {
          background-color: #f0f3f7;
          border-color: #f0f3f7;
          color: #323c5d; }
        .calculator-order-crew-message .btn-message .btn-primary {
          background-color: #4174d6;
          border-color: #4174d6;
          color: #ffffff; }
  .calculator-order-crew-success {
    border-bottom: 1px solid #dfe6f4;
    text-align: center;
    padding: 70px 40px 51px; }
    .calculator-order-crew-success > * {
      display: block; }
    .calculator-order-crew-success .title-message {
      font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      line-height: 1.37;
      letter-spacing: 0.6px;
      color: #323c5d;
      margin-bottom: 20px; }
    .calculator-order-crew-success .desc-message {
      max-width: 474px;
      font-size: 17px;
      line-height: 1.35;
      color: rgba(50, 60, 93, 0.7);
      margin-left: auto;
      margin-right: auto; }
    .calculator-order-crew-success .icon-message {
      display: inline-block;
      width: 36px;
      height: 36px;
      background-image: url("/static/img/lk/ic_done_36.svg");
      background-size: 100% 100%;
      vertical-align: middle;
      margin-top: -5px;
      margin-right: 4px; }

.calculator-wrapper {
  position: relative; }

.calculator-info {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #ffffff;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 30px 30px 30px; }
  .calculator-info .inner-info {
    width: 100%; }
  .calculator-info .title-info {
    font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 30px;
    line-height: 1.37;
    letter-spacing: 0.6px;
    color: #323c5d; }
  .calculator-info .desc-info {
    max-width: 474px;
    font-size: 17px;
    line-height: 1.35;
    color: rgba(50, 60, 93, 0.7);
    margin: 20px auto 0; }

.footer {
  position: relative;
  background-color: #222222;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 800px; }
  .footer .container {
    max-width: 1160px;
    margin: 0 auto;
    position: relative; }
    .footer .container.first-row {
      padding-top: 105px; }
    .footer .container .footer-title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 370;
      line-height: 1.33;
      color: #FFFFFF;
      display: block;
      text-decoration-line: none;
      padding-bottom: 20px;
      min-height: 39px; }
      .footer .container .footer-title-big {
        font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 30px;
        font-weight: 700;
        color: #FFFFFF; }
      .footer .container .footer-title:hover {
        color: #FFFFFF; }
    .footer .container .footer-adress {
      font-size: 15px;
      color: #707070;
      line-height: 1.33;
      margin-top: 22px; }
      .footer .container .footer-adress > div {
        margin-top: 16px; }
      .footer .container .footer-adress .footer-mail {
        text-decoration-line: none;
        color: #FFC658;
        display: block;
        margin-top: 16px; }
    .footer .container .footer-social {
      margin-top: 65px;
      width: 217px; }
      .footer .container .footer-social .row {
        align-items: center;
        margin-left: 0;
        margin-right: 0; }
      .footer .container .footer-social .col-sm {
        font-size: 0;
        line-height: 1;
        padding-right: 0;
        padding-left: 0; }
      .footer .container .footer-social .footer-icon {
        display: inline-block;
        margin-right: 10px;
        background-image: url("/static/img/sprite-footer.svg"); }
        .footer .container .footer-social .footer-icon.fb {
          width: 24px;
          height: 24px;
          background-position: 0 0; }
        .footer .container .footer-social .footer-icon.tw {
          width: 24px;
          height: 20px;
          background-position: -102px -2px; }
        .footer .container .footer-social .footer-icon.vk {
          width: 24px;
          height: 24px;
          background-position: -68px 0; }
        .footer .container .footer-social .footer-icon.ig {
          width: 24px;
          height: 24px;
          background-position: -34px 0; }
        .footer .container .footer-social .footer-icon.tg {
          width: 24px;
          height: 21px;
          background-position: -136px -2px; }
    .footer .container .footer-payment-info {
      font-size: 11px;
      margin-right: 30px;
      color: #707070;
      padding-bottom: 15px; }
    .footer .container .footer-link {
      font-size: 15px;
      color: #707070;
      display: inline-block;
      text-decoration-line: none;
      padding-bottom: 12px;
      cursor: pointer; }
      .footer .container .footer-link.selected {
        color: #FFFFFF; }
      .footer .container .footer-link:hover {
        color: #FFFFFF; }
    .footer .container .second-row {
      margin-top: 80px; }
    .footer .container .download-app {
      width: 140px;
      height: 42px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      background-image: url("/static/img/sprite-footer.svg");
      background-size: 220px auto; }
      .footer .container .download-app:not(:last-child) {
        margin-bottom: 20px; }
      .footer .container .download-app-google {
        background-position: 0 -34px; }
      .footer .container .download-app-apple {
        background-position: 0 -86px; }
    .footer .container .third-row {
      margin-top: 100px;
      padding-right: 15px;
      padding-left: 15px; }
      .footer .container .third-row a {
        font-size: 11px;
        display: inline-block;
        margin-right: 30px; }
      .footer .container .third-row [class*="col-"]:last-child {
        text-align: right; }
    .footer .container .four-row {
      margin-top: 20px; }
      .footer .container .four-row a {
        font-size: 11px;
        display: inline-block;
        margin-right: 30px; }
      .footer .container .four-row [class*="col-"]:last-child {
        text-align: right; }
    .footer .container .footer-cards {
      display: inline-block;
      vertical-align: top;
      margin-right: 20px; }
      .footer .container .footer-cards::after {
        display: block;
        clear: both;
        content: ""; }
      .footer .container .footer-cards li {
        width: 30px;
        height: 20px;
        background-image: url("/static/img/sprite-footer.svg");
        float: left; }
        .footer .container .footer-cards li:not(:last-child) {
          margin-right: 10px; }
        .footer .container .footer-cards li.visa {
          background-position: -150px -34px; }
        .footer .container .footer-cards li.mastercard {
          background-position: -150px -64px; }
        .footer .container .footer-cards li.mir {
          background-position: -190px -34px; }
        .footer .container .footer-cards li.uniteller {
          background-position: -190px -64px; }
        .footer .container .footer-cards li.alfa {
          width: 68px;
          height: 24px;
          background-image: url("/static/img/alfabank.svg"); }
        .footer .container .footer-cards li.info {
          font-size: 11px;
          margin-right: 30px;
          padding-bottom: 15px;
          background: none;
          width: 240px;
          color: #707070; }

.s-orders {
  position: relative;
  background-color: #fafbfc;
  padding: 15px 0 0;
  margin-top: -61px; }
  .s-orders .container-fluid {
    position: relative;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px; }

.orders-wrapper {
  padding-left: 196px;
  transition: padding-left 0.25s ease-in-out; }
  .orders-wrapper.active {
    padding-left: 40px; }

.orders-user {
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  transition-property: padding-left, width; }
  .orders-user > *:not(.t-not-pos) {
    position: relative;
    z-index: 1; }
  .orders-user::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #fafbfc; }
  .orders-user:not(.hide) {
    width: 236px;
    padding-left: 40px; }
    .orders-user:not(.hide) .orders-user-list .list {
      margin-left: -15px;
      margin-right: -16px; }
      .orders-user:not(.hide) .orders-user-list .list > li > a,
      .orders-user:not(.hide) .orders-user-list .list > li > span {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        color: #707070;
        padding: 6px 6px 6px 37px; }
        .orders-user:not(.hide) .orders-user-list .list > li > a:hover,
        .orders-user:not(.hide) .orders-user-list .list > li > span:hover {
          text-decoration: none;
          color: #323c5d; }
      .orders-user:not(.hide) .orders-user-list .list > li.active > a,
      .orders-user:not(.hide) .orders-user-list .list > li.active > span {
        background-color: #4174d6;
        color: #ffffff;
        cursor: default; }
      .orders-user:not(.hide) .orders-user-list .list > li.t-drop > a,
      .orders-user:not(.hide) .orders-user-list .list > li.t-drop > span {
        padding-right: 28px; }
        .orders-user:not(.hide) .orders-user-list .list > li.t-drop > a .arrow,
        .orders-user:not(.hide) .orders-user-list .list > li.t-drop > span .arrow {
          right: 16px; }
          .orders-user:not(.hide) .orders-user-list .list > li.t-drop > a .arrow svg .cls-2,
          .orders-user:not(.hide) .orders-user-list .list > li.t-drop > span .arrow svg .cls-2 {
            fill: #ffffff; }
      .orders-user:not(.hide) .orders-user-list .list > li.t-drop .icon svg .cls-2 {
        fill: #ffffff; }
      .orders-user:not(.hide) .orders-user-list .list > li:not(.t-drop) .icon {
        opacity: 0.5; }
      .orders-user:not(.hide) .orders-user-list .list > li .icon {
        left: 15px;
        margin-top: -7px;
        width: 12px;
        height: 12px; }
    .orders-user:not(.hide) .orders-user-logo {
      padding-left: 40px; }
      .orders-user:not(.hide) .orders-user-logo .logo {
        width: 119px; }
  .orders-user.hide {
    width: 80px;
    padding-left: 20px; }
    .orders-user.hide .orders-user-line {
      right: -40px; }
    .orders-user.hide .orders-user-info {
      width: 40px; }
      .orders-user.hide .orders-user-info .orders-btn {
        width: 0; }
    .orders-user.hide .orders-user-list {
      width: 40px; }
      .orders-user.hide .orders-user-list .list {
        width: 40px;
        margin-left: 0;
        margin-right: 0; }
        .orders-user.hide .orders-user-list .list > li > a,
        .orders-user.hide .orders-user-list .list > li > span {
          width: 40px;
          height: 29px;
          color: transparent; }
        .orders-user.hide .orders-user-list .list > li.t-drop > a .arrow,
        .orders-user.hide .orders-user-list .list > li.t-drop > span .arrow {
          right: 0;
          transform: rotate(90deg); }
          .orders-user.hide .orders-user-list .list > li.t-drop > a .arrow svg .cls-2,
          .orders-user.hide .orders-user-list .list > li.t-drop > span .arrow svg .cls-2 {
            fill: #323c5d; }
        .orders-user.hide .orders-user-list .list > li:not(.t-drop) .icon {
          opacity: 0.5; }
        .orders-user.hide .orders-user-list .list > li .icon {
          left: 8px;
          margin-top: -13px;
          width: 24px;
          height: 24px; }
    .orders-user.hide .orders-user-hide {
      display: none; }
    .orders-user.hide .orders-user-logo {
      padding-left: 20px; }
      .orders-user.hide .orders-user-logo .logo {
        width: 33px; }
  .orders-user:not(.fixed) {
    position: absolute;
    padding-top: 71px; }
    .orders-user:not(.fixed) .orders-user-line .burger {
      top: 43px; }
    .orders-user:not(.fixed) .orders-user-logo {
      padding-top: 22px; }
  .orders-user.fixed {
    position: fixed;
    padding-top: 81px; }
    .orders-user.fixed .orders-user-line .burger {
      top: 96px; }
    .orders-user.fixed .orders-user-logo {
      padding-top: 32px; }
  .orders-user-info {
    position: relative;
    border-bottom: 1px solid #dfe6f4;
    padding-bottom: 15px;
    margin-right: 16px;
    overflow: hidden; }
    .orders-user-info .inner {
      position: relative;
      min-height: 40px;
      padding-left: 50px;
      padding-right: 22px;
      transition: 0.25s ease-in-out; }
      .orders-user-info .inner .hide {
        position: absolute;
        right: 0;
        top: 0;
        width: 12px;
        height: 12px;
        background-image: url("/static/img/lk/user-list/ic_chevron_double_left_24.svg");
        background-size: 100% 100%;
        cursor: pointer;
        transition: 0.25s ease-in-out; }
    .orders-user-info .img {
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      height: 40px;
      overflow: hidden;
      background-color: #dfe6f4;
      border-radius: 50%;
      text-align: center;
      display: flex;
      /*justify-content: center;
        align-items: center;*/ }
      .orders-user-info .img img {
        vertical-align: top;
        /*width: auto;
          max-height: 100%;
          height: auto;*/
        width: 100%;
        object-fit: cover; }
    .orders-user-info .cnt {
      overflow: hidden;
      padding-top: 4px; }
      .orders-user-info .cnt > * {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis; }
    .orders-user-info .title {
      font-size: 13px;
      line-height: 18px;
      color: #323c5d; }
    .orders-user-info .position {
      font-size: 10px;
      line-height: 14px;
      color: rgba(50, 60, 93, 0.7); }
    .orders-user-info .orders-btn {
      overflow: hidden;
      margin-top: 10px; }
      .orders-user-info .orders-btn .btn-lk-add {
        background-color: #323c5d;
        border-color: #323c5d;
        border-radius: 6px;
        box-shadow: 0 5px 20px 0 rgba(50, 60, 93, 0.2);
        outline: none;
        font-size: 11px;
        padding: 7px 15px 7px; }
        .orders-user-info .orders-btn .btn-lk-add:hover {
          background-color: #4174d6;
          border-color: #4174d6; }
  .orders-user-list {
    position: relative;
    margin-right: 16px;
    margin-top: 12px; }
    .orders-user-list:not(.t-not-border) {
      border-bottom: 1px solid #dfe6f4;
      padding-bottom: 15px; }
    .orders-user-list .list {
      margin-bottom: 0;
      overflow: hidden; }
      .orders-user-list .list > li {
        /*&.t-1 {
            .icon {
              background-image: url('/static/img/lk/user-list/ic_cardstack_12_white.svg');
            }
          }

          &.t-2 {
            .icon {
              background-image: url('/static/img/lk/user-list/ic_route_12.svg');
            }
          }

          &.t-3 {
            .icon {
              background-image: url('/static/img/lk/user-list/ic_route_mid_24.svg');
            }
          }

          &.t-4 {
            .icon {
              background-image: url('/static/img/lk/user-list/ic_clock_12.svg');
            }
          }*/ }
        .orders-user-list .list > li:not(:last-child) {
          margin-bottom: 10px; }
        .orders-user-list .list > li > a,
        .orders-user-list .list > li > span {
          position: relative;
          display: block;
          font-size: 13px;
          line-height: 1.38;
          white-space: nowrap; }
          .orders-user-list .list > li > a:hover,
          .orders-user-list .list > li > span:hover {
            text-decoration: none; }
        .orders-user-list .list > li.active > a,
        .orders-user-list .list > li.active > span {
          cursor: default; }
        .orders-user-list .list > li .icon {
          position: absolute;
          top: 50%;
          background-size: 100% 100%;
          font-size: 0;
          line-height: 1; }
          .orders-user-list .list > li .icon svg {
            width: 100%;
            height: 100%; }
            .orders-user-list .list > li .icon svg .cls-1 {
              fill: none; }
            .orders-user-list .list > li .icon svg .cls-2 {
              fill: #323c5d; }
        .orders-user-list .list > li.t-drop > a .arrow,
        .orders-user-list .list > li.t-drop > span .arrow {
          position: absolute;
          top: 50%;
          margin-top: -6px;
          width: 12px;
          height: 12px;
          font-size: 0;
          line-height: 1; }
          .orders-user-list .list > li.t-drop > a .arrow svg,
          .orders-user-list .list > li.t-drop > span .arrow svg {
            width: 100%;
            height: 100%; }
            .orders-user-list .list > li.t-drop > a .arrow svg .cls-1,
            .orders-user-list .list > li.t-drop > span .arrow svg .cls-1 {
              fill: none; }
  .orders-user-hide {
    position: absolute;
    left: 40px;
    bottom: 40px;
    right: 0;
    z-index: 1;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    line-height: 1.38;
    color: #323c5d;
    padding: 6px 6px 6px 0; }
    .orders-user-hide .icon {
      position: relative;
      top: 2px;
      display: inline-block;
      vertical-align: top;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/user-list/ic_arrow_long_left_12.svg");
      background-size: 100% 100%;
      margin-right: 10px; }
  .orders-user-line {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    background-color: #dfe6f4;
    border-top-right-radius: 15px;
    color: #ffffff;
    transition: right 0.25s ease-in-out; }
    .orders-user-line .burger {
      position: absolute;
      left: 10px;
      right: 10px;
      cursor: pointer; }
      .orders-user-line .burger span {
        display: block;
        height: 2px;
        background-color: #323c5d;
        border-radius: 1px; }
        .orders-user-line .burger span:not(:last-child) {
          margin-bottom: 3px; }
  .orders-user-open {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer; }
  .orders-user-logo {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    transition: 0.25s ease-in-out;
    transition-property: padding-left, width; }
    .orders-user-logo .logo {
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      font-size: 0;
      line-height: 1;
      text-decoration: none;
      height: 28px;
      overflow: hidden;
      margin-left: 3.5px; }
      .orders-user-logo .logo .icon-page .cls-1 {
        fill: #000000; }
      .orders-user-logo .logo .icon-page .cls-2 {
        fill: #fc921f; }

.orders-col-r {
  min-height: calc(100vh - 55px);
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff; }

.orders-t {
  position: relative;
  z-index: 101;
  border-bottom: 1px solid #dfe6f4;
  padding: 20px 30px 21px; }

.orders-b {
  position: relative;
  min-height: calc(100vh - 135px);
  padding-left: 320px; }

.orders-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; }
  .orders-list::-webkit-scrollbar {
    width: 0; }
  .orders-list-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 320px;
    height: 100%;
    border-right: 1px solid #dfe6f4;
    overflow: hidden; }
  .orders-list-item {
    position: relative;
    border-bottom: 1px solid #dfe6f4;
    padding: 16px 20px 14px; }
    .orders-list-item:hover {
      background-color: #fafbfc; }
    .orders-list-item.is-active {
      background-color: #f0f3f7;
      cursor: default; }
    .orders-list-item:not(.is-active) {
      cursor: pointer; }
    .orders-list-item > .status {
      position: absolute;
      left: 8px;
      top: 20px;
      width: 8px;
      height: 8px;
      border-radius: 50%; }
      .orders-list-item > .status.t-completed {
        background-color: #63b463; }
      .orders-list-item > .status.t-accepted {
        background-color: #ffc658; }
      .orders-list-item > .status.t-canceled {
        background-color: transparent; }
      .orders-list-item > .status.t-not-found {
        background-color: #323c5d; }
    .orders-list-item .drop-icon {
      display: inline-block;
      vertical-align: top;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/info/ic_more_12.svg");
      background-size: 100% 100%;
      cursor: pointer; }
      .orders-list-item .drop-icon-wrapper {
        position: absolute;
        right: 20px;
        top: 18px;
        font-size: 0;
        line-height: 1; }
        .orders-list-item .drop-icon-wrapper.is-active {
          z-index: 2; }
    .orders-list-item .top {
      font-size: 0;
      margin-bottom: 10px; }
      .orders-list-item .top > * {
        display: inline-block;
        vertical-align: top; }
      .orders-list-item .top .time {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        line-height: 1.38;
        color: #323c5d; }
      .orders-list-item .top .number {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.5);
        margin-left: 10px; }
      .orders-list-item .top .status {
        border-radius: 12px;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 10px;
        color: #ffffff;
        line-height: 1.4;
        letter-spacing: 0.4px;
        cursor: pointer;
        padding: 2px 7px 3px 7px;
        margin-right: 5px;
        margin-top: -1px; }
        .orders-list-item .top .status.t-completed {
          background-color: #63b463; }
        .orders-list-item .top .status.t-canceled {
          background-color: #ff3333; }
    .orders-list-item .type {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #323c5d;
      margin-bottom: 7px; }
    .orders-list-item .path {
      position: relative;
      padding-left: 10px; }
      .orders-list-item .path .item {
        position: relative;
        font-size: 11px;
        line-height: 1.36;
        color: rgba(50, 60, 93, 0.7); }
        .orders-list-item .path .item:not(:last-child) {
          margin-bottom: 3px; }
        .orders-list-item .path .item::before {
          content: "";
          position: absolute;
          left: -11px;
          width: 2px;
          background-color: #d6d8de; }
        .orders-list-item .path .item:not(:first-child):not(:last-child)::before {
          top: -3px;
          bottom: -9px; }
        .orders-list-item .path .item:first-child::before {
          top: 6px;
          bottom: -9px; }
        .orders-list-item .path .item:last-child::before {
          top: 6px;
          height: 4px; }
        .orders-list-item .path .item::after {
          content: "";
          position: absolute;
          left: -13px;
          top: 4px;
          z-index: 1;
          width: 6px;
          height: 6px;
          border: 1px solid #adb1be;
          border-radius: 50%; }
        .orders-list-item .path .item.t-1::after {
          background-color: #adb1be; }
        .orders-list-item .path .item.t-2::after {
          background-color: #ffffff; }
    .orders-list-item .bottom {
      font-size: 0;
      margin-top: 11px; }
      .orders-list-item .bottom::after {
        display: block;
        clear: both;
        content: ""; }
      .orders-list-item .bottom > * {
        display: inline-block;
        vertical-align: middle; }
      .orders-list-item .bottom .car {
        display: inline-block;
        vertical-align: top;
        border-radius: 10px;
        font-size: 10px;
        line-height: 1.4;
        color: #4174d6;
        cursor: pointer;
        padding: 3px 10px 3px 0; }
        .orders-list-item .bottom .car-wrapper {
          position: relative;
          z-index: 1;
          float: left; }
          .orders-list-item .bottom .car-wrapper.is-active {
            z-index: 2; }
        .orders-list-item .bottom .car .icon {
          position: relative;
          top: -1px;
          display: inline-block;
          vertical-align: top;
          width: 24px;
          height: 13.5px;
          margin-right: 4px; }
          .orders-list-item .bottom .car .icon img {
            vertical-align: top; }
      .orders-list-item .bottom .price {
        float: right;
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        line-height: 1.33;
        color: #323c5d; }
        .orders-list-item .bottom .price .icon {
          position: relative;
          top: 4px;
          display: inline-block;
          vertical-align: top;
          width: 18px;
          height: 12px;
          margin-right: 6px; }
          .orders-list-item .bottom .price .icon img {
            vertical-align: top; }
  .orders-list-day {
    width: 100%;
    background-color: #f5f8fc;
    border-bottom: 1px solid #f5f8fc;
    font-size: 0;
    padding: 8px 20px 8px; }
    .orders-list-day::after {
      display: block;
      clear: both;
      content: ""; }
    .orders-list-day > * {
      display: inline-block;
      vertical-align: middle; }
    .orders-list-day:not(.t-fixed) {
      z-index: 1; }
    .orders-list-day.t-fixed {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3; }
    .orders-list-day-item {
      width: 100%;
      font-size: 0; }
      .orders-list-day-item::after {
        display: block;
        clear: both;
        content: ""; }
      .orders-list-day-item > * {
        display: inline-block;
        vertical-align: middle; }
      .orders-list-day-item:not(.active) {
        display: none; }
    .orders-list-day .info {
      float: left;
      font-size: 15px;
      line-height: 1.33;
      color: #323c5d;
      opacity: 0.5; }
      .orders-list-day .info .type {
        color: rgba(50, 60, 93, 0.5); }
    .orders-list-day .count {
      float: right;
      font-size: 13px;
      line-height: 1.38;
      color: rgba(50, 60, 93, 0.5);
      margin-top: 2px; }

.orders-map {
  position: relative;
  height: 240px;
  background-color: #dfe6f4; }
  .orders-map .edit-btn {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    width: 30px;
    height: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 50%; }
    .orders-map .edit-btn .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -6px;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      background-size: 100% 100%; }
    .orders-map .edit-btn.t-1 {
      right: 20px;
      top: 20px; }
      .orders-map .edit-btn.t-1 .icon {
        background-image: url("/static/img/lk/map/ic_expand_12.svg"); }
    .orders-map .edit-btn.t-2 {
      right: 20px;
      bottom: 20px; }
      .orders-map .edit-btn.t-2 .icon {
        background-image: url("/static/img/lk/map/ic_layers_12.svg"); }
    .orders-map .edit-btn.t-3 {
      right: 20px;
      bottom: 60px; }
      .orders-map .edit-btn.t-3 .icon {
        background-image: url("/static/img/lk/map/ic_dot_position_12.svg"); }

.orders-title::after {
  display: block;
  clear: both;
  content: ""; }

.orders-title > * {
  display: inline-block;
  vertical-align: middle; }

.orders-title .title {
  font-family: "Open Sans Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 28px;
  line-height: 1.36;
  color: #323c5d;
  margin-bottom: 0; }

.orders-title .type-drop {
  position: relative;
  margin-left: 16px;
  margin-top: 5px; }
  .orders-title .type-drop .inner {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #dfe6f4;
    border-radius: 20px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    line-height: 1.33;
    color: #323c5d;
    cursor: pointer;
    padding: 10px 27px 10px 15px; }
  .orders-title .type-drop .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
    width: 12px;
    height: 12px;
    background-image: url("/static/img/lk/ic_chevron_right_12.svg");
    background-size: 100% 100%; }

.orders-filter {
  position: relative;
  min-height: 32px;
  margin-bottom: -5px;
  margin-left: 77px; }
  .orders-filter .title {
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    line-height: 1.35;
    color: #323c5d; }
  .orders-filter .tags::after {
    display: block;
    clear: both;
    content: ""; }
  .orders-filter .tags .tag {
    position: relative;
    border-radius: 5px;
    background-color: #f5f8fc;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    line-height: 1.38;
    color: #323c5d;
    cursor: pointer;
    padding: 7px 32px 7px 29px; }
    .orders-filter .tags .tag:hover {
      color: #4174d6; }
    .orders-filter .tags .tag-drop {
      position: relative;
      float: left; }
      .orders-filter .tags .tag-drop:not(:last-child) {
        margin-right: 20px; }
      .orders-filter .tags .tag-drop > * {
        display: block; }
    .orders-filter .tags .tag .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      background-size: 100% 100%; }
    .orders-filter .tags .tag.t-calendar .icon {
      background-image: url("/static/img/lk/filter/ic_calendar_12.svg"); }
    .orders-filter .tags .tag.t-phone .icon {
      background-image: url("/static/img/lk/filter/ic_call_12.svg"); }
    .orders-filter .tags .tag.t-number .icon {
      background-image: url("/static/img/lk/filter/ic_seen_12.svg"); }
    .orders-filter .tags .tag.t-status .icon {
      background-image: url("/static/img/lk/filter/ic_cardstack_12.svg"); }
    .orders-filter .tags .tag .del {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/filter/ic_cancel_12.svg");
      background-size: 100% 100%;
      cursor: pointer; }
    .orders-filter .tags .tag .status {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: 6px;
      margin-bottom: -1px; }
      .orders-filter .tags .tag .status.t-1 {
        background-color: #ffc658; }
      .orders-filter .tags .tag .status.t-2 {
        background-color: #63b463; }
      .orders-filter .tags .tag .status.t-3 {
        background-color: #fb5c3f; }
  .orders-filter .tags .tag-add {
    position: relative;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 13px;
    line-height: 1.38;
    color: #4174d6;
    cursor: pointer;
    padding: 7px 10px 7px 29px; }
    .orders-filter .tags .tag-add:hover {
      background-color: rgba(65, 116, 214, 0.05); }
    .orders-filter .tags .tag-add .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/filter/ic_plus_12.svg");
      background-size: 100% 100%; }

.orders-info-top {
  background-color: #ffffff;
  padding: 30px 30px 30px; }
  .orders-info-top .top, .orders-info-top .bottom, .orders-info-top .others {
    font-size: 0; }
    .orders-info-top .top::after, .orders-info-top .bottom::after, .orders-info-top .others::after {
      display: block;
      clear: both;
      content: ""; }
    .orders-info-top .top > *, .orders-info-top .bottom > *, .orders-info-top .others > * {
      display: inline-block;
      vertical-align: middle; }
  .orders-info-top .top {
    margin-bottom: 13px; }
    .orders-info-top .top .time {
      border-bottom: 1px dashed currentColor;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      line-height: 1.35;
      color: #323c5d;
      cursor: pointer; }
      .orders-info-top .top .time-wrapper {
        position: relative; }
    .orders-info-top .top .number {
      position: relative;
      cursor: pointer;
      margin-left: 15px; }
      .orders-info-top .top .number > span {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 20px;
        line-height: 1.35;
        color: rgba(50, 60, 93, 0.5); }
    .orders-info-top .top .events {
      position: relative;
      border: 1px solid #dfe6f4;
      border-radius: 10px;
      padding: 2px 8px 2px 25px;
      cursor: pointer; }
      .orders-info-top .top .events-wrapper {
        position: relative;
        float: right;
        margin-top: 4px; }
      .orders-info-top .top .events .name {
        opacity: 0.7;
        font-size: 10px;
        line-height: 1.4;
        color: #323c5d; }
      .orders-info-top .top .events .icon {
        position: absolute;
        left: 8px;
        top: 3px;
        width: 12px;
        height: 12px;
        background-image: url("/static/img/lk/info/ic_more_12.svg");
        background-size: 100% 100%;
        cursor: pointer; }
  .orders-info-top .company {
    position: relative;
    border-radius: 10px;
    background-color: #f0f3f7;
    font-size: 10px;
    line-height: 1.4;
    color: #323c5d;
    padding: 3px 10px 3px 25px;
    margin-left: 10px; }
    .orders-info-top .company .img {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #dfe6f4;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      display: flex;
      /*justify-content: center;
          align-items: center;*/ }
      .orders-info-top .company .img img {
        vertical-align: top;
        /*width: auto;
            max-height: 100%;
            height: auto;*/
        width: 100%;
        object-fit: cover; }
  .orders-info-top .status {
    border-radius: 12px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 10px;
    color: #ffffff;
    line-height: 1.4;
    letter-spacing: 0.4px;
    cursor: pointer;
    padding: 2px 7px 3px 7px; }
    .orders-info-top .status-wrapper {
      position: relative;
      margin-left: 25px; }
    .orders-info-top .status:not([class*="t-"]) {
      background-color: #323c5d; }
    .orders-info-top .status.t-not-found {
      background-color: #323c5d; }
    .orders-info-top .status.t-completed {
      background-color: #63b463; }
    .orders-info-top .status.t-canceled {
      background-color: #ff3333; }
    .orders-info-top .status.t-accepted {
      background-color: #ffc658; }
    .orders-info-top .status .icon {
      position: relative;
      top: 2px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/info/ic_done_12.svg");
      background-size: 100% 100%;
      margin-right: 5px; }
  .orders-info-top .event {
    border: 1px solid #dfe6f4;
    border-radius: 10px;
    font-size: 10px;
    line-height: 1.4;
    color: rgba(50, 60, 93, 0.7);
    cursor: pointer;
    margin-left: 10px;
    padding: 1px 6px 2px; }
    .orders-info-top .event .icon {
      position: relative;
      top: 2px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-size: 100% 100%;
      margin-right: 5px; }
    .orders-info-top .event.t-phone .icon {
      background-image: url("/static/img/lk/info/ic_call_12.svg"); }
    .orders-info-top .event.t-message .icon {
      background-image: url("/static/img/lk/info/ic_chat_12.svg"); }
  .orders-info-top .bottom .info-l {
    float: left; }
    .orders-info-top .bottom .info-l > * {
      display: block; }
  .orders-info-top .bottom .info-r {
    position: relative;
    float: right;
    text-align: right;
    margin-left: 30px; }
    .orders-info-top .bottom .info-r > * {
      display: block; }
  .orders-info-top .bottom .title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 25px;
    line-height: 1.36;
    color: #323c5d;
    margin-bottom: 3px; }
  .orders-info-top .bottom .weight {
    font-size: 20px;
    line-height: 1.35;
    color: rgba(50, 60, 93, 0.5); }
  .orders-info-top .bottom .price {
    position: relative;
    font-size: 30px;
    line-height: 1.37;
    color: #323c5d;
    cursor: pointer;
    margin-top: 1px; }
    .orders-info-top .bottom .price .icon {
      position: relative;
      top: 12px;
      display: inline-block;
      vertical-align: top;
      width: 30px;
      height: 18px;
      margin-right: 10px; }
      .orders-info-top .bottom .price .icon img {
        vertical-align: top; }
    .orders-info-top .bottom .price span:last-child {
      position: relative; }
      .orders-info-top .bottom .price span:last-child::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 100%;
        height: 0;
        border-bottom: 1px dashed currentColor; }
  .orders-info-top .bottom .info-b {
    font-size: 13px;
    line-height: 1.38;
    color: rgba(50, 60, 93, 0.5); }
  .orders-info-top .bottom .time {
    font-size: 30px;
    line-height: 1.37;
    color: rgba(50, 60, 93, 0.5);
    margin-top: 1px; }
  .orders-info-top .bottom .number {
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #dfe6f4;
    font-size: 13px;
    line-height: 1.38;
    color: #323c5d;
    padding: 5px 8px;
    margin-top: 8px; }
  .orders-info-top .bottom .runSearch {
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #dfe6f4;
    font-size: 16px;
    line-height: 1.38;
    padding: 5px 8px;
    margin-top: 8px;
    margin-right: 10px;
    cursor: pointer; }
    .orders-info-top .bottom .runSearch:hover {
      background-color: #4174d626;
      color: #4174d6; }
    .orders-info-top .bottom .runSearch > span {
      color: #FFFFFF; }
  .orders-info-top .others {
    margin-top: 20px; }
    .orders-info-top .others .buttons {
      float: left; }
  .orders-info-top .driver {
    min-height: 40px;
    position: relative;
    border-radius: 20px;
    background-color: #f5f8fc;
    padding: 4px 45px 4px 45px;
    margin-top: 1px; }
    .orders-info-top .driver-wrapper {
      position: relative;
      float: right; }
    .orders-info-top .driver .img {
      position: absolute;
      left: 5px;
      top: 5px;
      width: 30px;
      height: 30px;
      background-color: #323c5d;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; }
      .orders-info-top .driver .img img {
        vertical-align: top;
        max-width: 100%;
        width: auto;
        max-height: 100%;
        height: auto; }
    .orders-info-top .driver .cnt > * {
      display: block; }
    .orders-info-top .driver .name {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 13px;
      line-height: 1.38;
      color: #323c5d; }
    .orders-info-top .driver .number {
      font-size: 10px;
      line-height: 1.2;
      color: rgba(50, 60, 93, 0.5); }
    .orders-info-top .driver .icon {
      position: absolute;
      right: 15px;
      top: 12px;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/info/ic_more_12.svg");
      background-size: 100% 100%;
      cursor: pointer; }

.orders-info-tabs .tabs-list {
  border-bottom: 1px solid #dfe6f4;
  padding: 0 30px;
  margin-bottom: 0; }
  .orders-info-tabs .tabs-list li {
    float: left; }
    .orders-info-tabs .tabs-list li:not(:last-child) {
      margin-right: 40px; }
    .orders-info-tabs .tabs-list li.active a, .orders-info-tabs .tabs-list li.active span {
      color: #4174d6;
      text-decoration: none;
      cursor: default; }
      .orders-info-tabs .tabs-list li.active a::after, .orders-info-tabs .tabs-list li.active span::after {
        background-color: #4174d6; }
  .orders-info-tabs .tabs-list a, .orders-info-tabs .tabs-list span {
    position: relative;
    display: block;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
    color: rgba(50, 60, 93, 0.5);
    cursor: pointer;
    padding-bottom: 9px; }
    .orders-info-tabs .tabs-list a:hover, .orders-info-tabs .tabs-list span:hover {
      text-decoration: none;
      color: #323c5d; }
    .orders-info-tabs .tabs-list a::after, .orders-info-tabs .tabs-list span::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background-color: transparent; }

.orders-info-tabs .tabs-cnt {
  min-height: 155px;
  padding-bottom: 43px; }

.orders-info-table {
  display: table;
  width: 100%; }
  .orders-info-table .table-thead {
    display: table-row; }
  .orders-info-table .table-th {
    border-bottom: 1px solid #dfe6f4;
    display: table-cell;
    vertical-align: top;
    font-size: 10px;
    line-height: 1.4;
    color: rgba(50, 60, 93, 0.5);
    text-transform: uppercase;
    padding: 10px 10px; }
    .orders-info-table .table-th:first-child {
      padding-left: 30px; }
    .orders-info-table .table-th:last-child {
      padding-right: 30px; }
  .orders-info-table .table-tr {
    display: table-row; }
    .orders-info-table .table-tr.t-border-b-none .table-td {
      border-bottom-width: 0; }
    .orders-info-table .table-tr.t-info .table-td {
      padding-bottom: 0; }
  .orders-info-table .table-td {
    border-bottom: 1px solid #dfe6f4;
    display: table-cell;
    vertical-align: top;
    padding: 20px 10px 22px; }
    .orders-info-table .table-td:first-child {
      padding-left: 30px; }
    .orders-info-table .table-td:last-child {
      padding-right: 30px; }
  .orders-info-table.t-tab-1 .table-th:nth-child(1) {
    width: 34.36019%; }
  .orders-info-table.t-tab-1 .table-th:nth-child(2) {
    width: 27.25118%; }
  .orders-info-table.t-tab-1 .table-th:nth-child(3) {
    width: 14.21801%; }
  .orders-info-table.t-tab-2 .table-th:nth-child(1) {
    width: 34.36019%; }
  .orders-info-table.t-tab-2 .table-th:nth-child(2) {
    width: 27.25118%; }
  .orders-info-table.t-tab-2 .table-th:nth-child(3) {
    width: 14.21801%; }
  .orders-info-table.t-tab-2 .table-th:last-child {
    text-align: right; }
  .orders-info-table.t-tab-2 .table-td:last-child {
    text-align: right; }
  .orders-info-table.t-tab-3 .table-td:nth-child(1) {
    width: 34.36019%; }
  .orders-info-table.t-tab-3 .table-td:nth-child(2) {
    width: 30.80569%; }
  .orders-info-table.t-tab-3 .table-td:nth-child(3) {
    width: 20.7346%; }
  .orders-info-table.t-tab-3 .table-td:nth-child(4) {
    width: 9.12322%; }
  .orders-info-table.t-tab-3 .table-td:last-child {
    text-align: right; }
  .orders-info-table.t-tab-4 .table {
    /*&-td {
            &:last-child {
              text-align: right;
            }
          }*/ }
    .orders-info-table.t-tab-4 .table-th {
      /*&:nth-child(3) {
              width: percentage(110 / 844);
            }

            &:nth-child(4) {
              width: percentage(205 / 844);
            }

            &:nth-child(5) {
              width: percentage(160 / 844);
            }

            &:nth-child(6) {
              width: percentage(143 / 844);
            }*/
      /*&:last-child {
              text-align: right;
            }*/ }
      .orders-info-table.t-tab-4 .table-th:nth-child(1) {
        width: 6.63507%; }
      .orders-info-table.t-tab-4 .table-th:nth-child(2) {
        width: 10.66351%; }
  .orders-info-table.t-tab-5 .table-td:nth-child(1) {
    width: 34.36019%; }
  .orders-info-table.t-tab-5 .table-td:nth-child(2) {
    width: 30.80569%; }
  .orders-info-table.t-tab-5 .table-td:nth-child(3) {
    width: 20.7346%; }
  .orders-info-table.t-tab-5 .table-td:nth-child(4) {
    width: 9.12322%; }
  .orders-info-table.t-tab-5 .table-td:last-child {
    text-align: right; }
  .orders-info-table.t-tab-6 .table-td:nth-child(1) {
    width: 25%; }
  .orders-info-table.t-tab-6 .table-td:nth-child(2) {
    width: 75%; }
  .orders-info-table .type-list {
    margin-bottom: 0; }
    .orders-info-table .type-list.t-1 {
      margin-top: 4px; }
      .orders-info-table .type-list.t-1 li {
        font-size: 13px;
        line-height: 1.38;
        color: rgba(50, 60, 93, 0.5); }
        .orders-info-table .type-list.t-1 li:not(:last-child) {
          margin-bottom: 22px; }
    .orders-info-table .type-list.t-2 {
      margin-top: 2px; }
      .orders-info-table .type-list.t-2 li {
        min-height: 19px;
        font-size: 15px;
        line-height: 1.33; }
        .orders-info-table .type-list.t-2 li:not(:last-child) {
          margin-bottom: 20px; }
        .orders-info-table .type-list.t-2 li.t-total {
          font-size: 17px;
          color: #323c5d; }
      .orders-info-table .type-list.t-2:not(.t-sum) li:not(.t-total) {
        color: rgba(50, 60, 93, 0.5); }
      .orders-info-table .type-list.t-2.t-sum li {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        .orders-info-table .type-list.t-2.t-sum li:not(.t-total) {
          color: rgba(50, 60, 93, 0.7); }
  .orders-info-table .type-label {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
    margin-bottom: -3px; }
    .orders-info-table .type-label:not(.t-left) {
      margin-left: 5px; }
    .orders-info-table .type-label.t-left {
      margin-right: 5px; }
    .orders-info-table .type-label.t-1 {
      background-image: url("/static/img/lk/info/ic_route_A_16.svg"); }
    .orders-info-table .type-label.t-2 {
      background-image: url("/static/img/lk/info/ic_route_point_16.svg"); }
    .orders-info-table .type-label.t-3 {
      background-image: url("/static/img/lk/info/ic_route_B_16.svg"); }
  .orders-info-table .path {
    display: inline-block;
    position: relative;
    padding-left: 26px; }
    .orders-info-table .path::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 16px;
      height: 16px;
      background-size: 100% 100%; }
    .orders-info-table .path.t-start::before {
      background-image: url("/static/img/lk/info/ic_route_A_16.svg"); }
    .orders-info-table .path.t-point::before {
      background-image: url("/static/img/lk/info/ic_route_point_16.svg"); }
    .orders-info-table .path.t-route::before {
      background-image: url("/static/img/lk/info/ic_route_mid_24.svg"); }
    .orders-info-table .path.t-finish::before {
      background-image: url("/static/img/lk/info/ic_route_B_16.svg"); }
    .orders-info-table .path > * {
      display: block; }
    .orders-info-table .path .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 17px;
      line-height: 1.35;
      color: #323c5d; }
    .orders-info-table .path .info {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 12px;
      line-height: 1.42;
      color: rgba(50, 60, 93, 0.5); }
    .orders-info-table .path .station {
      font-size: 10px;
      line-height: 1.4;
      color: #323c5d;
      margin-top: 10px; }
      .orders-info-table .path .station .icon {
        position: relative;
        top: 1px;
        display: inline-block;
        vertical-align: top;
        width: 12px;
        height: 12px;
        margin-right: 4px; }
        .orders-info-table .path .station .icon img {
          vertical-align: top; }
    .orders-info-table .path .tags {
      margin-top: 10px; }
      .orders-info-table .path .tags > * {
        display: inline-block;
        vertical-align: top; }
        .orders-info-table .path .tags > *:not(:last-child) {
          margin-right: 5px; }
      .orders-info-table .path .tags .tag {
        border-radius: 4px;
        background-color: rgba(65, 116, 214, 0.2);
        font-size: 10px;
        line-height: 1.4;
        color: #4174d6;
        padding: 5px 5px; }
  .orders-info-table .user {
    display: inline-block;
    position: relative; }
    .orders-info-table .user:not(:last-child) {
      margin-bottom: 23px; }
    .orders-info-table .user .img {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      display: flex;
      /*justify-content: center;
          align-items: center;*/ }
      .orders-info-table .user .img:not(.t-car) {
        background-color: #dfe6f4; }
        .orders-info-table .user .img:not(.t-car) img {
          vertical-align: top;
          width: 100%;
          object-fit: cover; }
      .orders-info-table .user .img.t-car {
        justify-content: center;
        align-items: center; }
        .orders-info-table .user .img.t-car img {
          vertical-align: top;
          /*max-width: 100%;
              width: auto;
              max-height: 100%;
              height: auto;*/ }
    .orders-info-table .user .info {
      display: block;
      font-size: 10px;
      line-height: 1.4;
      color: rgba(50, 60, 93, 0.7);
      margin-top: -1px; }
    .orders-info-table .user.t-1 {
      min-height: 20px;
      background-color: #f8f8f8;
      border-radius: 10px;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 10px;
      color: rgba(50, 60, 93, 0.7);
      padding-left: 25px;
      padding-right: 8px;
      margin-top: 3px;
      cursor: pointer; }
      .orders-info-table .user.t-1 .img {
        width: 20px;
        height: 20px; }
    .orders-info-table .user.t-2 {
      min-height: 30px;
      font-size: 13px;
      color: #323c5d;
      line-height: 1.38;
      padding-left: 40px; }
      .orders-info-table .user.t-2 .img {
        width: 30px;
        height: 30px; }
  .orders-info-table .type > * {
    display: block; }
  .orders-info-table .type .title {
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #323c5d; }
    .orders-info-table .type .title:not(.t-total) {
      font-size: 17px;
      line-height: 1.35;
      margin-bottom: 10px; }
    .orders-info-table .type .title.t-total {
      font-size: 25px;
      line-height: 1.36; }
  .orders-info-table .type .info {
    font-size: 17px;
    line-height: 1.35;
    color: rgba(50, 60, 93, 0.7); }
  .orders-info-table .type .label {
    position: relative;
    top: -1px;
    display: inline-block;
    border-radius: 10px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 10px;
    line-height: 1.4;
    margin-left: 5px;
    padding: 3px 6px; }
    .orders-info-table .type .label.t-1 {
      background-color: rgba(252, 146, 31, 0.15);
      color: #fc921f; }
  .orders-info-table .total-sum > * {
    display: block; }
  .orders-info-table .total-sum .title {
    font-size: 15px;
    line-height: 1.33;
    color: rgba(50, 60, 93, 0.5);
    margin-bottom: 10px; }
  .orders-info-table .total-sum .sum {
    font-size: 25px;
    line-height: 1.36; }
    .orders-info-table .total-sum .sum:not(.t-total) {
      color: rgba(50, 60, 93, 0.7); }
    .orders-info-table .total-sum .sum.t-total {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      color: #323c5d; }
  .orders-info-table .personal {
    display: inline-block;
    vertical-align: top;
    font-size: 13px;
    line-height: 1.38;
    color: #323c5d; }
    .orders-info-table .personal.rating {
      width: 65px; }
    .orders-info-table .personal .star-icon {
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      background-size: 100% 100%;
      background-image: url("/static/img/lk/widgets/edit/ic_star_border_black_24px.svg"); }
      .orders-info-table .personal .star-icon.is-active {
        background-image: url("/static/img/lk/widgets/edit/ic_star_border_black_24px_active.svg"); }
    .orders-info-table .personal.t-number {
      position: relative;
      top: -4px;
      border-radius: 3px;
      border: 1px solid #dfe6f4;
      padding: 3px 5px; }
  .orders-info-table .more-icon {
    position: relative;
    top: 3px;
    display: inline-block;
    vertical-align: top;
    width: 12px;
    height: 12px;
    background-image: url("/static/img/lk/ic_more_12.svg");
    background-size: 100% 100%; }
  .orders-info-table .img-cargo {
    width: 36px;
    height: 36px;
    background-color: #dfe6f4;
    border-radius: 3px;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; }
    .orders-info-table .img-cargo img {
      vertical-align: top;
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto; }
  .orders-info-table .cargo > * {
    display: block; }
  .orders-info-table .cargo .title {
    font-size: 13px;
    line-height: 1.38;
    color: #323c5d; }
    .orders-info-table .cargo .title.t-bold {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .orders-info-table .cargo .info {
    font-size: 10px;
    line-height: 1.4;
    color: rgba(50, 60, 93, 0.7); }
  .orders-info-table .cargo .desc {
    font-size: 11px;
    line-height: 1.36;
    color: rgba(50, 60, 93, 0.7);
    padding-top: 2px; }
  .orders-info-table .driver-label {
    display: inline-block;
    background-color: #ffc658;
    font-size: 15px;
    line-height: 1.33;
    color: #323c5d;
    border-radius: 12px;
    padding: 2px 10px 2px; }
    .orders-info-table .driver-label:not(:first-child) {
      margin-left: 10px; }
  .orders-info-table .tariff-char {
    display: inline-block;
    vertical-align: top; }
    .orders-info-table .tariff-char-item {
      display: inline-block;
      vertical-align: top; }
      .orders-info-table .tariff-char-item > * {
        display: block; }
      .orders-info-table .tariff-char-item:not(:last-child) {
        margin-right: 40px; }
      .orders-info-table .tariff-char-item .title {
        font-size: 10px;
        line-height: 1.4;
        color: rgba(50, 60, 93, 0.5); }
      .orders-info-table .tariff-char-item .info {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        line-height: 1.33;
        color: #323c5d;
        margin-top: 3px; }
  .orders-info-table .tariff-car {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 230px;
    min-height: 30px; }
    .orders-info-table .tariff-car > * {
      display: block; }
    .orders-info-table .tariff-car .img {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      overflow: hidden;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 4px; }
      .orders-info-table .tariff-car .img img {
        vertical-align: top; }
      .orders-info-table .tariff-car .img .img-icon {
        background-image: url("/static/img/sprite-calc.svg"); }
        .orders-info-table .tariff-car .img .img-icon.mini2 {
          width: 20px;
          height: 11px;
          background-position: -206px -77px; }
        .orders-info-table .tariff-car .img .img-icon.standart2 {
          width: 22px;
          height: 12px;
          background-position: -239px -76px; }
        .orders-info-table .tariff-car .img .img-icon.extra2 {
          width: 24px;
          height: 14px;
          background-position: -272px -74px; }
        .orders-info-table .tariff-car .img .img-icon.maxi2 {
          width: 24px;
          height: 13px;
          background-position: -306px -75px; }
    .orders-info-table .tariff-car .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #4174d6; }
    .orders-info-table .tariff-car .info {
      font-size: 10px;
      line-height: 1.4;
      color: rgba(50, 60, 93, 0.7);
      margin-top: 3px; }
      .orders-info-table .tariff-car .info .item:not(:last-child)::after {
        content: " - "; }
  .orders-info-table .car-char {
    display: block;
    font-size: 15px;
    line-height: 1.33;
    color: #323c5d; }
    .orders-info-table .car-char .item:not(:last-child)::after {
      content: ", "; }
  .orders-info-table .car-char-list li::after {
    display: block;
    clear: both;
    content: ""; }
  .orders-info-table .car-char-list li:not(:last-child) {
    margin-bottom: 22px; }
  .orders-info-table .car-char-list li > * {
    float: left;
    display: inline-block;
    vertical-align: top; }
  .orders-info-table .car-char-list li .title {
    width: 230px;
    font-size: 13px;
    line-height: 1.38;
    color: rgba(50, 60, 93, 0.5); }
  .orders-info-table .car-char-list li .count {
    font-size: 13px;
    line-height: 1.38;
    color: #323c5d; }
    .orders-info-table .car-char-list li .count .item:not(:last-child)::after {
      content: " - "; }
    .orders-info-table .car-char-list li .count .item.capacity:last-child::after {
      content: " т."; }
    .orders-info-table .car-char-list li .count .item.Dlina:last-child::after {
      content: " м."; }

.orders-info-buttons {
  font-size: 0; }
  .orders-info-buttons::after {
    display: block;
    clear: both;
    content: ""; }
  .orders-info-buttons > * {
    display: inline-block;
    vertical-align: middle; }
  .orders-info-buttons .button {
    width: 100px;
    border: 1px solid #dfe6f4;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    padding: 8px 8px; }
    .orders-info-buttons .button:not(:last-child) {
      margin-right: 16px; }
    .orders-info-buttons .button .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-size: 100% 100%; }
    .orders-info-buttons .button.t-stop .icon {
      background-image: url("/static/img/lk/info/ic_stop_24.svg"); }
    .orders-info-buttons .button.t-pause .icon {
      background-image: url("/static/img/lk/info/ic_pause_24.svg"); }

.orders-not-found {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px; }
  .orders-not-found.t-content {
    padding-top: 145px; }
  .orders-not-found.t-info {
    padding-left: 320px; }
  .orders-not-found .inner {
    text-align: center; }
  .orders-not-found .img {
    width: 96px;
    height: 96px;
    margin-left: auto;
    margin-right: auto; }
    .orders-not-found .img img {
      vertical-align: top; }
  .orders-not-found .cnt {
    margin-top: 10px; }
  .orders-not-found .title {
    max-width: 192px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    line-height: 1.33;
    color: rgba(50, 60, 93, 0.5);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
  .orders-not-found .desc {
    font-size: 13px;
    line-height: 1.38;
    color: rgba(50, 60, 93, 0.5);
    margin-bottom: 0; }
  .orders-not-found .more-btn {
    margin-top: 30px; }
  .orders-not-found .btn-lk-add {
    background-color: #4174d6;
    border-color: #4174d6;
    border-radius: 8px;
    width: 118px;
    outline: none;
    color: #FFFFFF;
    font-size: 15px; }

.orders-big-map {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #dfe6f4; }
  .orders-big-map:not(.t-only) {
    top: 0; }
  .orders-big-map.t-only {
    top: 80px; }

.orders-search {
  position: absolute;
  top: 80px;
  bottom: 0;
  width: 100%;
  overflow: hidden; }
  .orders-search-l {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 320px;
    height: 100%;
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.16);
    background-color: #f7f8fa;
    overflow-y: auto;
    -ms-overflow-style: none; }
    .orders-search-l::-webkit-scrollbar {
      width: 0; }
  .orders-search-path {
    position: relative;
    padding: 20px 20px 21px 46px; }
    .orders-search-path::before {
      content: "";
      position: absolute;
      left: 27px;
      width: 2px;
      background-color: rgba(65, 116, 214, 0.4); }
    .orders-search-path:not(:first-child):not(:last-child)::before {
      top: 0;
      bottom: 0; }
    .orders-search-path:first-child::before {
      top: 22px;
      bottom: 0; }
    .orders-search-path:last-child::before {
      top: 0;
      height: 34px; }
    .orders-search-path .type-path {
      position: absolute;
      left: 20px;
      top: 22px;
      z-index: 1;
      width: 16px;
      height: 16px;
      background-color: #f7f8fa;
      background-size: 100% 100%; }
    .orders-search-path.t-start .type-path {
      background-image: url("/static/img/lk/info/ic_route_A_16.svg"); }
    .orders-search-path.t-point .type-path {
      background-image: url("/static/img/lk/info/ic_route_point_16.svg"); }
    .orders-search-path.t-route .type-path {
      background-image: url("/static/img/lk/info/ic_route_mid_24.svg"); }
    .orders-search-path.t-finish .type-path {
      background-image: url("/static/img/lk/info/ic_route_B_16.svg"); }
    .orders-search-path > * {
      display: block; }
    .orders-search-path .title {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #323c5d; }
    .orders-search-path .events {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px; }
      .orders-search-path .events > * {
        display: inline-block;
        vertical-align: middle; }
    .orders-search-path .time {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #4174d6; }
    .orders-search-path .event-wrapper {
      position: relative; }
    .orders-search-path .event {
      position: relative;
      border-radius: 12px;
      border: 1px solid #dfe6f4;
      font-size: 10px;
      line-height: 1.4;
      color: rgba(50, 60, 93, 0.5);
      cursor: pointer;
      padding: 2px 25px 2px 9px; }
      .orders-search-path .event .icon {
        position: absolute;
        right: 9px;
        top: 50%;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        background-image: url("/static/img/lk/ic_chevron_right_12.svg");
        background-size: 100% 100%;
        opacity: 0.5; }
    .orders-search-path .type {
      font-size: 12px;
      line-height: 1.42;
      color: rgba(50, 60, 93, 0.5);
      margin-top: 4px; }
    .orders-search-path .driver {
      display: inline-block;
      vertical-align: top;
      position: relative;
      border-radius: 10px;
      background-color: #ffffff;
      font-size: 10px;
      line-height: 1.4;
      color: rgba(50, 60, 93, 0.7);
      padding: 3px 10px 3px 25px;
      margin-top: 10px; }
      .orders-search-path .driver .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-color: #dfe6f4;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        display: flex; }
        .orders-search-path .driver .img img {
          vertical-align: top;
          width: 100%;
          object-fit: cover; }
    .orders-search-path .cargo {
      position: relative;
      border-radius: 10px;
      background-color: #ffffff;
      padding: 6px 20px 6px 32px;
      margin-top: 10px; }
      .orders-search-path .cargo > * {
        display: block; }
      .orders-search-path .cargo-title {
        font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        line-height: 18px;
        color: #323c5d; }
      .orders-search-path .cargo-type {
        font-size: 10px;
        line-height: 14px;
        color: rgba(50, 60, 93, 0.7); }
      .orders-search-path .cargo .icon {
        position: absolute;
        left: 10px;
        top: 50%;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        background-image: url("/static/img/ic_download_12.svg");
        background-size: 100% 100%; }
    .orders-search-path .time-period {
      position: relative;
      font-size: 10px;
      line-height: 14px;
      color: rgba(50, 60, 93, 0.5);
      margin-top: 12px; }
      .orders-search-path .time-period::before {
        content: "";
        position: absolute;
        left: 0;
        right: -20px;
        top: 50%;
        height: 1px;
        background-color: #dfe6f4;
        margin-top: -0.5px; }
      .orders-search-path .time-period span {
        position: relative;
        background-color: #f7f8fa;
        padding-right: 10px; }
  .orders-search-calendar {
    position: relative;
    margin-left: 20px;
    margin-bottom: -2px; }
    .orders-search-calendar .inner {
      position: relative;
      border-radius: 20px;
      background-color: #f5f8fc;
      cursor: pointer;
      padding: 5px 36px 5px 44px; }
      .orders-search-calendar .inner > * {
        display: block; }
    .orders-search-calendar .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      background-image: url("/static/img/lk/ic_calendar_24.svg");
      background-size: 100% 100%; }
    .orders-search-calendar .time-calendar {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 13px;
      line-height: 1.38;
      color: #4174d6; }
    .orders-search-calendar .date-calendar {
      font-size: 10px;
      line-height: 1.2;
      color: rgba(50, 60, 93, 0.5); }
  .orders-search-events {
    float: right; }
    .orders-search-events > * {
      display: inline-block;
      vertical-align: middle; }
      .orders-search-events > *:not(:last-child) {
        margin-right: 20px; }
    .orders-search-events .item-event {
      position: relative;
      border-radius: 5px;
      background-color: #ffffff;
      cursor: pointer;
      padding: 7px 10px 7px 29px; }
      .orders-search-events .item-event.t-about .title-event {
        color: #323c5d; }
      .orders-search-events .item-event.t-about .icon {
        background-image: url("/static/img/lk/filter/ic_cardstack_12.svg"); }
      .orders-search-events .item-event.t-cancel .title-event {
        color: #fb5c3f; }
      .orders-search-events .item-event.t-cancel .icon {
        background-image: url("/static/img/lk/ic_cancel_12.svg"); }
      .orders-search-events .item-event.t-start .title-event {
        color: #63b463; }
      .orders-search-events .item-event.t-start .icon {
        background-image: url("/static/img/lk/ic_start_12.svg"); }
    .orders-search-events .title-event {
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 13px;
      line-height: 1.38; }
    .orders-search-events .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -5px;
      width: 12px;
      height: 12px;
      background-size: 100% 100%; }
  .orders-search-progress {
    position: relative;
    border-radius: 20px;
    background-color: #ffffff;
    border: 1px solid #dfe6f4;
    padding: 6px 58px 7px 15px;
    margin-left: 20px;
    margin-bottom: -2px; }
    .orders-search-progress .title-progress {
      position: relative;
      font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      line-height: 1.33;
      color: #323c5d; }
    .orders-search-progress .line-progress {
      display: block;
      height: 100%;
      background-color: rgba(252, 146, 31, 0.15);
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      transition: 0.25s linear;
      /*animation: progress 7.5s ease-in infinite;
        -webkit-animation: progress 7.5s ease-in infinite;*/ }
      .orders-search-progress .line-progress-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden; }
    .orders-search-progress .drop-icon {
      display: inline-block;
      vertical-align: top;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/info/ic_more_12.svg");
      background-size: 100% 100%;
      cursor: pointer; }
      .orders-search-progress .drop-icon-wrapper {
        position: absolute;
        right: 17px;
        top: 50%;
        font-size: 0;
        line-height: 1;
        margin-top: -5px; }

.notifications-wrapper {
  width: 290px;
  position: absolute;
  bottom: 25px;
  left: 15px;
  z-index: 999;
  display: none; }
  .notifications-wrapper .notifications-inner .notification {
    color: #ffffff;
    padding: 10px 13px;
    border-radius: 7px;
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.16);
    position: relative; }
    .notifications-wrapper .notifications-inner .notification:not(:last-child) {
      margin-bottom: 5px; }
    .notifications-wrapper .notifications-inner .notification-success {
      background-color: #63b463; }
      .notifications-wrapper .notifications-inner .notification-success button {
        color: #FFFFFF; }
        .notifications-wrapper .notifications-inner .notification-success button:hover {
          background-color: #63b463;
          color: #FFFFFF; }
    .notifications-wrapper .notifications-inner .notification-info {
      background-color: #4174d6; }
      .notifications-wrapper .notifications-inner .notification-info button {
        color: #FFFFFF; }
        .notifications-wrapper .notifications-inner .notification-info button:hover {
          background-color: #FFFFFF;
          color: #4174d6; }
    .notifications-wrapper .notifications-inner .notification-warning {
      background-color: #FFC658;
      color: #323C5D; }
      .notifications-wrapper .notifications-inner .notification-warning button {
        color: #323C5D; }
        .notifications-wrapper .notifications-inner .notification-warning button:hover {
          background-color: #FFC658;
          color: #323C5D; }
    .notifications-wrapper .notifications-inner .notification-error {
      background-color: #fb5c3f; }
      .notifications-wrapper .notifications-inner .notification-error button {
        color: #FFFFFF; }
        .notifications-wrapper .notifications-inner .notification-error button:hover {
          background-color: #FFC658;
          color: #FFFFFF; }
    .notifications-wrapper .notifications-inner .notification-hidden {
      display: none; }
    .notifications-wrapper .notifications-inner .notification h4 {
      font-size: 16px; }
    .notifications-wrapper .notifications-inner .notification button {
      background-color: transparent;
      border-color: #dfe6f4;
      border-radius: 8px;
      outline: none;
      font-size: 15px;
      padding: 3px 14px;
      margin-top: 10px; }
    .notifications-wrapper .notifications-inner .notification .notification-message {
      font-size: 12px; }
    .notifications-wrapper .notifications-inner .notification .notification-dismiss {
      position: absolute;
      top: 0;
      right: 11px;
      cursor: pointer; }

.orderInfo {
  font-size: 13px;
  line-height: 1.38;
  color: #323c5d;
  background-color: #fafbfc;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px; }
  .orderInfo .header {
    height: 60px;
    width: 100%;
    background-color: #fafbfc;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid #dfe6f4; }
    .orderInfo .header .logo {
      background-image: url(/static/img/sprite-business.svg);
      width: 130px;
      height: 60px;
      background-position-y: -435px;
      z-index: 999;
      position: relative;
      display: block;
      margin: 0 auto; }
  .orderInfo .orders-map {
    margin-top: 60px; }
  .orderInfo .wrap {
    padding: 20px;
    border-bottom: 1px solid #dfe6f4; }
  .orderInfo .data {
    margin-top: 5px; }
  .orderInfo .order-id {
    font-size: 20px;
    line-height: 1.35;
    color: #323c5d;
    border-bottom: 1px solid #dfe6f4;
    padding: 20px; }
  .orderInfo .order-adress {
    padding-top: 0px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 20px; }
    .orderInfo .order-adress .title-route {
      margin-left: -10px;
      margin-bottom: 10px; }
    .orderInfo .order-adress .point {
      position: relative;
      font-size: 11px;
      line-height: 1.36;
      color: rgba(50, 60, 93, 0.5); }
      .orderInfo .order-adress .point:not(:last-child) {
        margin-bottom: 3px; }
      .orderInfo .order-adress .point::before {
        content: "";
        position: absolute;
        left: -11px;
        width: 1px;
        background-color: #dfe6f4; }
      .orderInfo .order-adress .point:not(:first-child):not(:last-child)::before {
        top: -3px;
        bottom: -9px; }
      .orderInfo .order-adress .point:first-child::before {
        top: 6px;
        bottom: -9px; }
      .orderInfo .order-adress .point:last-child::before {
        top: 6px;
        height: 4px; }
      .orderInfo .order-adress .point::after {
        content: "";
        position: absolute;
        left: -13px;
        top: 4px;
        z-index: 1;
        width: 6px;
        height: 6px;
        border: 1px solid #dfe6f4;
        border-radius: 50%; }
      .orderInfo .order-adress .point.t-1::after {
        background-color: #dfe6f4; }
      .orderInfo .order-adress .point.t-2::after {
        background-color: #ffffff; }
      .orderInfo .order-adress .point .title {
        font-size: 13px;
        font-weight: 700; }
  .orderInfo .cost {
    font-size: 20px;
    line-height: 1.35;
    color: #323c5d;
    padding: 20px; }
    .orderInfo .cost .t-left {
      float: left; }
    .orderInfo .cost .t-right {
      float: right; }
  .orderInfo .status {
    border-radius: 12px;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 10px;
    color: #ffffff;
    line-height: 1.4;
    letter-spacing: 0.4px;
    cursor: pointer;
    padding: 2px 20px 3px 7px; }
    .orderInfo .status-wrapper {
      position: relative;
      margin-left: 25px;
      width: unset;
      float: right;
      margin-top: 3px; }
    .orderInfo .status:not([class*="t-"]) {
      background-color: #323c5d; }
    .orderInfo .status.t-not-found {
      background-color: #323c5d; }
    .orderInfo .status.t-completed {
      background-color: #63b463; }
    .orderInfo .status.t-canceled {
      background-color: #ff3333; }
    .orderInfo .status.t-accepted {
      background-color: #ffc658; }
    .orderInfo .status .icon {
      position: relative;
      top: 2px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: url("/static/img/lk/info/ic_done_12.svg");
      background-size: 100% 100%;
      margin-right: 5px; }

@media (min-width: 992px) {
  .container-fluid {
    max-width: 1190px; }
  .s-index-start .column-item:nth-child(1) {
    width: calc(100% - 406px); }
  .s-index-start .column-item:nth-child(2) {
    width: 406px; }
  .s-index-start .business-driver .inner {
    align-items: center; }
  .header .big .container-fluid .block_right.links a {
    display: inline-block; }
  .header .big .container-fluid .block_right .btn-take-order {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 1;
    background: #FFFFFF00 0% 0% no-repeat padding-box;
    border-radius: 8px;
    height: 32px;
    width: 147px;
    float: right;
    margin-top: 14px;
    outline: none;
    border: 1px solid #4174D6;
    cursor: pointer;
    color: #4174D6;
    font-size: 13px;
    line-height: 32px;
    display: inline-block;
    transition: 0.25s ease-in-out; }
    .header .big .container-fluid .block_right .btn-take-order:hover {
      color: white;
      background-color: #4174D6;
      box-shadow: 0 5px 20px 0 #4174D67F; }
  .header .big.fixed {
    background-color: #f8f8f8;
    border-bottom: 1px solid #dfe6f4; }
  .header .burger {
    display: none; } }

@media (max-width: 991px) {
  .s-index-start > .container-fluid {
    max-width: 542px; }
  .s-index-start .column-item {
    width: 100%; }
    .s-index-start .column-item:nth-child(2) {
      margin: 50px 0 0; }
  .s-index-start .index-start__desc2 {
    margin-top: 30px; }
  .s-index-start .business-driver .container-fluid {
    max-width: 717px; }
  .s-index-start .business-driver .text {
    padding-right: 15px; }
  .header .container-fluid {
    max-width: 717px; }
  .header .big .container-fluid .block_left {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center; }
    .header .big .container-fluid .block_left .logo {
      overflow: hidden;
      width: 32px; }
  .header .big .container-fluid .block_right.links a {
    display: none; }
  .header .big .container-fluid .block_right .btn-take-order {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 1;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 8px;
    height: 30px;
    width: 128px;
    float: right;
    margin-top: 15px;
    outline: none;
    cursor: pointer;
    color: #FFFFFF;
    font-family: "Open Sans Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    line-height: 28px;
    display: inline-block;
    transition: 0.25s ease-in-out; }
  .header .big.fixed {
    background-color: #000000;
    border-bottom: 1px solid #000000; }
  .widget-tariff-car .column-inner {
    float: right;
    max-width: 165px; } }

@media (min-width: 1200px) and (max-height: 630px) {
  .s-index-start .index-start__title {
    font-size: 37px; }
  .s-index-start .index-start__desc2 {
    margin-top: 23px; }
  .s-index-start .index-start-app {
    margin-top: 14px; } }

@media (min-width: 1200px) and (min-height: 631px) {
  .s-index-start .index-start__title {
    font-size: 50px; } }

@media (min-width: 2560px) {
  .s-index-start {
    padding: 170px 0 250px; }
    .s-index-start .container-fluid {
      max-width: 2040px; }
    .s-index-start .business-driver {
      padding: 25px 0; }
      .s-index-start .business-driver .text {
        font-size: 40px; }
      .s-index-start .business-driver .buttons a {
        height: auto;
        border-width: 2px;
        border-radius: 20px;
        font-size: 26px;
        padding: 14px 40px 14px; }
        .s-index-start .business-driver .buttons a .icon {
          top: -1px;
          vertical-align: middle;
          width: 28px;
          height: 28px;
          margin-left: 24px; }
      .s-index-start .business-driver .inner {
        padding-left: 169px; }
      .s-index-start .business-driver .close {
        top: 18px;
        width: 36px;
        height: 36px; }
    .s-index-start .column-item:nth-child(1) {
      width: calc(100% - 677px); }
    .s-index-start .column-item:nth-child(2) {
      width: 677px; }
    .s-index-start .index-start__title {
      max-width: 1162px;
      font-size: 86px;
      margin: 0 0 20px; }
    .s-index-start .index-start__desc {
      font-size: 36px;
      margin: 20px 0 0; }
    .s-index-start .index-start__desc2 {
      max-width: 1052px;
      font-size: 50px;
      margin: 60px 0 0; }
    .s-index-start .index-start-type {
      max-width: 800px;
      margin-top: 50px; }
      .s-index-start .index-start-type .column-type {
        margin-bottom: 43px; }
        .s-index-start .index-start-type .column-type .item {
          position: relative;
          height: auto;
          font-size: 29px;
          padding-left: 76px; }
          .s-index-start .index-start-type .column-type .item .icon {
            width: 56px;
            height: 56px;
            left: 0;
            margin-top: -10px;
            margin-left: 0; }
    .s-index-start .index-start-app {
      margin: 36px 0 0; }
      .s-index-start .index-start-app-list a {
        width: 186px;
        height: 56px;
        background-position: 0 0;
        background-size: 100% 100%; }
        .s-index-start .index-start-app-list a.google {
          background-image: url("/static/img/google_play.svg"); }
        .s-index-start .index-start-app-list a.apple {
          background-image: url("/static/img/app_store.svg"); }
    .s-index-start .widget:not(:first-child) {
      margin-top: 20px; }
  .header .container-fluid {
    max-width: 2040px; }
  .header .small {
    height: 80px; }
    .header .small .container-fluid .block_left .link {
      font-size: 21px;
      line-height: 80px; }
    .header .small .container-fluid .block_right {
      width: 500px; }
      .header .small .container-fluid .block_right > .link {
        font-size: 21px;
        line-height: 80px; }
    .header .small .lang {
      font-size: 21px;
      line-height: inherit; }
      .header .small .lang-inner {
        margin-right: 30px;
        padding-right: 30px; }
      .header .small .lang-wrapper {
        padding: 26px 0 24px; }
    .header .small .login-in {
      padding: 21px 0; }
      .header .small .login-in-item {
        font-size: 21px; }
      .header .small .login-in-balance .icon {
        top: -3px; }
      .header .small .login-in-user {
        padding-left: 38px; }
        .header .small .login-in-user .user {
          top: 5px;
          width: 28px;
          height: 28px; }
  .header .big {
    height: 101px;
    top: 80px; }
    .header .big .container-fluid .block_left {
      height: 100px; }
      .header .big .container-fluid .block_left .logo {
        margin-top: 36px; }
    .header .big .container-fluid .block_right.links a {
      font-size: 23px;
      line-height: 100px; }
    .header .big .container-fluid .block_right .btn-take-order {
      font-size: 23px;
      line-height: 1.04;
      width: auto;
      height: auto;
      padding: 14px 40px 14px;
      margin-top: 25px; }
  .widget-address {
    border-radius: 30px;
    padding: 33px 25px; }
    .widget-address-inputs {
      margin-right: -25px; }
      .widget-address-inputs::before {
        left: 13px; }
    .widget-address-input {
      padding-left: 45px; }
      .widget-address-input:not(:last-child)::after {
        left: 45px; }
      .widget-address-input .icon {
        top: 5px;
        width: 28px;
        height: 28px; }
      .widget-address-input .geo-icon {
        bottom: 9px; }
      .widget-address-input .wrap-inp input {
        height: auto;
        font-size: 28px; }
  .widget-tariff-head {
    padding: 17px 30px 19px 84px; }
    .widget-tariff-head:first-child {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px; }
    .widget-tariff-head .type {
      font-size: 25px; }
    .widget-tariff-head .img {
      left: 30px;
      margin-top: -9px; }
    .widget-tariff-head .time {
      top: -1px;
      font-size: 17px; }
      .widget-tariff-head .time .icon {
        top: 0; }
    .widget-tariff-head .price {
      font-size: 25px; }
      .widget-tariff-head .price .als-rubl {
        font-size: 25px; }
      .widget-tariff-head .price .count {
        font-size: 17px; }
  .widget-tariff-info .tariff {
    font-size: 22px; }
  .widget-tariff-info .mass {
    font-size: 29px; }
  .widget-tariff-info .price {
    font-size: 50px; }
    .widget-tariff-info .price .count {
      font-size: 25px; }
  .widget-tariff .widget-tariff-car .column:nth-child(1) {
    width: 300px; }
  .widget-tariff .widget-tariff-car .column:nth-child(2) {
    width: calc(100% - 300px); }
  .widget-tariff .widget-tariff-car .car {
    height: 120px; }
  .widget-tariff .widget-tariff-car .info-1 {
    font-size: 18px; }
  .widget-tariff .widget-tariff-car .info-2 {
    max-height: 48px;
    font-size: 18px; }
  .widget-tariff .widget-tariff-car .options-list {
    margin: 0; }
    .widget-tariff .widget-tariff-car .options-list li {
      font-size: 17px; }
      .widget-tariff .widget-tariff-car .options-list li .icon {
        top: 7px; }
      .widget-tariff .widget-tariff-car .options-list li:not(:last-child) {
        margin-right: 18px; }
  .widget-tariff .widget-tariff-car .time {
    font-size: 17px;
    margin-top: 15px; }
    .widget-tariff .widget-tariff-car .time .icon {
      top: 12px; }
  .widget-tariff .widget-tariff-car .btn {
    font-size: 21px;
    padding: 21px 15px 21px 15px; }
    .widget-tariff .widget-tariff-car .btn .icon {
      height: 28px;
      width: 28px; }
    .widget-tariff .widget-tariff-car .btn .count {
      height: 28px;
      width: 28px;
      font-size: 18px;
      line-height: 28px;
      padding: 0; }
  .widget {
    border-radius: 30px; } }

.loader-box {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90px;
  height: 90px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: -45px 0 0 -45px;
  text-align: center;
  z-index: 500000002; }
  .loader-box .loading-text {
    line-height: 90px;
    position: absolute;
    height: 90px;
    width: 90px;
    text-align: center;
    z-index: 102;
    color: #fc921f; }
  .loader-box .loading-text.sub-text {
    position: absolute;
    top: 95px;
    left: -90px;
    font-size: 80%;
    line-height: normal;
    width: 270px;
    height: auto;
    background-color: #fc921f;
    color: white;
    border: 1px solid white;
    padding: 4px;
    border-radius: 4px; }
  .loader-box .global-loader {
    width: 90px;
    height: 90px;
    background-color: #FFFFFF;
    overflow: hidden;
    display: block;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    border: 3px solid #fc921f;
    position: relative;
    z-index: 101; }
  .loader-box .element-animation {
    animation: animationFrames linear 3s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    /*when the spec is finished*/
    -webkit-animation: animationFrames linear 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-fill-mode: forwards;
    /*Chrome 16+, Safari 4+*/
    -moz-animation: animationFrames linear 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-fill-mode: forwards;
    /*FF 5+*/
    -o-animation: animationFrames linear 3s;
    -o-animation-iteration-count: infinite;
    -o-animation-fill-mode: forwards;
    /*Not implemented yet*/
    -ms-animation: animationFrames linear 3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-fill-mode: forwards;
    /*IE 10+*/ }

@keyframes animationFrames {
  0% {
    transform: translate(-1160px, 15px); }
  100% {
    transform: translate(0px, 15px); } }

@-moz-keyframes animationFrames {
  0% {
    transform: translate(-1160px, 15px); }
  100% {
    transform: translate(0px, 15px); } }

@-webkit-keyframes animationFrames {
  0% {
    transform: translate(-1160px, 15px); }
  100% {
    transform: translate(0px, 15px); } }

@-o-keyframes animationFrames {
  0% {
    transform: translate(-1160px, 15px); }
  100% {
    transform: translate(0px, 15px); } }

@-ms-keyframes animationFrames {
  .loader-box 0% {
    transform: translate(-1160px, 15px); }
  .loader-box 100% {
    transform: translate(0px, 15px); } }
